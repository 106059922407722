import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MatDialog, MatDialogConfig, MatTableDataSource} from "@angular/material";
import {PortGraphDialogModalComponent} from "../port-graph-dialog-modal/port-graph-dialog-modal.component";
import {Router} from "@angular/router";
import {UtilService} from "../../service/util/util.service";
import * as ports2Json from 'src/assets/ports.json';

declare var $: any;

class Filter {
    match_phrase: MatchPhrase = new MatchPhrase()
}

class MatchPhrase {
    type: string = ''
}


@Component({
    selector: 'app-search-header',
    templateUrl: './search-header.component.html',
    styleUrls: ['./search-header.component.scss']
})
export class SearchHeaderComponent implements OnInit, OnChanges {

    // allPorts = (portsJson as any).default;
    allPorts = (ports2Json as any).default;

    keyword = 'value';
    utcDate = new Date().toUTCString();
    @Output('onSearch') searchEmitter = new EventEmitter()
    @Output('onLocation') locationEmitter = new EventEmitter()
    @Output('onLocationClear') locationClearEmitter = new EventEmitter()
    @Output('onIsPort') isPortEmitter = new EventEmitter()
    @Output('onApply') applyEmitter = new EventEmitter()
    @Output('onSeaMarks') seaMarksEmitter = new EventEmitter()
    @Output('onDryad') dryadEmitter = new EventEmitter()
    @Output('onNews') newsEmitter = new EventEmitter()
    @Output('onCorridors') corridorEmitter = new EventEmitter()
    @Output('onTerminals') terminalEmitter = new EventEmitter()
    @Output('onNigeriaTerminals') nigeriaterminalEmitter = new EventEmitter()
    @Output('onNigeriaTerminalsEZ') nigeriaezterminalEmitter = new EventEmitter()
    @Output('onPatrolZones') patrolEmitter = new EventEmitter()
    @Output('onIOFA') iofaEmitter = new EventEmitter()
    @Output('onSTS') stsEmitter = new EventEmitter()
    @Output('onHRA') hraEmitter = new EventEmitter()
    @Output('onIndianOcean') indianOceanEmitter = new EventEmitter()
    @Output('onWestAfrica') westAfricaEmitter = new EventEmitter()
    @Output('onIndianOceanJWC') indianOceanJWCEmitter = new EventEmitter()
    @Output('onGogJWC') gogJWCEmitter = new EventEmitter()
    @Output('onAsia12') asia12Emitter = new EventEmitter()
    @Output('onMiddleEast12') middleEast12Emitter = new EventEmitter()
    @Output('onSouthAmerica12') southAmerica12Emitter = new EventEmitter()
    @Output('onAfrica12') africa12Emitter = new EventEmitter()
    @Output('onEEZ') eezEmitter = new EventEmitter()
    @Output('onAllWorldEEZ') allworldEmitter = new EventEmitter()
    @Output('onAllWorld12EEZ') allworld12Emitter = new EventEmitter()
    @Output('onNigeriaEEZ') nigeriaeezEmitter = new EventEmitter()
    @Output('onSoaTomeEEZ') soaTomeEmitter = new EventEmitter()
    @Input('count') count
    @Input('portList') portList
    @Input('incidentList') incidentList
    @Input('migrationList') migrationList
    @Input('countryList') countryList
    @Input('mapCanvas') mapCanvas
    @Input('incidentData') incidentData
    @Input('popMarker') popMarker
    @Input('isTra') isTra
    @Input('sTime') sTime
    @Input('eTime') eTime
    @Input('dataSource') dataSource
    @Input('chart1') chart1
    @Input('chart2') chart2
    timeType: any = 'months'
    timeCount: number = 6
    isDateTypeChange: boolean = false

    //change
    filteredPortList: any = []
    filteredMigrationList: any = []
    filteredDryadList: any = [
        {value: "GoA - BAM", status: true},
        {value: "Africa East Coast and Seychelles", status: true},
        {value: "Somalia", status: true},
        {value: "Caribbean Central", status: true},
        {value: "Caribbean South", status: true},
        {value: "Caribbean East", status: true},
        {value: "Black Sea& Bosporus", status: true},
        {value: "Bay of Bengal", status: true},
        {value: "Malacca", status: true},
        {value: "Sulu & Celebes Sea", status: true},
        {value: "South China Sea", status: true},
        {value: "Mediterranean", status: true},
        {value: "GoG East", status: true},
        {value: "Waf South", status: true},
        {value: "GoG West", status: true},
        {value: "Waf West", status: true},
        {value: "Togo & Benin", status: true},
        {value: "Sao Tome & Nigerian", status: true},
        {value: "Cameroon & Malabo", status: true},
        {value: "Suez and Red Sea", status: true},
        {value: "GoO Arabian Sea", status: true},
        {value: "Persian Gulf", status: true},
        {value: "Gulf of Mexico", status: true},
        {value: "Lagos", status: true},
        {value: "Southern Niger Delta", status: true},
        {value: "Mozambique Channel", status: true},
        {value: "Maldives", status: true},
        {value: "Gulf of Kutch", status: true},
        {value: "Western Niger Delta", status: true}
    ];
    filteredDryadListX: any = [
        {value: "GoG - Central", status: true},
        {value: "Caribbean Central", status: true},
        {value: "Black Sea and Bosporus", status: true},
        {value: "Zanzibar", status: true},
        {value: "Caribbean East", status: true},
        {value: "GoG - West", status: true},
        {value: "GoA - BaM", status: true},
        {value: "Caribbean South", status: true},
        {value: "WaF - South Gabon", status: true},
        {value: "GoG - East", status: true},
        {value: "GoA - Socotra", status: true},
        {value: "South China Sea", status: true},
        {value: "Gulf of Oman - Arabian Sea", status: true},
        {value: "Sulu and Celebes Sea", status: true},
        {value: "Mediterranean", status: true},
        {value: "WaF - South Angola", status: true},
        {value: "Bay of Bengal", status: true},
        {value: "Malacca", status: true},
        {value: "Somalia", status: true}]
    filteredIncidentList: any = [
        {value: "Robbery", status: true},
        {value: "Boarding", status: true},
        {value: "Fired Upon", status: true},
        {value: "Approach", status: true},
        {value: "Kidnapping", status: true},
        {value: "Attack", status: true},
        {value: "Attempted", status: true},
        {value: "Hijack", status: true}]
    search: string = ''
    isPort: boolean = false
    isIncident: boolean = true
    isMigration: boolean = true
    isCountry: boolean = true
    isDryad: boolean = true
    isSeaMarks: boolean = true
    isDryadPolygons: boolean = true
    isNews: boolean = true
    isCorridors: boolean = false
    isTerminals: boolean = false
    isNigeriaTerminals: boolean = false
    isNigeriaTerminalsEZ: boolean = false
    isPatrolZones: boolean = false
    isIOFA: boolean = false
    isSTS: boolean = false
    isHRA: boolean = false
    isIndianOceanHRA: boolean = false
    isWestAfricaHRA: boolean = false
    isIndianOceanJWC: boolean = false
    isGogJWC: boolean = false
    isAsia12: boolean = false
    isMiddleEast12: boolean = false
    isSouthAmerica12: boolean = false
    isAfrica12: boolean = false
    isEEZ: boolean = true
    isAllWordEEZ: boolean = true
    isAllWord12EEZ: boolean = true
    isNigeriaEEZ: boolean = true
    isSoaTomeEEZ: boolean = true
    filterList: any = []
    selectedCountry: any = []
    endDate: any
    startDate: any
    user: any = {
        "name": "",
        "email": "",
        "username": ""
    }
    text1 = 'ashay'
    autocompletelist = [];
    isSidebar: boolean = false
    isSidebarLayers: boolean = false

    mLat:number = 0;
    mLong:number = 0;
    mLatDMS = '';
    mLongDMS = '';

    isDMS = false
    constructor(private matDialog: MatDialog, public ngZone: NgZone, private router: Router, public util: UtilService) {
        const date = new Date().setMonth(new Date().getMonth() - 6)
        const start = new Date(date)

        let sm = start.getMonth() + 1
        let sMonth = sm >= 10 ? sm : '0' + sm

        let sd = start.getDate()
        let sDate = sd >= 10 ? sd : '0' + sd

        this.startDate = start.getFullYear() + '-' + sMonth + '-' + sDate

        const end = new Date()

        let em = end.getMonth() + 1
        let eMonth = em >= 10 ? em : '0' + em

        let ed = end.getDate()
        let eDate = ed >= 10 ? ed : '0' + ed

        this.endDate = end.getFullYear() + '-' + eMonth + '-' + eDate

        this.user = JSON.parse(localStorage.getItem('userDetails'));
        // console.log(this.endDate)
        // console.log(this.startDate)

    }

    selectEvent(item) {
        // do something with selected item
    }

    onChangeSearch(val: string) {
        this.search = val;
        // fetch remote data from here
        // And reassign the 'data' which is binded to 'data' property.
    }

    onFocused(e) {
        // do something when input is focused
        // this.autocompletelist = this.portList.concat(this.filteredDryadList, this.countryList);
    }

    addRemoveDryad() {
        // this.isDryadPolygons = !this.isDryadPolygons;
        this.dryadEmitter.emit({isDryadPolygons: this.isDryadPolygons});
    }

    addRemoveNews() {
        this.newsEmitter.emit({isNews: this.isNews});
    }

    addRemoveSeaMarks() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.seaMarksEmitter.emit({isSeaMarks: this.isSeaMarks});
    }

    addRemoveCorridors() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.corridorEmitter.emit({isCorridors: this.isCorridors});
    }

    addRemoveIndianOceanHRA() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.indianOceanEmitter.emit({isIndianOceanHRA: this.isIndianOceanHRA});
    }

    addRemoveWestAfricaHRA() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.westAfricaEmitter.emit({isWestAfricaHRA: this.isWestAfricaHRA});
    }

    addRemoveTerminals() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.isNigeriaTerminals = this.isTerminals;
        this.isNigeriaTerminalsEZ = this.isTerminals;
        this.terminalEmitter.emit({isTerminals: this.isTerminals});
    }

    addRemoveNigeriaTerminals() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.nigeriaterminalEmitter.emit({isNigeriaTerminals: this.isNigeriaTerminals});
    }

    addRemoveNigeriaTerminalsEZ() {
        // this.isSeaMarks = !this.isSeaMarks;
        this.nigeriaezterminalEmitter.emit({isNigeriaTerminalsEZ: this.isNigeriaTerminalsEZ});
    }

    addRemovePatrolZones() {
        this.patrolEmitter.emit({isPatrolZones: this.isPatrolZones})
    }

    addRemoveIOFA() {
        this.iofaEmitter.emit({isIOFA: this.isIOFA})
    }

    addRemoveSTS() {
        this.stsEmitter.emit({isSTS: this.isSTS})
    }

    addRemoveHRA() {
        this.isIndianOceanHRA = this.isHRA;
        this.isWestAfricaHRA = this.isHRA;
        this.isCorridors = this.isHRA;
        this.hraEmitter.emit({isHRA: this.isHRA})
    }

    addRemoveIndianOceanJWC() {
        this.indianOceanJWCEmitter.emit({isIndianOceanJWC: this.isIndianOceanJWC})
    }

    addRemoveGogJWC() {
        this.gogJWCEmitter.emit({isGogJWC: this.isGogJWC})
    }

    addRemoveAsia12() {
        this.asia12Emitter.emit({isAsia12: this.isAsia12})
    }

    addRemoveMiddleEast12() {
        this.middleEast12Emitter.emit({isMiddleEast12: this.isMiddleEast12})
    }

    addRemoveSouthAmerica12() {
        this.southAmerica12Emitter.emit({isSouthAmerica12: this.isSouthAmerica12})
    }

    addRemoveAfrica12() {
        this.africa12Emitter.emit({isAfrica12: this.isAfrica12})
    }

    addRemoveEEZ() {
        this.isAllWordEEZ = this.isEEZ;
        this.isAllWord12EEZ = this.isEEZ;
        this.isNigeriaEEZ = this.isEEZ;
        this.isSoaTomeEEZ = this.isEEZ;
        this.eezEmitter.emit({isEEZ: this.isEEZ});
    }

    addRemoveAllWorldEEZ() {
        this.allworldEmitter.emit({isAllWordEEZ: this.isAllWordEEZ});
    }

    addRemoveAllWorld12EEZ() {
        this.allworld12Emitter.emit({isAllWord12EEZ: this.isAllWord12EEZ});
    }

    addRemoveNigeriaEEZ() {
        this.nigeriaeezEmitter.emit({isNigeriaEEZ: this.isNigeriaEEZ});
    }

    addRemoveSoaTomeEEZ() {
        this.soaTomeEmitter.emit({isSoaTomeEEZ: this.isSoaTomeEEZ});
    }

    ngOnInit() {
        setInterval(() => {
            this.utcDate = new Date().toUTCString();
        }, 1000);
        // console.log('this.countryList', JSON.stringify(this.portList))
        this.autocompletelist = this.allPorts.concat(this.portList, this.countryList, this.filteredDryadList);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (null != changes.portList && null != changes.portList.currentValue) {
            this.autocompletelist = this.allPorts.concat(this.portList, this.countryList, this.filteredDryadList);
            // console.log('Auto Complete List: ', JSON.stringify(this.autocompletelist));
            // this.addresses = changes.customer.currentValue.addresses;
            // this.custId = changes.customer.currentValue.custId;
        }
    }

    onChange() {
        this.isDateTypeChange = !this.isDateTypeChange
    }

    instanceSearchPort(event) {
        this.filteredPortList = this.filterListSearch(event, this.portList)
    }

    instanceSearchMigration(event) {
        this.filteredMigrationList = this.filterListSearch(event, this.migrationList)
    }

    filterListSearch(val: string, list: any): string[] {
        // if(val.length >3){
        return list.map(x => x).filter(option =>
            option.value.toLowerCase().includes(val.toLowerCase()));
        // }else{
        //   return []
        // }
    }

    selectCountry(event) {
        // console.log('ss: ', event)
    }

    selectionChange(event){
        // console.log('ssChange: ', event, this.search)
    }
    newSearchSelectionChange(string){
       // console.log('new Search::::  ', string)
       // console.log('new Searchxxx::::  ', this.search)
       setTimeout(()=>console.log('new Searchxxx::::  ', this.search), 1000)

        /*if (val) {
            console.log('onSearch::::::::::::::::::::::', val, JSON.stringify(val))
            if (val.hasOwnProperty("value")) {
                this.search = val['value'];
            }
        } else {
            this.search = '';
        }*/
        this.selectedFilter()
        // console.log('country', JSON.stringify(this.countryList))

        // var countriesQuery =[]
        for (let country of this.countryList) {
            if (country.status) {
                const x = {
                    "match_phrase": {
                        "country.description.keyword": {
                            "query": country.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }
        for (let incident of this.filteredIncidentList) {
            if (incident.status) {
                const x = {
                    "match_phrase": {
                        "significant_tag.keyword": {
                            "query": incident.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }

        for (let dryad of this.filteredDryadList) {
            if (dryad.status) {
                const x = {
                    "match_phrase": {
                        "classification.dryad_area.keyword": {
                            "query": dryad.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }

        this.isPortEmitter.emit({isPort: this.isPort})
        const setected = this.autocompletelist.filter(val => {
            return (val.location && val.location.length && val.value === this.search)
        });
        // console.log('search: ', JSON.stringify(setected))

        this.searchEmitter.emit({filter: this.filterList, search: this.search, searchObj: setected})
        // console.log(this.selectedCountry)

    }
    onSearch(val: string) {
        if (val) {
            // console.log('onSearch::::::::::::::::::::::', val, JSON.stringify(val))
            if (val.hasOwnProperty("value")) {
                this.search = val['value'];
            }
        } else {
            this.search = '';
        }
        this.selectedFilter()
        // console.log('country', JSON.stringify(this.countryList))

        // var countriesQuery =[]
        for (let country of this.countryList) {
            if (country.status) {
                const x = {
                    "match_phrase": {
                        "country.description.keyword": {
                            "query": country.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }
        for (let incident of this.filteredIncidentList) {
            if (incident.status) {
                const x = {
                    "match_phrase": {
                        "significant_tag.keyword": {
                            "query": incident.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }

        for (let dryad of this.filteredDryadList) {
            if (dryad.status) {
                const x = {
                    "match_phrase": {
                        "classification.dryad_area.keyword": {
                            "query": dryad.value
                        }
                    }
                }
                this.filterList.push(x)
            }
        }

        this.isPortEmitter.emit({isPort: this.isPort})
        const setected = this.autocompletelist.filter(val => {
            return (val.location && val.location.length && val.value === this.search)
        });
        // console.log('search: ', JSON.stringify(setected))

        this.searchEmitter.emit({filter: this.filterList, search: this.search, searchObj: setected})
        // console.log(this.selectedCountry)

    }

    selectedFilter() {
        this.filterList = []

        if (this.isPort || this.isDryad || this.isCountry || this.isMigration || this.isIncident) {

            /*if(this.isPort){
              let obj1 = new Filter()
              obj1.match_phrase.type = 'port'
              this.filterList.push(obj1)
            }*/


            if (this.isIncident) {
                let obj2 = new Filter()
                obj2.match_phrase.type = 'incident'
                this.filterList.push(obj2)
            }
            if (this.isMigration) {
                let obj3 = new Filter()
                obj3.match_phrase.type = 'migration'
                this.filterList.push(obj3)
            }
            if (this.isCountry) {
                let obj4 = new Filter()
                obj4.match_phrase.type = 'country'
                this.filterList.push(obj4)
            }
            if (this.isDryad) {
                let obj5 = new Filter()
                obj5.match_phrase.type = 'dryad'
                this.filterList.push(obj5)
            }
        } else {
            /*
             let obj1 = new Filter()
             obj1.match_phrase.type = 'port'
             this.filterList.push(obj1)

             let obj2 = new Filter()
             obj2.match_phrase.type = 'incident'
             this.filterList.push(obj2)

             let obj3 = new Filter()
             obj3.match_phrase.type = 'migration'
             this.filterList.push(obj3)

             let obj4 = new Filter()
             obj4.match_phrase.type = 'country'
             this.filterList.push(obj4)x

             let obj5 = new Filter()
             obj5.match_phrase.type = 'dryad'
             this.filterList.push(obj5)
             */

        }

        console.log(JSON.stringify(this.filterList))

    }

    onApply() {

        if (this.isDateTypeChange) {
            this.applyEmitter.emit({endDate: new Date(this.endDate), startDate: new Date(this.startDate)})
        } else {
            const d = new Date()
            var x
            switch (this.timeType) {
                case 'minutes':
                    x = d.setMinutes(d.getMinutes() - this.timeCount)
                    break;
                case 'hours':
                    x = d.setHours(d.getHours() - this.timeCount)
                    break;
                case 'days':
                    x = d.setDate(d.getDate() - this.timeCount)
                    break;
                case 'months':
                    x = d.setMonth(d.getMonth() - this.timeCount)
                    break;
                case 'years':
                    x = d.setUTCFullYear(d.getFullYear() - this.timeCount)
                    // this.startTime=new Date(x)
                    break;
            }
            this.applyEmitter.emit({endDate: new Date(), startDate: new Date(x)})

        }


    }

    changePortStatus() {
        this.isPort = false;
    }

    changeIncidentStatus() {
        this.isIncident = false;
    }

    changeCountryStatus() {
        this.isCountry = false;
    }

    changeDryadStatus() {
        this.isDryad = false;
    }

    changeAllPort() {
        for (let f in this.filteredPortList) {
            this.filteredPortList[f].status = this.isPort;
        }
    }

    changeAllIncident() {
        for (let f in this.filteredIncidentList) {
            this.filteredIncidentList[f].status = this.isIncident;
        }
    }

    changeAllCountry() {
        for (let f in this.countryList) {
            this.countryList[f].status = this.isCountry;
        }
    }

    changeAllDryad() {
        for (let f in this.filteredDryadList) {
            this.filteredDryadList[f].status = this.isDryad;
        }
    }

    onClickOpenPortGraphDataModal() {



        var tag = []
        for (let x of this.filteredIncidentList) {
            if (x.status) {
                tag.push(x.value)
            }
        }


        const matDialogConfig = new MatDialogConfig();
        matDialogConfig.height = "80%";
        matDialogConfig.width = "70%";
        matDialogConfig.data = {
            canvas: this.mapCanvas,
            startTime: this.sTime,
            endTime: this.eTime,
            tag: tag.join(', '),
            chart1: this.chart1,
            chart2: this.chart2,
            incidentDetails: this.incidentData.hits.hits[0]._source, //Incident_Details._source,
            marker: this.popMarker,
            dataSource: new MatTableDataSource<PeriodicElement>(this.dataSource)
        }
        const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            // console.log(`Dialog result: ${result}`);
        });

    }

    onReset() {

        this.search = ''
        this.isPort = false
        this.isIncident = true
        this.isMigration = true
        this.isCountry = true
        this.isDryad = true

        // location.reload();
    }

    onSidebar() {
        this.isSidebarLayers = false
        $('#sidebarLayers').collapse('hide');

        if (this.isSidebar) {
            $('#sidebar').collapse('hide');
        } else {
            $('#sidebar').collapse('show');
        }

        this.isSidebar = !this.isSidebar
    }

    onSidebarLayer() {
        this.isSidebar = false
        $('#sidebar').collapse('hide');

        if (this.isSidebarLayers) {
            $('#sidebarLayers').collapse('hide');
        } else {
            $('#sidebarLayers').collapse('show');
        }

        this.isSidebarLayers = !this.isSidebarLayers
    }

    signOut() {
        localStorage.clear();
        this.util.logout();
        this.router.navigate(['/']);
    }

    getLetter(name: string, email: string) {

        if (name) {
            return ' ' + name.charAt(0).toUpperCase() + ' ';
        } else {
            return ' ' + email.charAt(0).toUpperCase() + ' ';
        }
    }

    radio:any = 1
    mLatLng: string
    onSetLocation(){
        // console.log('radio: ', this.radio)
        const mLatLng = this.mLatLng.split(',')
       /* switch (this.radio) {
            case 1:
                this.locationEmitter.emit({latitude: Number(mLatLng[0]), longitude: Number(mLatLng[1])})
                break;
            case 2:
                this.locationEmitter.emit({latitude: this.util.convertDMSToDDMin(mLatLng[0]), longitude: this.util.convertDMSToDDMin(mLatLng[1])})
                break;
            case 3:
                this.locationEmitter.emit({latitude: this.util.convertDMSToDD(mLatLng[0]), longitude: this.util.convertDMSToDD(mLatLng[1])})
                break;
        }*/

        this.locationEmitter.emit({latitude: this.util.convertLatLngToDD(mLatLng[0]), longitude: this.util.convertLatLngToDD(mLatLng[1])})

        /*if(!this.isDMS) {
            this.locationEmitter.emit({latitude: this.mLat, longitude: this.mLong})
        } else {
            console.log('mLat: ', this.util.convertDMSToDD(this.mLatDMS))
            console.log('mLong: ', this.util.convertDMSToDD(this.mLongDMS))
            this.locationEmitter.emit({latitude: this.util.convertDMSToDD(this.mLatDMS), longitude: this.util.convertDMSToDD(this.mLongDMS)})
        }*/
    }
    formatLatLng(radio) {
        return '10.5, 44.5 | 10 30.0 N, 44 30.0 E | 10 30 0.0 N, 44 30 0.0 E'
        /*switch (radio) {
            case 1:
                return '10.5, 44.5'
                break;
            case 2:
                return '10 30.0 N, 44 30.0 E'
                break;
            case 3:
                return '10 30 0.0 N, 44 30 0.0 E'
                break;
        }*/
    }

    onSetLocationClear(){
        this.mLatLng = '';
        this.locationEmitter.emit()
    }

}

export interface PeriodicElement {
    time: number;
    title: string;  // @ts-ignore
    significant_tag: string;
    tags: string;
    classification_permissioning: string;
    contributor: string;
}
