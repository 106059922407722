import { DatePipe } from "@angular/common";
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { ChartOptions } from "chart.js";
import { UtilService } from "./../../service/util/util.service";
import { IframeService } from "./../../service/iframe.service";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatPaginator, MatSort } from "@angular/material";
import html2canvas from "html2canvas";
import { url } from "src/app/utils/const";
import { HubspotService } from "src/app/service/hubspot.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-maritime",
  templateUrl: "./maritime.component.html",
  styleUrls: ["./maritime.component.scss"],
})
export class MaritimeComponent implements OnInit {
  public isDateTypeChange: boolean = false;
  endDate = new Date(new Date().setHours(23, 59, 59, 999));
  startDate = new Date(
    new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(
      0,
      0,
      0,
      0
    )
  );

  startTime: any;
  endTime: any;
  public maritimeName: string = "GoG West";

  public topdetailsHTML: any = "";

  public maritimeDetails: any;
  public incidents = [];
  public riskSummary = [];

  public markers = [];
  public displayedColumns = [
    "type",
    "time",
    "title",
    "significant_tag",
    "classification_permissioning",
  ];

  public incidentTable;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /********************************************** */

  public option: ChartOptions = {
    responsive: true,
  };
  public plugins = [];
  public legend = true;

  public mapData: any;

  checkbox = {
    isOverview: false,
    isRiskSummary: false,
    isRiskCategories: false,
    isMaritimeCrimeData: false,
    isIncidentSummaryMap: false,
    isIncidentSummaryTable: true,
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    public utilService: UtilService,
    private iframeService: IframeService,
    private changeDetectorRef: ChangeDetectorRef,
    private httpClient: HttpClient,
    private hubspotService: HubspotService,
    private spinner: NgxSpinnerService
  ) {
    const datePipe: DatePipe = new DatePipe("en-US");
    this.startTime = datePipe.transform(this.startDate, "yyyy-MM-dd");
    this.endTime = datePipe.transform(this.endDate, "yyyy-MM-dd");

    this.mapData = {
      mLat: 0,
      mLng: 0,
      markers: this.markers,
    };
  }

  ngOnInit() {
    const maritimeName = this.activatedRoute.snapshot.params.maritimeName;
    this.maritimeName = maritimeName.replace("_", " ");
    this.onMaritime();
  }

  onChangeRA() {
    this.isDateTypeChange = !this.isDateTypeChange;
  }

  timeType: any = "years";
  timeCount: number = 2;
  onApply() {
    if (!this.isDateTypeChange) {
      this.endDate = new Date(this.endTime);
      this.startDate = new Date(this.startTime);
      this.onMaritime();
    } else {
      const d = new Date();
      var x;
      switch (this.timeType) {
        case "minutes":
          x = d.setMinutes(d.getMinutes() - this.timeCount);
          break;
        case "hours":
          x = d.setHours(d.getHours() - this.timeCount);
          break;
        case "days":
          x = d.setDate(d.getDate() - this.timeCount);
          break;
        case "months":
          x = d.setMonth(d.getMonth() - this.timeCount);
          break;
        case "years":
          x = d.setUTCFullYear(d.getFullYear() - this.timeCount);
          break;
      }
      this.endDate = new Date();
      this.startDate = new Date(x);

      this.onMaritime();
    }
  }

  onMaritime() {
    this.iframeService
      .getMaritimeDetails(this.maritimeName, this.startDate, this.endDate)
      .subscribe((res) => {
        this.maritimeDetails = res.maritimeDetails;
        this.incidents = Array.isArray(res.incidents) ? res.incidents : [];

        this.riskSummary = this.utilService.convertToArrayObj(
          this.maritimeDetails.risk
        );

        this.convertIncident(this.incidents);
      });
  }

  getOverview(summary) {
    return summary.filter((text: any) => {
      if (text.key === "recommendations") {
        return text.value.narrative;
      } else {
        return text.key.toLowerCase() == "overview";
      }
    });
  }

  getSummary(summary) {
    let arr = summary.filter((text: any) => {
      if (text.key === "recommendations") {
        return text.value.narrative ? text.value.narrative.length : false;
      } else {
        return (
          text.key.toLowerCase() === "approach" ||
          text.key.toLowerCase() === "maritime security" ||
          text.key.toLowerCase() === "maritime_security" ||
          text.key.toLowerCase() === "boarding" ||
          text.key.toLowerCase() === "shots fired" ||
          text.key.toLowerCase() === "hijack kidnap" ||
          text.key.toLowerCase() === "militaryinterference" ||
          text.key.toLowerCase() === "environmental" ||
          text.key.toLowerCase() === "geopolitical" ||
          text.key.toLowerCase() === "corruption"
        );
      }
    });

    for (let a of arr) {
      a["priority"] = 11;
      switch (a.key.toLowerCase()) {
        case "overview":
          a["priority"] = 1;
          break;

        case "approach":
          a["priority"] = 2;
          break;

        case "boarding":
          a["priority"] = 3;
          break;

        case "shots fired":
          a["priority"] = 4;
          break;

        case "hijack kidnap":
          a["priority"] = 5;
          break;

        case "maritime security":
          a["priority"] = 6;
          break;
        case "maritime_security":
          a["priority"] = 6;
          break;

        case "militaryinterference":
          a["priority"] = 7;
          break;
        case "environmental":
          a["priority"] = 8;
          break;

        case "geopolitical":
          a["priority"] = 9;
          break;
        case "corruption":
          a["priority"] = 10;
          break;
        case "recommendations":
          a["priority"] = 12;
          break;
      }
    }
    return arr.sort((a, b) => a.priority - b.priority);
  }

  convertIncident(incidents) {
    this.markers = [];
    let incidentArray = [];

    for (const incident of incidents) {
      let modal = new IncidentTableModal();
      modal.title = incident["title"] ? incident["title"] : "";
      modal.time = incident["dtg"] ? incident["dtg"] : "";
      modal.classification_permissioning = incident["classification"][
        "permissioning"
      ]
        ? Array.isArray(incident["classification"]["permissioning"])
          ? incident["classification"]["permissioning"].join()
          : incident["classification"]["permissioning"]
        : "";
      modal.tags = incident["tags"] ? incident["tags"].join() : "";
      modal.significant_tag = incident["significant_tag"]
        ? incident["significant_tag"]
        : "";
      modal.contributor = incident["contributor"]
        ? incident["contributor"]
        : "";
      modal.id = incident["id"] ? incident["id"] : "";
      modal.type = incident["type"] ? incident["type"] : "";
      modal.published = incident["published"] ? incident["published"] : "";
      modal.country = incident["country"]["description"]
        ? incident["country"]["description"]
        : "";
      modal.code = incident["country"]["code"]
        ? incident["country"]["code"]
        : "";
      modal.label =
        incident["dtg_parts"]["month_desc"] && incident["dtg_parts"]["year"]
          ? incident["dtg_parts"]["month_desc"] +
            " " +
            incident["dtg_parts"]["year"]
          : "";

      modal.timestamp = new Date(incident["dtg"]).getTime();

      modal.lat = incident["lat"] ? incident["lat"] : 0;
      modal.lon = incident["lon"] ? incident["lon"] : 0;
      modal.dtg_parts = incident["dtg_parts"]
        ? incident["dtg_parts"]["month_desc"] +
          ", " +
          incident["dtg_parts"]["day"] +
          " " +
          incident["dtg_parts"]["year"]
        : "";

      this.markers.push({
        location: [modal.lat, modal.lon],
        title: modal.title,
        significant_tag: modal.significant_tag,
        id: modal.id,
        dtg_parts: modal.dtg_parts,
      });
      incidentArray.push(modal);
    }
    this.incidentTable = incidentArray
      .filter((val) => {
        return val.published;
      })
      .sort((a, b) => b - a);

    this.incidentTable.paginator = this.paginator;

    this.onChart(this.incidentTable);
  }

  chart1: any = {
    label: [],
    data: [],
  };
  chart2: any = {
    label: [],
    data: [],
  };
  chart3: any = {
    label: [],
    data: [],
  };
  onChart(incidentTable) {
    const label = [...new Set(incidentTable.map((m: any) => m.label))];
    const tags = [...new Set(incidentTable.map((m: any) => m.significant_tag))];
    const regions = [
      ...new Set(incidentTable.map((m: any) => m.classification_permissioning)),
    ];

    this.chart1 = {
      label: label,
      data: tags.map((d: any) => ({
        data: label.map(
          (m) =>
            incidentTable.filter((f) => d == f.significant_tag && f.label == m)
              .length
        ),
        label: d,
        stack: "a",
      })),
      options: {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
    };

    this.chart2 = {
      label: tags,
      data: tags.map(
        (m) => incidentTable.filter((f) => f.significant_tag == m).length
      ),
    };

    this.chart3 = {
      label: regions,
      data: tags.map((d: any) => ({
        data: regions.map(
          (m) =>
            incidentTable.filter(
              (f) =>
                d == f.significant_tag && f.classification_permissioning == m
            ).length
        ),
        label: d,
        stack: "a",
      })),
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: {
                stepSize: 1,
              },
            },
          ],
        },
      },
    };
    this.atMap();
  }

  atMap() {
    if (this.markers.length) {
      this.mapData = {
        mLat: this.markers[0].location[0],
        mLng: this.markers[0].location[1],
        markers: this.markers,
      };
    }
    this.changeDetectorRef.detectChanges();
  }

  isPdfOption = false;
  createFileOption() {
    this.isPdfOption = !this.isPdfOption;
  }

  getSummarySort(summary) {
    let arr = summary.filter((text: any) => {
      return (
        text.key.toLowerCase() === "overview" ||
        text.key.toLowerCase() === "approach" ||
        text.key.toLowerCase() === "maritime security" ||
        text.key.toLowerCase() === "maritime_security" ||
        text.key.toLowerCase() === "boarding" ||
        text.key.toLowerCase() === "shots fired" ||
        text.key.toLowerCase() === "hijack kidnap" ||
        text.key.toLowerCase() === "militaryinterference" ||
        text.key.toLowerCase() === "environmental" ||
        text.key.toLowerCase() === "geopolitical" ||
        text.key.toLowerCase() === "corruption" ||
        text.key === "recommendations" ||
        text.key === "uscglist" ||
        text.key === "ispscompliant" ||
        text.key === "ispslevel" ||
        text.key === "jwrcarea"
      );
    });

    for (let a of arr) {
      a["priority"] = 11;
      switch (a.key.toLowerCase()) {
        case "overview":
          a["priority"] = 1;
          break;

        case "approach":
          a["priority"] = 2;
          break;

        case "boarding":
          a["priority"] = 3;
          break;

        case "shots fired":
          a["priority"] = 4;
          break;

        case "hijack kidnap":
          a["priority"] = 5;
          break;

        case "maritime security":
          a["priority"] = 6;
          break;
        case "maritime_security":
          a["priority"] = 6;
          break;

        case "militaryinterference":
          a["priority"] = 7;
          break;
        case "environmental":
          a["priority"] = 8;
          break;

        case "geopolitical":
          a["priority"] = 9;
          break;
        case "corruption":
          a["priority"] = 10;
          break;
        case "recommendations":
          a["priority"] = 12;
          break;
      }
    }
    return arr.sort((a, b) => a.priority - b.priority);
  }

  createPDF() {
    let m1 = document.getElementById("cMap");
    let g1 = document.getElementById("cbgGraph1");
    let g2 = document.getElementById("cbgGraph2");
    let g3 = document.getElementById("cbgGraph3");

    if (
      this.checkbox.isMaritimeCrimeData ||
      this.checkbox.isIncidentSummaryMap
    ) {
      if (
        this.checkbox.isMaritimeCrimeData &&
        this.checkbox.isIncidentSummaryMap
      ) {
        this.spinner.show();
        html2canvas(g1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((charts1) => {
            html2canvas(g2, {
              allowTaint: true,
              useCORS: true,
            })
              .then((charts2) => {
                html2canvas(g3, {
                  allowTaint: true,
                  useCORS: true,
                })
                  .then((charts3) => {
                    html2canvas(m1, {
                      allowTaint: true,
                      useCORS: true,
                    })
                      .then((map1) => {
                        this.spinner.hide();
                        this.genratePDF(
                          charts1.toDataURL(),
                          charts2.toDataURL(),
                          charts3.toDataURL(),
                          map1.toDataURL()
                        );
                      })
                      .catch((err) => {
                        this.spinner.hide();
                        console.error(err);
                      });
                  })
                  .catch((err) => {
                    this.spinner.hide();
                    console.error(err);
                  });
              })
              .catch((err) => {
                this.spinner.hide();
                console.error(err);
              });
          })
          .catch((err) => {
            this.spinner.hide();
            console.error(err);
          });
      } else if (this.checkbox.isMaritimeCrimeData) {
        this.spinner.show();

        html2canvas(g1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((charts1) => {
            html2canvas(g2, {
              allowTaint: true,
              useCORS: true,
            })
              .then((charts2) => {
                html2canvas(g3, {
                  allowTaint: true,
                  useCORS: true,
                })
                  .then((charts3) => {
                    this.spinner.hide();
                    this.genratePDF(
                      charts1.toDataURL(),
                      charts2.toDataURL(),
                      charts3.toDataURL(),
                      ""
                    );
                  })
                  .catch((err) => {
                    this.spinner.hide();
                    console.log(err);
                  });
              })
              .catch((err) => {
                this.spinner.hide();
                console.log(err);
              });
          })
          .catch((err) => {
            this.spinner.hide();
            console.log(err);
          });
      } else if (this.checkbox.isIncidentSummaryMap) {
        this.spinner.show();
        html2canvas(m1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((map1) => {
            this.spinner.hide();
            this.genratePDF("", "", "", map1.toDataURL());
          })
          .catch((err) => {
            this.spinner.hide();
            console.log(err);
          });
      }
    } else {
      this.genratePDF("", "", "", "");
    }
  }

  genratePDF(chartImg1, chartImg2, chartImg3, mapImage) {
    const title = "Maritime Threat Assessment";
    const name = this.maritimeDetails.title;

    let summaryHTML = "";
    let graphImageHTML = "";
    let mapImageHTML = "";
    let tableHTML = "";

    let topTableHTML = "";
    let topdetailsHTML = "";
    let summary = "";
    let topTable =
      '<tr><th class="bText">Threat Type</th><th class="bText textCenter">Level </th></tr>';

    if (this.checkbox.isOverview || this.checkbox.isRiskCategories) {
      if (this.checkbox.isOverview) {
        const geo_regions = this.maritimeDetails.geo_desc.filter((val) => {
          return val !== "Trial End";
        });
        // topdetailsHTML = '<p class="bText">Associated Countries: ' + this.data.portResponse.country.description.join(', ') + '</p>' +
        //     '<p class="bText">Associated Regions: ' + geo_regions.join(', ') + '</p>';
      }
      for (let x of this.getSummarySort(this.riskSummary)) {
        let desc = "";
        for (let para of x.value.narrative ? x.value.narrative : []) {
          desc += "<p>" + para + "</p>";
        }

        if (this.checkbox.isOverview) {
          if (x.key === "overview" || x.key === "recommendations") {
            if (desc != "") {
              summary +=
                '<p class="bText textRighttt fontSize18"> ' +
                this.utilService.capitalizeFirstLetterXMartine(x.key) +
                this.utilService.getRiskIconX(x.value.indicator, x.key) +
                "</p>" +
                desc +
                "<br>";
            }
          }
        }

        if (this.checkbox.isRiskCategories) {
          if (
            x.key.toLowerCase() === "approach" ||
            x.key.toLowerCase() === "maritime security" ||
            x.key.toLowerCase() === "maritime_security" ||
            x.key.toLowerCase() === "boarding" ||
            x.key.toLowerCase() === "shots fired" ||
            x.key.toLowerCase() === "hijack kidnap" ||
            x.key.toLowerCase() === "militaryinterference" ||
            x.key.toLowerCase() === "environmental" ||
            x.key.toLowerCase() === "geopolitical" ||
            x.key.toLowerCase() === "corruption"
          ) {
            summary +=
              '<p class="bText textRighttt fontSize18"> ' +
              this.utilService.capitalizeFirstLetterXMartine(x.key) +
              this.utilService.getRiskIconX(x.value.indicator, x.key) +
              "</p>" +
              desc +
              "<br>";
          }
        }

        if (
          x.key.toLowerCase() === "approach" ||
          x.key.toLowerCase() === "maritime security" ||
          x.key.toLowerCase() === "maritime_security" ||
          x.key.toLowerCase() === "boarding" ||
          x.key.toLowerCase() === "shots fired" ||
          x.key.toLowerCase() === "hijack kidnap" ||
          x.key.toLowerCase() === "militaryinterference" ||
          x.key.toLowerCase() === "environmental" ||
          x.key.toLowerCase() === "geopolitical" ||
          x.key.toLowerCase() === "corruption"
        ) {
          topTable +=
            "<tr>" +
            "<td>" +
            this.utilService.capitalizeFirstLetterXMartine(x.key) +
            "</td>" +
            '<td style="text-align: center">' +
            this.utilService.getRiskIcon(x.value.indicator, x.key) +
            "</td>";
          ("</tr>");
        }

        if (this.checkbox.isOverview) {
          if (
            x.key === "uscglist" ||
            x.key === "ispscompliant" ||
            x.key === "ispslevel" ||
            x.key === "jwrcarea"
          ) {
            topdetailsHTML +=
              '<p class="bText">' +
              this.utilService.capitalizeFirstLetterXMartine(x.key) +
              ": " +
              this.utilService.getRiskIcon(x.value.indicator, x.key) +
              "</p></br>";
          }
        }
      }

      if (this.checkbox.isRiskCategories) {
        topTableHTML =
          '<table border="1" cellpadding="5" cellspacing="0" width="100%" class="cFontFamily">\n' +
          topTable +
          "</table><br>";
      }
    }
    summaryHTML =
      '<div class="pageDiv">\n' +
      '            <div class="pageContent">\n' +
      '                 <p class="bText fontSize18"> ' +
      title +
      "</p>" +
      '                 <p class="bText cFont">' +
      name +
      "</p>" +
      "                 </br>" +
      topdetailsHTML +
      "</br></br>" +
      topTableHTML +
      '                  <div class="cJustify">' +
      summary +
      "</div>" +
      "                </div>" +
      "            </div>";

    if (this.checkbox.isMaritimeCrimeData) {
      const graphText =
        "Maritime Crime Data: " +
        this.utilService.getDate(this.startDate) +
        " to " +
        this.utilService.getDate(this.endDate);

      graphImageHTML =
        '<div class="page_break"></div>\n' +
        '            <div class="pageDiv">\n' +
        '                <div class="pageContent">\n' +
        '                    <p class="bText fontSize18">' +
        graphText +
        "</p><br><br>\n" +
        '                    <img class="cGraph" src = "' +
        chartImg1 +
        '"/><br><br>\n' +
        '                    <img class="cGraph" src = "' +
        chartImg2 +
        '"/><br><br>\n' +
        '                    <img class="cGraph" src = "' +
        chartImg3 +
        '"/><br><br>\n' +
        "             </div>\n" +
        "            </div>";
    }

    if (this.checkbox.isIncidentSummaryMap) {
      mapImageHTML =
        '<div class="page_break"></div>\n' +
        '            <div class="pageDiv">\n' +
        '                <div class="pageContent">\n' +
        '                    <p class="bText fontSize18">Incident Plot </p><br><br>\n' +
        '                    <img class="cMap" src = "' +
        mapImage +
        '"/>' +
        "                </div>\n" +
        "            </div>";
    }

    if (this.checkbox.isIncidentSummaryTable) {
      const tb = this.incidentTable;
      let table =
        "<tr>\n" +
        '<th class="bText">Time</th>\n' +
        '<th class="bText">Title</th>\n' +
        '<th class="bText">Type</th>\n' +
        '<th class="bText">Area</th>\n' +
        "</tr>";
      for (let x = 0; x < tb.length; x++) {
        table +=
          "<tr>\n" +
          "<td>" +
          this.utilService.getTime(tb[x].time) +
          "</td>\n" +
          "<td>" +
          tb[x].title +
          "</td>\n" +
          "<td>" +
          tb[x].significant_tag +
          "</td>\n" +
          "<td>" +
          tb[x].classification_permissioning +
          "</td>\n" +
          "</tr>";
      }

      tableHTML =
        '<div class="page_break"></div>' +
        '<div class="pageDiv">' +
        '<div class="pageContent">' +
        '<p class="bText fontSize18">Incident Summary Table:</p><br>' +
        '<table border="1" cellpadding="5" cellspacing="0" width="100%">' +
        table +
        "</table>" +
        "</div>" +
        "</div>";
    }

    let d = new Date();
    const body = {
      reportTitle: "Maritime Threat Assessment ",
      reportFor: this.maritimeDetails.title,
      company: this.utilService.company,
      date:
        d.getDate() + "/" + Math.abs(d.getMonth() + 1) + "/" + d.getFullYear(),
      summaryHTML: summaryHTML,
      graphImageHTML: graphImageHTML,
      mapImageHTML: mapImageHTML,
      tableHTML: tableHTML,
    };

    /*
            const bodyFileName = {
                coverFileName: 'dryad_pdf_body.pdf',
                bodyFileName: 'dryad1.pdf'
            };
            this.http.post(url.download,bodyFileName,{
                responseType: 'arraybuffer',
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Accept', 'application/pdf')
            }).subscribe(response=>{
                this.downLoadFile(response, "application/pdf")
            },error2 => {
                // this.spinner.hide();
                console.error(url.country+' error: ',error2)
            });*/

    setTimeout(() => this.spinner.show(), 100);

    this.httpClient.post(url.country, body).subscribe(
      (bodyFileName) => {
        console.log("sucess", bodyFileName);
        let fileNames = {
          coverFileName: "cover-maritime-risk", //coverFileName,
          bodyFileName: bodyFileName,
        };
        setTimeout(() => {
          this.httpClient
            .post(url.download, fileNames, {
              responseType: "arraybuffer",
              headers: new HttpHeaders()
                //.set("Content-Type", "application/x-www-form-urlencoded")
                .set("Accept", "application/pdf"),
            })
            .subscribe(
              (response) => {
                this.spinner.hide();
                this.downLoadFile(response, "application/pdf");
              },
              (error2) => {
                this.spinner.hide();
                console.error("download error: ", error2);
              }
            );
        }, 1000);
      },
      (error2) => {
        this.spinner.hide();
        console.error(url.country + " error: ", error2);
      }
    );
  }

  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download =
      this.maritimeDetails.title + this.utilService.getDateformate() + ".pdf";
    link.click();
    this.isPdfOption = false;
    this.hubspotService.postDownloadDetails(
      this.maritimeDetails.title + " at " + new Date().toISOString()
    );
  }
}

class IncidentTableModal {
  id?: any = "";
  time?: any;
  title?: any;
  significant_tag?: any;
  tags?: any;
  classification_permissioning?: any;
  contributor?: any;
  lat?: any;
  lon?: any;
  dtg_parts?: any;
  type?: any;
  published?: boolean;
  country?: any = "";
  code?: any = "";
  label: any = "";
  timestamp: any;
}

export interface IncidentTable {
  time: number;
  title: string; // @ts-ignore
  significant_tag: string;
  tags: string;
  classification_permissioning: string;
  contributor: string;
}
