import { IframeService } from "./service/iframe.service";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TraComponent } from "./tra/tra.component";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";
import { LeafletDrawModule } from "@asymmetrik/ngx-leaflet-draw";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LoginComponent } from "./auth/login/login.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { SearchHeaderComponent } from "./dashboard/search-header/search-header.component";
import {
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatTableModule,
  MatSortModule,
  MatSlideToggleModule,
  MatSelect,
  MatSelectModule,
} from "@angular/material";
import { FormsModule } from "@angular/forms";
import { IncidentDialogModalComponent } from "./dashboard/incident-dialog-modal/incident-dialog-modal.component";
import { PortGraphDialogModalComponent } from "./dashboard/port-graph-dialog-modal/port-graph-dialog-modal.component";
import { ChartsModule } from "ng2-charts";
import { PortViewPopupComponent } from "./dashboard/port-view-popup/port-view-popup.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { ResetPasswordComponent } from "./profile/reset-password/reset-password.component";
import { SettingComponent } from "./profile/setting/setting.component";
import { ReportsComponent } from "./profile/reports/reports.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { SimpleNotificationsModule } from "angular2-notifications";
import { CountryPopupComponent } from "./dashboard/country-popup/country-popup.component";
import { MigrationPopupComponent } from "./dashboard/migration-popup/migration-popup.component";
import { PortPopupComponent } from "./dashboard/port-popup/port-popup.component";
import { TraPopupComponent } from "./tra/tra-popup/tra-popup.component";

import { AutocompleteLibModule } from "angular-ng-autocomplete";

import { MatExpansionModule } from "@angular/material/expansion";
import { LeafletMapComponent } from "./dashboard/leaflet-map/leaflet-map.component";
import { TokenInterceptor } from "./interceptors/token.interceptors";
import { PortComponent } from "./iframe/port/port.component";
import { CountryComponent } from "./iframe/country/country.component";
import { MaritimeComponent } from "./iframe/maritime/maritime.component";
import { DryadAreaComponent } from "./iframe/dryad-area/dryad-area.component";

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    TraComponent,
    LoginComponent,
    SearchHeaderComponent,
    IncidentDialogModalComponent,
    PortGraphDialogModalComponent,
    PortViewPopupComponent,
    ForgotPasswordComponent,
    SignUpComponent,
    ResetPasswordComponent,
    SettingComponent,
    ReportsComponent,
    CountryPopupComponent,
    MigrationPopupComponent,
    PortPopupComponent,
    TraPopupComponent,
    LeafletMapComponent,
    PortComponent,
    CountryComponent,
    MaritimeComponent,
    DryadAreaComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ChartsModule,
    LeafletModule.forRoot(),
    LeafletDrawModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatButtonModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    AutocompleteLibModule,
    SimpleNotificationsModule.forRoot(),
    MatSelectModule,
  ],
  entryComponents: [
    SearchHeaderComponent,
    IncidentDialogModalComponent,
    PortGraphDialogModalComponent,
    PortViewPopupComponent,
    CountryPopupComponent,
    MigrationPopupComponent,
    PortPopupComponent,
    TraPopupComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
