import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UtilService} from "../../service/util/util.service";
import {code, countryData, countryIncidentData, data, portChartData, portPostData, url} from "../../utils/const";
import {catchError, map} from "rxjs/internal/operators";
import {throwError} from "rxjs/index";

@Injectable({
    providedIn: 'root'
})
export class MapIncidentService {

    constructor(private httpClient: HttpClient, public util: UtilService) {
    }


    getPopDetails(queryType, isNearByDetails, size, searchBy, startTime, endTime, searchBy2?) {

        let must = []

        /*must.push({
            match_all: {}
        })
        must.push({
            match_all: {}
        })*/

        if (size - 1) {
            must.push({
                range: {
                    dtg: {
                        format: "strict_date_optional_time",
                        gte: startTime,
                        lte: endTime
                    }
                }
            })
        }

        /*must.push({
            range: {
                dtg: {
                    format: "strict_date_optional_time",
                    gte: startTime,
                    lte: endTime
                }
            }
        })*/

        switch (queryType) {
            case code.PORTAL_RISK:
                if (!isNearByDetails) {
                    must.push({
                        match_phrase: {
                            _index: {
                                query: queryType
                            }
                        }
                    });

                    must.push({
                        "match_phrase": {
                            "title": {
                                "query": searchBy2
                            }
                        }
                    });

                    must.push({
                        "match_phrase": {
                            "type": {
                                "query": "Port"
                            }
                        }
                    });

                    must.push({
                        "match_phrase": {
                            "status":  {
                                "query": true
                            }
                        }
                    });
                    must.push({
                        "match_phrase": {
                            "published":  {
                                "query": true
                            }
                        }
                    });

                }

                must.push({
                    match_phrase: {
                        "classification.port": {
                            query: searchBy
                        }
                    }
                });


                break;

            case code.DRYAD:
                if (!isNearByDetails) {
                    must.push({
                        match_phrase: {
                            type: {
                                query: queryType
                            }
                        }
                    })

                    must.push({
                        "match_phrase": {
                            "status":  {
                                "query": true
                            }
                        }
                    });
                    must.push({
                        match_phrase: {
                            "published": {
                                "query": true
                            }
                        }
                    })
                }
                if (size - 1) {
                    must.push({
                        match_phrase: {
                            "classification.dryad_area": {
                                query: searchBy
                            }
                        }
                    });
                } else {
                    must.push({
                        match_phrase: {
                            "name": {
                                query: searchBy
                            }
                        }
                    })

                }

                break;
            case code.COUNTRY:
                if (!isNearByDetails) {
                    must.push({
                        match_phrase: {
                            type: {
                                query: queryType
                            }
                        }
                    })

                    must.push({
                        "match_phrase": {
                            "status":  {
                                "query": true
                            }
                        }
                    });
                    must.push({
                        "match_phrase": {
                            "published":  {
                                "query": true
                            }
                        }
                    });
                }
                must.push({
                    match_phrase: {
                        "country.code": {
                            query: searchBy
                        }
                    }
                });
                break;
        }

        if (isNearByDetails) {

            must.push({
                match_phrase: {
                    _index: {
                        query: 'portal-incidents'
                    }
                }
            })
        }

        let jsonData = {
            body: {
                version: true,
                size: size,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    "excludes": []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: must,
                        filter: [],
                        should: [],
                        must_not: []
                    }
                },
                highlight: {
                    pre_tags: [
                        "@kibana-highlighted-field@"
                    ],
                    post_tags: [
                        "@/kibana-highlighted-field@"
                    ],
                    fields: {
                        "*": {}
                    },
                    fragment_size: 2147483647
                }
            },
            currentUser: this.util.currentUser
        }


        console.log('Serch port', JSON.stringify(jsonData))
        return this.getHttp(jsonData)

    }

    getHttp(data) {
        return this.httpClient.post(url.index,
            data, {responseType: 'text'});
    }

    getMaritineChartDetails(dryadCode, startTime, endTime) {

        let countryIncidentDataX = {
            body: {
                version: true,
                size: 500,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    excludes: []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        field: "dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.create_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.updated.timestamp",
                        format: "date_time"
                    },
                    {
                        field: "index_date",
                        format: "date_time"
                    },
                    {
                        field: "last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "migration.reported_date",
                        format: "date_time"
                    },
                    {
                        field: "updated.timestamp",
                        format: "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                match_all: {}
                            },
                            {
                                match_all: {}
                            },
                            {
                                match_phrase: {
                                    "classification.dryad_area.keyword": {
                                        query: dryadCode
                                    }
                                }
                            },
                            {
                                range: {
                                    dtg: {
                                        format: "strict_date_optional_time",
                                        gte: startTime,
                                        lte: endTime
                                    }
                                }
                            }
                        ],
                        "filter": [],
                        "should": [],
                        "must_not": []
                    }
                },
                "highlight": {
                    "pre_tags": [
                        "@kibana-highlighted-field@"
                    ],
                    "post_tags": [
                        "@/kibana-highlighted-field@"
                    ],
                    "fields": {
                        "*": {}
                    },
                    "fragment_size": 2147483647
                }
            },
            currentUser: this.util.currentUser
        }


        return this.httpClient.post(url.index,
            countryIncidentDataX, {responseType: 'text'});
    }

    getMaritineDetails(dryadCode, startTime, endTime) {

        let countryDataX = {
            body: {

                version: true,
                size: 1,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    "excludes": []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                match_all: {}
                            },
                            {
                                match_all: {}
                            },
                            {
                                match_phrase: {
                                    type: {
                                        query: "dryad"
                                    }
                                }
                            },
                            {
                                match_phrase: {
                                    "classification.dryad_area.keyword": {
                                        query: dryadCode
                                    }
                                }
                            },
                            {
                                range: {
                                    dtg: {
                                        format: "strict_date_optional_time",
                                        gte: startTime,
                                        lte: endTime
                                    }
                                }
                            }
                        ],
                        filter: [],
                        should: [],
                        must_not: []
                    }
                },
                highlight: {
                    pre_tags: [
                        "@kibana-highlighted-field@"
                    ],
                    post_tags: [
                        "@/kibana-highlighted-field@"
                    ],
                    fields: {
                        "*": {}
                    },
                    fragment_size: 2147483647
                }
            },
            currentUser: this.util.currentUser
        }

        return this.httpClient.post(url.index,
            countryDataX, {responseType: 'text'});
    }

    getCountryChartDetails(countryCode, startTime, endTime) {

        let countryIncidentDataX = {
            body: {
                version: true,
                size: 500,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    excludes: []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        field: "dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.create_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.updated.timestamp",
                        format: "date_time"
                    },
                    {
                        field: "index_date",
                        format: "date_time"
                    },
                    {
                        field: "last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "migration.reported_date",
                        format: "date_time"
                    },
                    {
                        field: "updated.timestamp",
                        format: "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                match_all: {}
                            },
                            {
                                match_all: {}
                            },
                            {
                                match_phrase: {
                                    "country.code": {
                                        "query": countryCode
                                    }
                                }
                            },
                            {
                                range: {
                                    dtg: {
                                        format: "strict_date_optional_time",
                                        gte: startTime,
                                        lte: endTime
                                    }
                                }
                            }
                        ],
                        "filter": [],
                        "should": [],
                        "must_not": []
                    }
                },
                "highlight": {
                    "pre_tags": [
                        "@kibana-highlighted-field@"
                    ],
                    "post_tags": [
                        "@/kibana-highlighted-field@"
                    ],
                    "fields": {
                        "*": {}
                    },
                    "fragment_size": 2147483647
                }
            },
            currentUser: this.util.currentUser
        }


        return this.httpClient.post(url.index,
            countryIncidentDataX, {responseType: 'text'});
    }

    getCountryDetails(countryCode, startTime, endTime) {

        let countryDataX = {
            body: {

                version: true,
                size: 1,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    "excludes": []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                match_all: {}
                            },
                            {
                                match_all: {}
                            },
                            {
                                match_phrase: {
                                    type: {
                                        query: "country"
                                    }
                                }
                            },
                            {
                                match_phrase: {
                                    "country.code": {
                                        query: countryCode
                                    }
                                }
                            },
                            {
                                range: {
                                    dtg: {
                                        format: "strict_date_optional_time",
                                        gte: startTime,
                                        lte: endTime
                                    }
                                }
                            }
                        ],
                        filter: [],
                        should: [],
                        must_not: []
                    }
                },
                highlight: {
                    "pre_tags": [
                        "@kibana-highlighted-field@"
                    ],
                    "post_tags": [
                        "@/kibana-highlighted-field@"
                    ],
                    "fields": {
                        "*": {}
                    },
                    "fragment_size": 2147483647
                }
            },
            currentUser: this.util.currentUser

        }


        return this.httpClient.post(url.index,
            countryDataX, {responseType: 'text'});
    }

    getPortChartDetails(portName, startTime, endTime) {
        //= "lagos"
        let portChartDataX = {
            "body": {
                "version": true,
                "size": 500,
                "sort": [
                    {
                        "dtg": {
                            "order": "desc",
                            "unmapped_type": "boolean"
                        }
                    }
                ],
                "_source": {
                    "excludes": []
                },
                "stored_fields": [
                    "*"
                ],
                "script_fields": {},
                "docvalue_fields": [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                "match_all": {}
                            },
                            {
                                "match_all": {}
                            },
                            {
                                match_phrase: {
                                    "classification.port": {
                                        query: portName
                                    }
                                }
                            },
                            {
                                range: {
                                    dtg: {
                                        "format": "strict_date_optional_time",
                                        gte: startTime,
                                        lte: endTime
                                    }
                                }
                            }
                        ],
                        filter: [],
                        should: [],
                        must_not: []
                    }
                },
                highlight: {
                    "pre_tags": [
                        "@kibana-highlighted-field@"
                    ],
                    "post_tags": [
                        "@/kibana-highlighted-field@"
                    ],
                    "fields": {
                        "*": {}
                    },
                    "fragment_size": 2147483647
                }
            },
            currentUser: this.util.currentUser
        }

        return this.httpClient.post(url.index,
            portChartDataX, {responseType: 'text'});
    }

    getPortDetails(portName, startTime, endTime) {
        let portPostDataX = {
            "body": {
                "version": true,
                "size": 500,
                "sort": [
                    {
                        "dtg": {
                            "order": "desc",
                            "unmapped_type": "boolean"
                        }
                    }
                ],
                "_source": {
                    "excludes": []
                },
                "stored_fields": [
                    "*"
                ],
                "script_fields": {},
                "docvalue_fields": [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match_all": {}
                            },
                            {
                                "match_all": {}
                            },
                            {
                                "match_phrase": {
                                    "_id": {
                                        "query": "risk.wpi.46130.20"
                                    }
                                }
                            },
                            {
                                "range": {
                                    "dtg": {
                                        "format": "strict_date_optional_time",
                                        "gte": startTime,
                                        "lte": endTime
                                    }
                                }
                            }
                        ],
                        "filter": [],
                        "should": [],
                        "must_not": []
                    }
                },
                "highlight": {
                    "pre_tags": [
                        "@kibana-highlighted-field@"
                    ],
                    "post_tags": [
                        "@/kibana-highlighted-field@"
                    ],
                    "fields": {
                        "*": {}
                    },
                    "fragment_size": 2147483647
                }
            },
            currentUser: this.util.currentUser
        }

        return this.httpClient.post(url.index,
            portPostDataX, {responseType: 'text'});
    }

    getIncidentsPop(bound, startTime, endTime, search, filterList, poly = [], zoom = 1) {

        const search1 = {
            query_string: {
                query: search,
                analyze_wildcard: true,
                time_zone: 'Asia/Calcutta'
            }
        };

        const must = [];
        const x1 = {
            match_all: {}
        };

        const all_filter = [];
        const portfilter = [];
        const incidentfilter = [];
        const countryfilter = [];
        const dryadfilter = [];

        let isAllPortSelected = false;
        let isAllIncidentSelected = false;
        let isAllCountrySelected = false;
        let isAllDryadSelected = false;


        for (const f in filterList) {
            if (filterList[f].match_phrase.hasOwnProperty('type')) {
                all_filter.push(filterList[f]);
                if (filterList[f].match_phrase.type == 'port') {
                    isAllPortSelected = true;
                }
                if (filterList[f].match_phrase.type == 'incident') {
                    isAllIncidentSelected = true;
                }
                if (filterList[f].match_phrase.type == 'country') {
                    isAllCountrySelected = true;
                }
                if (filterList[f].match_phrase.type == 'dryad') {
                    isAllDryadSelected = true;
                }
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.port')) {
                portfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('significant_tag.keyword')) {
                incidentfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('country.description.keyword')) {
                countryfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.dryad_area.keyword')) {
                dryadfilter.push(filterList[f]);
            }
        }
        if (portfilter.length > 0 && !isAllPortSelected) {
            must.push({
                bool: {
                    should: portfilter,
                    minimum_should_match: 1
                }
            });
        }
        if (incidentfilter.length > 0 && !isAllIncidentSelected) {
            must.push({
                bool: {
                    should: incidentfilter,
                    minimum_should_match: 1
                }
            });
        }
        if (countryfilter.length > 0 && !isAllCountrySelected) {
            must.push({
                bool: {
                    should: countryfilter,
                    minimum_should_match: 1
                }
            });
        }
        if (dryadfilter.length > 0 && !isAllDryadSelected) {
            must.push({
                bool: {
                    should: dryadfilter,
                    minimum_should_match: 1
                }
            });
        }

        if (all_filter.length > 0) {
            must.push({
                bool: {
                    should: all_filter,
                    minimum_should_match: 1
                }
            });
        }

        const x3 = {
            range: {
                dtg: {
                    format: 'strict_date_optional_time',
                    gte: startTime,
                    lte: endTime
                }
            }
        };

        // console.log(poly.length, poly)
        if (poly.length !== 0) {
            const polystring = {
                geo_polygon: {
                    ignore_unmapped: true,
                    location: {
                        points: poly
                    }
                }
            };
            must.push(polystring);
        }


        must.push(x1);
        must.push(x3);

        // console.log('must', must)

        if (search) {
            must.push(search1);
        }


        let body = {};
        body = {
            aggs: {
                filter_agg: {
                    filter: {
                        geo_bounding_box: {
                            ignore_unmapped: true,
                            location: {
                                top_right: {
                                    lat: bound.getNorthEast().lat,
                                    lon: bound.getNorthEast().lng
                                },
                                bottom_left: {
                                    lat: bound.getSouthWest().lat,
                                    lon: bound.getSouthWest().lng

                                }
                            }
                        }
                    },
                    aggs: {
                        2: {
                            geohash_grid: {
                                field: 'location',
                                precision: zoom
                            },
                            aggs: {
                                3: {
                                    geo_centroid: {
                                        field: 'location'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            size: 0,
            _source: {
                excludes: []
            },
            stored_fields: [
                '*'
            ],
            script_fields: {},
            docvalue_fields: [
                {
                    field: 'dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.create_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.updated.timestamp',
                    format: 'date_time'
                },
                {
                    field: 'index_date',
                    format: 'date_time'
                },
                {
                    field: 'last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'migration.reported_date',
                    format: 'date_time'
                },
                {
                    field: 'updated.timestamp',
                    format: 'date_time'
                }
            ],
            query: {
                bool: {
                    must,
                    filter: [],
                    should: [],
                    must_not: []
                }
            }
        };


        // console.log('body : ', body);

        return this.httpClient.post(url.index,
            {body, currentUser: this.util.currentUser}, {responseType: 'text'});
    }

    getIncidents(bound, startTime, endTime, search, filterList, poly = [], zoom = 1, zoomLevel) {

        const search1 = {
            query_string: {
                query: search,
                analyze_wildcard: true,
                time_zone: 'Asia/Calcutta'
            }
        };

        const must = [];
        const x1 = {
            match_all: {}
        };
        let size = 0;

        const all_filter = [];
        const portfilter = [];
        const incidentfilter = [];
        const countryfilter = [];
        const dryadfilter = [];

        let isAllPortSelected = false;
        let isAllIncidentSelected = false;
        let isAllCountrySelected = false;
        let isAllDryadSelected = false;
        let isAllMigrationSelected = false;


        for (const f in filterList) {
            if (filterList[f].match_phrase.hasOwnProperty('type')) {
                all_filter.push(filterList[f]);
                if (filterList[f].match_phrase.type == 'port') {
                    isAllPortSelected = true;
                }
                if (filterList[f].match_phrase.type == 'incident') {
                    isAllIncidentSelected = true;
                }
                if (filterList[f].match_phrase.type == 'country') {
                    isAllCountrySelected = true;
                }
                if (filterList[f].match_phrase.type == 'dryad') {
                    isAllDryadSelected = true;
                }
                if (filterList[f].match_phrase.type == 'migration') {
                    isAllMigrationSelected = true;
                }
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.port')) {
                portfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('significant_tag.keyword')) {
                // isAllIncidentSelected = true;
                // all_filter.push({'match_phrase':{'type':'incident'}});
                incidentfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('country.description.keyword')) {
                countryfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.dryad_area.keyword')) {
                dryadfilter.push(filterList[f]);
            }
        }
        let should = {
            "bool": {
                "should": []
            }
        }
        if (zoomLevel >= 5) {
            // size = 1059;
            size = 10000;

            if (isAllPortSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "type.keyword": "Port"
                    }
                });
            }

            if (isAllIncidentSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "_index": {
                            "query": 'portal-incidents'
                        }
                    }
                });
            }

            if (isAllMigrationSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "type": {
                            "query": 'migration'
                        }
                    }
                });
            }

        }
        if (portfilter.length > 0 && !isAllPortSelected) {
            should.bool.should.push(portfilter);
        }
        if (incidentfilter.length > 0 && !isAllIncidentSelected) {
            should.bool.should.push(incidentfilter);
        }
        if (countryfilter.length > 0 && !isAllCountrySelected) {
            should.bool.should.push(countryfilter);
        }
        if (dryadfilter.length > 0 && !isAllDryadSelected) {
            should.bool.should.push(dryadfilter);
        }

        if (all_filter.length > 0) {
            should.bool.should.push(all_filter);
        }

        must.push(should);

        const x3 = {
            range: {
                dtg: {
                    format: 'strict_date_optional_time',
                    gte: startTime,
                    lte: endTime
                }
            }
        };

        if (poly.length !== 0) {
            const polystring = {
                geo_polygon: {
                    ignore_unmapped: true,
                    location: {
                        points: poly
                    }
                }
            };
            must.push(polystring);
        }


        must.push(x1);
        must.push(x3);

        /* TODO search remove due the incident
        if (search) {
            must.push(search1);
        }*/


        let body = {};
        body = {
            aggs: {
                filter_agg: {
                    filter: {
                        geo_bounding_box: {
                            ignore_unmapped: true,
                            location: {
                                top_right: {
                                    lat: bound.getNorthEast().lat,
                                    lon: bound.getNorthEast().lng
                                },
                                bottom_left: {
                                    lat: bound.getSouthWest().lat,
                                    lon: bound.getSouthWest().lng

                                }
                            }
                        }
                    },
                    aggs: {
                        2: {
                            geohash_grid: {
                                field: 'location',
                                precision: zoom
                            },
                            aggs: {
                                3: {
                                    geo_centroid: {
                                        field: 'location'
                                    }
                                }
                            }
                        }
                    }
                }
            },
            size: size,
            _source: {
                excludes: []
            },
            stored_fields: [
                '*'
            ],
            script_fields: {},
            docvalue_fields: [
                {
                    field: 'dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.create_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.updated.timestamp',
                    format: 'date_time'
                },
                {
                    field: 'index_date',
                    format: 'date_time'
                },
                {
                    field: 'last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'migration.reported_date',
                    format: 'date_time'
                },
                {
                    field: 'updated.timestamp',
                    format: 'date_time'
                }
            ],
            query: {
                bool: {
                    must,
                    filter: [],
                    should: [],
                    must_not: []
                }
            }
        };


        console.log('incident body check : ', JSON.stringify(body));

        return this.httpClient.post(url.index,
            {body, currentUser: this.util.currentUser}, {responseType: 'text'});
    }


    getPort() {
        let body = {
            "body": {
                "aggs": {
                    "filter_agg": {
                        "filter": {
                            "geo_bounding_box": {
                                "ignore_unmapped": true,
                                "location": {
                                    "top_right": {
                                        "lat": 7.645664723491027,
                                        "lon": 26.542968750000004
                                    },
                                    "bottom_left": {
                                        "lat": -3.1405161039832357,
                                        "lon": -14.39208984375
                                    }
                                }
                            }
                        },
                        "aggs": {
                            "2": {
                                "geohash_grid": {
                                    "field": "location",
                                    "precision": 4
                                },
                                "aggs": {
                                    "3": {
                                        "geo_centroid": {
                                            "field": "location"
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                "size": 10000,
                "_source": {
                    "excludes": []
                },
                "stored_fields": [
                    "*"
                ],
                "script_fields": {},
                "docvalue_fields": [
                    {
                        "field": "dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.create_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.dtg",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "incident.updated.timestamp",
                        "format": "date_time"
                    },
                    {
                        "field": "index_date",
                        "format": "date_time"
                    },
                    {
                        "field": "last_update_date",
                        "format": "date_time"
                    },
                    {
                        "field": "migration.reported_date",
                        "format": "date_time"
                    },
                    {
                        "field": "updated.timestamp",
                        "format": "date_time"
                    }
                ],
                "query": {
                    "bool": {
                        "must": [
                            {
                                "match_phrase": {
                                    "type.keyword": "Port"
                                }
                            },
                            {
                                "match_phrase": {
                                    "published" : true
                                }
                            },
                            {
                                "match_phrase": {
                                    "status" : true
                                }
                            },
                            {
                                "match_all": {}
                            }
                        ],
                        "filter": [],
                        "should": [],
                        "must_not": []
                    }
                }
            },
            currentUser: this.util.currentUser
        }
        console.log("PORT BODY:: ", JSON.stringify(body));
        return this.httpClient.post(url.index,
            body, {responseType: 'text'});
    }


    getIncident(id) {

        let data1 = {
            body: {
                version: true,
                size: 500,
                sort: [
                    {
                        dtg: {
                            order: "desc",
                            unmapped_type: "boolean"
                        }
                    }
                ],
                _source: {
                    excludes: []
                },
                stored_fields: [
                    "*"
                ],
                script_fields: {},
                docvalue_fields: [
                    {
                        field: "dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.create_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.dtg",
                        format: "date_time"
                    },
                    {
                        field: "incident.last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "incident.updated.timestamp",
                        format: "date_time"
                    },
                    {
                        field: "index_date",
                        format: "date_time"
                    },
                    {
                        field: "last_update_date",
                        format: "date_time"
                    },
                    {
                        field: "migration.reported_date",
                        format: "date_time"
                    },
                    {
                        field: "updated.timestamp",
                        format: "date_time"
                    }
                ],
                query: {
                    bool: {
                        must: [
                            {
                                match_phrase: {
                                    _id: {
                                        query: id
                                    }
                                }
                            }]
                    }
                },
                highlight: {
                    pre_tags: [
                        "@kibana-highlighted-field@"
                    ],
                    post_tags: [
                        "@/kibana-highlighted-field@"
                    ],
                    fields: {
                        "*": {}
                    },
                    fragment_size: 2147483647
                }
            },
            currentUser: this.util.currentUser
        }


        return this.httpClient.post(url.index,
            data1, {responseType: 'text'})
    }


    getIncidentsDetailsData(bound, startTime, endTime, search, filterList, poly = [], zoom = 1, zoomLevel = 6) {
        let size = 0;
        const search1 = {
            query_string: {
                query: search,
                analyze_wildcard: true,
                time_zone: 'Asia/Calcutta'
            }
        };

        const must = [];
        const x1 = {
            match_all: {}
        };

        const all_filter = [];
        const portfilter = [];
        const incidentfilter = [];
        const countryfilter = [];
        const dryadfilter = [];

        let isAllPortSelected = false;
        let isAllIncidentSelected = false;
        let isAllCountrySelected = false;
        let isAllDryadSelected = false;
        let isAllMigrationSelected = false;


        for (const f in filterList) {
            if (filterList[f].match_phrase.hasOwnProperty('type')) {
                all_filter.push(filterList[f]);
                if (filterList[f].match_phrase.type == 'port') {
                    isAllPortSelected = true;
                }
                if (filterList[f].match_phrase.type == 'incident') {
                    isAllIncidentSelected = true;
                }
                if (filterList[f].match_phrase.type == 'country') {
                    isAllCountrySelected = true;
                }
                if (filterList[f].match_phrase.type == 'dryad') {
                    isAllDryadSelected = true;
                }
                if (filterList[f].match_phrase.type == 'migration') {
                    isAllMigrationSelected = true;
                }
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.port')) {
                // portfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('significant_tag.keyword')) {
                incidentfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('country.description.keyword')) {
                countryfilter.push(filterList[f]);
            }
            if (filterList[f].match_phrase.hasOwnProperty('classification.dryad_area.keyword')) {
                dryadfilter.push(filterList[f]);
            }
        }
        let should = {
            "bool": {
                "should": []
            }
        }
        if (zoomLevel >= 5) {
            // size = 1059;
            size = 10000;

            if (isAllPortSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "type.keyword": "Port"
                    }
                });
            }

            if (isAllIncidentSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "_index": {
                            "query": 'portal-incidents'
                        }
                    }
                });
            }

            if (isAllMigrationSelected) {
                should.bool.should.push({
                    "match_phrase": {
                        "type": {
                            "query": 'migration'
                        }
                    }
                });
            }

        }
        if (portfilter.length > 0 && !isAllPortSelected) {
            should.bool.should.push(portfilter);
        }
        if (incidentfilter.length > 0 && !isAllIncidentSelected) {
            should.bool.should.push(incidentfilter);
        }
        if (countryfilter.length > 0 && !isAllCountrySelected) {
            should.bool.should.push(countryfilter);
        }
        if (dryadfilter.length > 0 && !isAllDryadSelected) {
            should.bool.should.push(dryadfilter);
        }

        if (all_filter.length > 0) {
            should.bool.should.push(all_filter);
        }

        must.push(should);

        const x3 = {
            range: {
                dtg: {
                    format: 'strict_date_optional_time',
                    gte: startTime,
                    lte: endTime
                }
            }
        };

        if (poly.length != 0) {
            const polystring = {
                geo_polygon: {
                    ignore_unmapped: true,
                    location: {
                        points: poly
                    }
                }
            };
            must.push(polystring);
        }


        must.push(x1);
        must.push(x3);

        const body = {
            version: true,
            size: 1000,
            sort: [
                {
                    dtg: {
                        order: 'desc',
                        unmapped_type: 'boolean'
                    }
                }
            ],
            _source: {
                excludes: []
            },
            stored_fields: [
                '*'
            ],
            script_fields: {},
            docvalue_fields: [
                {
                    field: 'dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.create_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.updated.timestamp',
                    format: 'date_time'
                },
                {
                    field: 'index_date',
                    format: 'date_time'
                },
                {
                    field: 'last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'migration.reported_date',
                    format: 'date_time'
                },
                {
                    field: 'updated.timestamp',
                    format: 'date_time'
                }
            ],
            query: {
                bool: {
                    must,
                    filter: [],
                    should: [],
                    must_not: []
                }
            },
            highlight: {
                pre_tags: [
                    '@kibana-highlighted-field@'
                ],
                post_tags: [
                    '@/kibana-highlighted-field@'
                ],
                fields: {
                    '*': {}
                },
                fragment_size: 2147483647
            }
        };
        return this.httpClient.post(url.index,
            {body, currentUser: this.util.currentUser}, {responseType: 'text'});
    }

    getCountry(startTime, endTime, search, filterList) {
        const search1 = {
            query_string: {
                query: search,
                analyze_wildcard: true,
                time_zone: 'Asia/Calcutta'
            }
        };

        const must = [];
        const x1 = {
            match_all: {}
        };
        const x2 = {
            bool: {
                should: filterList,
                minimum_should_match: 1
            }
        };
        const x3 = {
            range: {
                dtg: {
                    format: 'strict_date_optional_time',
                    gte: startTime,
                    lte: endTime
                }
            }
        };

        must.push(x1);
        must.push(x2);
        must.push(x3);

        if (search) {
            must.push(search1);
        }


        const body = {
            aggs: {
                2: {
                    terms: {
                        field: 'country.description.keyword',
                        order: {
                            _count: 'desc'
                        },
                        size: 10
                    }
                }
            },
            size: 0,
            _source: {
                excludes: []
            },
            stored_fields: [
                '*'
            ],
            script_fields: {},
            docvalue_fields: [
                {
                    field: 'dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.create_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.dtg',
                    format: 'date_time'
                },
                {
                    field: 'incident.last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'incident.updated.timestamp',
                    format: 'date_time'
                },
                {
                    field: 'index_date',
                    format: 'date_time'
                },
                {
                    field: 'last_update_date',
                    format: 'date_time'
                },
                {
                    field: 'migration.reported_date',
                    format: 'date_time'
                },
                {
                    field: 'updated.timestamp',
                    format: 'date_time'
                }
            ],
            query: {
                bool: {
                    must,
                    filter: [],
                    should: [],
                    must_not: []
                }
            }
        };
        return this.httpClient.post(url.index,
            {body, currentUser: this.util.currentUser}, {responseType: 'text'});

    }


    getTra(points, speed, departure) {
        return this.httpClient.post('http://138.68.175.169/dryad/tra.php',
            {'points': points, 'speed': speed, 'departure': departure}, {responseType: 'text'});

    }


    getCountryNews(countryName) {
        //'http://206.189.124.132/pdf/dryad-dompdf/news.php'
        return this.httpClient.post(url.news,{region: countryName})
            .pipe(map((val: any) => val.features.filter((res: any) => res.geometry.type === 'Point')),
                catchError(err => {
                    // console.log('Handling error locally and rethrowing it...', err);
                    return throwError(err);
                }));
    }

    getCountryNewsVenues(countryName) {
        return this.httpClient.post(url.news_venues,{region: countryName})
            .pipe(map((val: any) => val && val.venues?val.venues:[]),
                catchError(err => {
                    // console.log('Handling error locally and rethrowing it...', err);
                    return throwError(err);
                }));
    }


}
