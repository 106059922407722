import {Component, Input, NgZone, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {canvas, circle, circleMarker, icon, latLng, Map, marker, tileLayer} from "leaflet";
import {UtilService} from "../../service/util/util.service";
import {MapIncidentService} from "../../service/map/map-incident.service";
import {categories} from "../../utils/const";

declare let L;

@Component({
    selector: 'app-leaflet-map',
    templateUrl: './leaflet-map.component.html',
    styleUrls: ['./leaflet-map.component.scss']
})
export class LeafletMapComponent implements OnInit, OnChanges {

    @Input() mLat: any
    @Input() mLng: any
    @Input() iData: any
    @Input() range: any
    @Input() startDate: any
    @Input() endDate: any

    @Input() data: any

    @Input() news: any
    // @Input() incidentMarker: any

    // streetMaps = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
    //     detectRetina: true,
    //     attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    // });

    streetMaps = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}', {
	attribution: 'Tiles &copy; Esri &mdash; National Geographic, Esri, DeLorme, NAVTEQ, UNEP-WCMC, USGS, NASA, ESA, METI, NRCAN, GEBCO, NOAA, iPC',
	// maxZoom: 16
});

    options = {
        layers: [this.streetMaps],
        minZoom: 3,
        maxZoom: 10,
        zoomControl: true,
        drawControl: false,
        zoom: 5,
        attributionControl: false,
        scrollWheelZoom: false,
        center: latLng([0, 0])
    };

    drawOptions = {
        position: 'bottomleft',
        draw: {
            polygon: true,
            polyline: false,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false
        }
    };
    map: any
    markers: any = []
    incidentMarker: any;
    maxCount = 0
    markerList: any = []
    newsMarkers = []

    constructor(private ngZone: NgZone,
                private mapIncident: MapIncidentService,
                public util: UtilService,) {
    }

    ngOnInit() {
        setInterval(() => {
            this.map.invalidateSize()
        }, 1000)
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('SimpleChanges', changes);
        if (null != changes.data && null != changes.data.currentValue) {
            this.data = changes.data.currentValue;
            console.log("leaflet data", JSON.stringify(this.data.markers))
            this.putMarker();
        }
        if (null != changes.news && null != changes.news.currentValue) {
            this.news = changes.news.currentValue;
            this.newsMarker();
        }


        if (null != changes.incidentMarker && null != changes.incidentMarker.currentValue) {
            this.incidentMarker = changes.incidentMarker.currentValue;
            // this.addMarkerToWorld(this.incidentMarker);
        }

        if ((null != changes.iData && null != changes.iData.currentValue)
            || (null != changes.range && null != changes.range.currentValue)
            || (null != changes.startDate && null != changes.startDate.currentValue)
            || (null != changes.endDate && null != changes.endDate.currentValue)) {
            if (null != changes.iData && null != changes.iData.currentValue) {
                this.iData = changes.iData.currentValue;
            }
            if (null != changes.range && null != changes.range.currentValue) {
                this.range = changes.range.currentValue;
            }
            if (null != changes.startDate && null != changes.startDate.currentValue) {
                this.startDate = changes.startDate.currentValue;
            }
            if (null != changes.endDate && null != changes.endDate.currentValue) {
                this.endDate = changes.endDate.currentValue;
            }
            this.incidentDataFetch();
        }


    }

    /*getPopMapMarkPop(poly){
        console.log('idata',this.iData)
        if(this.map){
            this.mapIncident.getIncidents(this.map.getBounds(),this.iData.startTime,this.iData.endTime,'',[],poly,3,6).subscribe(value => {
                console.log('Get Incidents in Map: ',value)
                if(value){
                    this.addMarkerToWorld(JSON.parse(value))
                }
                // this.addMarkerToWorld(value)
            },error=>this.util.error('Get Incidents in Map err: ',error))
        }
    }
*/

    onMapReady(map: Map) {
        console.log('onMapReady leaflet Map', map);
        console.log('onMapReady  leaflet Map in  ', map);
        this.ngZone.run(() => {

            // map.panTo(new L.LatLng(this.data.incidentDetails.lat?this.data.incidentDetails.lat:0, this.data.incidentDetails.lon?this.data.incidentDetails.lon:0));

            this.map = map
            if (this.data) {
                map.panTo(new L.LatLng(this.data.mLat, this.data.mLng));
                this.putMarker()
            } else {
                var latlon = [this.iData.incidentDetails.lat ? this.iData.incidentDetails.lat : 0, this.iData.incidentDetails.lon ? this.iData.incidentDetails.lon : 0]
                // console.log('latlon',latlon)
                map.panTo(new L.LatLng(this.iData.incidentDetails.lat ? this.iData.incidentDetails.lat : 0, this.iData.incidentDetails.lon ? this.iData.incidentDetails.lon : 0));


                var square = this.util.getBoundingCoords(this.iData.incidentDetails.lat ? this.iData.incidentDetails.lat : 0, this.iData.incidentDetails.lon ? this.iData.incidentDetails.lon : 0, this.range)

                let n1 = {lat: square.left.latitude, lon: square.left.longitude};
                let n2 = {lat: square.top.latitude, lon: square.top.longitude};
                let n3 = {lat: square.right.latitude, lon: square.right.longitude};
                let n4 = {lat: square.bottom.latitude, lon: square.bottom.longitude};
                let poly = []
                poly.push(n1);
                poly.push(n2);
                poly.push(n3);
                poly.push(n4);

                setTimeout(() => this.getPopMapMarkPop(poly), 2000);
                // this.getPopMapMarkPop(poly);
            }

        });
    }

    incidentDataFetch() {
        var latlon = [this.iData.incidentDetails.lat ? this.iData.incidentDetails.lat : 0, this.iData.incidentDetails.lon ? this.iData.incidentDetails.lon : 0]
        console.log('latlon', latlon)
        // this.map.panTo(new L.LatLng(this.iData.incidentDetails.lat?this.iData.incidentDetails.lat:0, this.iData.incidentDetails.lon?this.iData.incidentDetails.lon:0));


        var square = this.util.getBoundingCoords(this.iData.incidentDetails.lat ? this.iData.incidentDetails.lat : 0, this.iData.incidentDetails.lon ? this.iData.incidentDetails.lon : 0,
            this.range)

        let n1 = {lat: square.left.latitude, lon: square.left.longitude};
        let n2 = {lat: square.top.latitude, lon: square.top.longitude};
        let n3 = {lat: square.right.latitude, lon: square.right.longitude};
        let n4 = {lat: square.bottom.latitude, lon: square.bottom.longitude};
        let poly = []
        poly.push(n1);
        poly.push(n2);
        poly.push(n3);
        poly.push(n4);
        setTimeout(() => this.getPopMapMarkPop(poly), 2000);
    }

    getPopMapMarkPop(poly) {

        //this.map.getBounds()
        console.log('getBountmap', this.map.getBounds(), JSON.stringify(this.map.getBounds()))
        this.mapIncident.getIncidents(this.map.getBounds(),
            this.startDate, //this.iData.startTime,
            this.endDate, //this.iData.endTime,
            '', [], poly, 3, 6).subscribe(value => {

            console.log('Get Incidents in Map: ', JSON.parse(value))
            // this.addMarkerToWorld(JSON.parse(value))
            // this.popmarker = value
            this.addMarkerToWorld(JSON.parse(value))
            this.incidentMarker = JSON.parse(value);
            // this.spinner.hide()
            // this.createImageById('map1')
            // this.createImageById('chart1')
            // this.createImageById('chart2')

        }, error => this.util.error('Get Incidents in Map err: ', error))
    }

    // addMarkerToWorld(dumpIncidentJson: { took: number; timed_out: boolean; _shards: { total: number; successful: number; skipped: number; failed: number }; hits: { total: number; max_score: null; hits: any[] }; aggregations: { filter_agg: { "2": { buckets: ({ "3": { location: { lat: number; lon: number }; count: number }; key: string; doc_count: number })[] }; doc_count: number } }; status: number }){
    addMarkerToWorld(dumpIncidentJson: any) {

        for (let marker of this.markerList) {
            this.map.removeLayer(marker);
        }

        var hits = dumpIncidentJson.hits.hits

        this.maxCount = 2
        for (let hit of hits) {

            if (hit._source.published) {
                // console.log('out', hit['_source']['lat'], hit['_source']['lon']);
                if (hit._index == "portal-incidents") {
                    if (hit._source && hit._source.lat && hit._source.lon) {
                        // let circlecolor = '#68ff65';
                        // console.log('out', hit['_source']['lat'], hit['_source']['lon']);

                        let circlecolor = 'white';
                        let symbol = '';
                        switch (hit._source.significant_tag) {
                            case "Hijack":
                                circlecolor = 'f8433f';
                                symbol = 'H';
                                break;
                            case "Kidnap":
                                circlecolor = 'fd9b3e';
                                symbol = 'K';
                                break;
                            case "Kidnapping":
                                circlecolor = 'fd9b3e';
                                symbol = 'K';
                                break;
                            case "Attack":
                                circlecolor = 'c27ba0';
                                symbol = 'A';
                                break;
                            case "Fired Upon":
                                circlecolor = '8e7cc3';
                                symbol = 'F';
                                break;
                            case "Boarding":
                                circlecolor = '6d9eeb';
                                symbol = 'B';
                                break;
                            case "Robbery":
                                circlecolor = 'b7b7b7';
                                symbol = 'R';
                                break;
                            case "Attempted":
                                circlecolor = '93c47d';
                                symbol = 'AT';
                                break;
                            case "Approach":
                                circlecolor = '2f6318';
                                symbol = 'AP';
                                break;
                            default:
                                circlecolor = 'white';
                        }
                        // console.log(JSON.stringify(hit._source))
                        let x = new L.marker([hit['_source']['lat'], hit['_source']['lon']], {
                            icon: icon({
                                iconSize: [20, 25],
                                iconAnchor: [0, 0],
                                iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-' + circlecolor + '-' + circlecolor + '-' + symbol + '@2x.png'
                                // shadowUrl: 'leaflet/marker-shadow.png'
                            })
                        }).bindPopup("<p class='bText'> " + hit._source.title + "" + "</p>" +
                            "<p><b>Latitude: </b>" + this.util.getDMS(hit._source.lat, 'lat') + "</p>" +
                            "<p><b>Longitude: </b>" + this.util.getDMS(hit._source.lon, 'long') + "</p>" +
                            "<p><b>Date : </b>" + hit._source.dtg_parts.month_desc + ', '
                            + hit._source.dtg_parts.day + ' '
                            + hit._source.dtg_parts.year + "</p>", {
                            bucket: hit
                        }).on('mouseover', (d) => {
                            x.openPopup()
                        }).on('click', () => {

                            this.ngZone.run(() => {
                                // this.spinner.show();
                                // this.getPopData()
                            })

                        }).addTo(this.map);
                        this.markerList.push(x)

                    }
                }
            }
        }
    }

    newsMarkerX() {
        for (let marker of this.newsMarkers) {
            this.map.removeLayer(marker);
        }
        this.newsMarkers = [];

        for (const data of this.news) {
            let content = '';
            if (data.properties) {
                if (data.properties.picture) {
                    content += '<a href="' + data.properties.picpath + '" target="_blank">' +
                        '<img style=" width: 300px" src="' + data.properties.picture + '"/>' +
                        '</a>';
                }

                content += '<h4>' + data.properties.title.split('http')[0] + '</h4>' +
                    '<p>' + data.properties.message.split('http')[0] +
                    '<a href="' + data.properties.link + '" target="_blank">Click here to read more</a>' +
                    '</p>' +
                    '<p>' +
                    '<a href="' + data.properties.source + '" target="_blank">News source</a>' +
                    '</p>'

            }

            let x = new L.marker(data.geometry.coordinates.reverse(), {
                icon: icon({
                    iconSize: [20, 25],
                    iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-68ceed-ffffff-N@2x.png'
                })
            }).bindPopup(content)
                .on('mouseover', (d) => {
                    x.openPopup()
                }).on('mouseout', (d) => {
                    // x.closePopup()
                }).addTo(this.map);
            this.newsMarkers.push(x);
        }
    }

    getCategories(cId) {
        // console.warn(categories.find(value => value.id === cId).name);
        return categories.find(value => value.id === cId) ? categories.find(value => value.id === cId).name: "";
    }
    newsMarker() {
        for (let marker of this.newsMarkers) {
            this.map.removeLayer(marker);
        }
        this.newsMarkers = [];

        for (const data of this.news) {
            let content = '';

            /*if (data.picture) {
                content += '<a href="' + data.picpath + '" target="_blank">' +
                    '<img style=" width: 300px" src="' + data.picture + '"/>' +
                    '</a>';
            }*/
            const cId = data.cat_id ? data.cat_id : 94;

            content += '<h4>' + data.name.split('http')[0] + '</h4>' +
                '<p>'+this.getCategories(cId)+' - '+this.util.dateFormat(data.timestamp)+'</p>'+
                '<p>'+
                '<a href="' + data.link + '" target="_blank">Click here to read more</a>' +
                '<a style="position: relative; left: 113px;" href="' + data.source + '" target="_blank">News source</a>' +
                '</p>';


            let x = new L.marker([data.lat, data.lng], {
                icon: icon({
                    iconSize: [30, 30],
                    iconUrl: `https://atlas.dryadglobal.com/map-icons/${cId}.png`
                    // iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-68ceed-ffffff-N@2x.png'
                })
            }).bindPopup(content)
                .on('mouseover', (d) => {
                    x.openPopup()
                }).on('mouseout', (d) => {
                    // x.closePopup()
                }).addTo(this.map);
            this.newsMarkers.push(x);
        }
        /*for (const data of this.news) {
            let content = '';
            if (data.properties) {
                if (data.properties.picture) {
                    content += '<a href="' + data.properties.picpath + '" target="_blank">' +
                        '<img style=" width: 300px" src="' + data.properties.picture + '"/>' +
                        '</a>';
                }

                content += '<h4>' + data.properties.title.split('http')[0] + '</h4>' +
                    '<p>' + data.properties.message.split('http')[0] +
                    '<a href="' + data.properties.link + '" target="_blank">Click here to read more</a>' +
                    '</p>' +
                    '<p>' +
                    '<a href="' + data.properties.source + '" target="_blank">News source</a>' +
                    '</p>'

            }

            let x = new L.marker(data.geometry.coordinates.reverse(), {
                icon: icon({
                    iconSize: [20, 25],
                    iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-68ceed-ffffff-N@2x.png'
                })
            }).bindPopup(content)
                .on('mouseover', (d) => {
                    x.openPopup()
                }).on('mouseout', (d) => {
                    // x.closePopup()
                }).addTo(this.map);
            this.newsMarkers.push(x);
        }*/
    }

    putMarker() {
        this.ngZone.run(() => {
            for (let marker of this.markers) {
                this.map.removeLayer(marker);
            }
            this.markers = []

            if (this.data.markers) {
                for (let marker of this.data.markers) {

                    // console.log(marker)

                    let circlecolor = 'white';
                    let symbol = '';
                    switch (marker.significant_tag) {
                        case "Hijack":
                            circlecolor = 'f8433f';
                            symbol = 'H';
                            break;
                        case "Kidnap":
                            circlecolor = 'fd9b3e';
                            symbol = 'K';
                            break;
                        case "Kidnapping":
                            circlecolor = 'fd9b3e';
                            symbol = 'K';
                            break;
                        case "Attack":
                            circlecolor = 'c27ba0';
                            symbol = 'A';
                            break;
                        case "Fired Upon":
                            circlecolor = '8e7cc3';
                            symbol = 'F';
                            break;
                        case "Boarding":
                            circlecolor = '6d9eeb';
                            symbol = 'B';
                            break;
                        case "Robbery":
                            circlecolor = 'b7b7b7';
                            symbol = 'R';
                            break;
                        case "Attempted":
                            circlecolor = '93c47d';
                            symbol = 'AT';
                            break;
                        case "Approach":
                            circlecolor = '2f6318';
                            symbol = 'AP';
                            break;
                        default:
                            circlecolor = 'white';
                    }
                    console.log('marker.location', JSON.stringify(marker.location))
                    if(marker.location) {
                        let x = new L.marker(marker.location, {
                            icon: icon({
                                iconSize: [20, 25],
                                iconAnchor: [0, 0],
                                iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-' + circlecolor + '-' + circlecolor + '-' + symbol + '@2x.png'
                                // shadowUrl: 'leaflet/marker-shadow.png'
                            })
                        }).bindPopup("<h4> " + marker.title + "" + "</h4>" +
                            "<p><b>Latitude: </b>" + this.util.getDMS(marker.location[0], 'lat') + "</p>" +
                            "<p><b>Longitude: </b>" + this.util.getDMS(marker.location[1], 'long') + "</p>" +
                            "<p><b>Date : </b>" + marker.dtg_parts + "</p>").on('mouseover', (d) => {
                            x.openPopup()
                        }).addTo(this.map)

                        this.markers.push(x);
                    }

                }
            }


        })
    }


    getColorMarkerForIncidentCount(n, maxCount) {

        let color: string = '#68ff65';
        if (n < maxCount / 4) {
            color = '#68ff65'
        } else if (n < (maxCount / 4) * 2) {
            color = '#fff928'
        } else if (n < (maxCount / 4) * 3) {
            color = '#ff8a1f'
        } else {
            color = '#ff0721'
        }
        return color
    }

}
