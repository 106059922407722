import {Injectable} from '@angular/core';
import {categories} from "../../utils/const";

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    public currentUser: string = null;
    public username: string = "";
    public password: string = "";
    public company: string = null;

    isDMS: boolean = true

    constructor() {
        // this.currentUser = "asss";
        this.currentUser = localStorage.getItem('currentUser');
        this.company = localStorage.getItem('company');
    }

    logout(){
        this.currentUser = null;
        this.company = null;
    }

    getDateformate() {
        let date = new Date()
        const dd = Math.abs(date.getDate())
        const mon = Math.abs(date.getMonth() + 1)
        let df = '_' + (dd > 9 ? dd : '0' + dd ) + '-' + (mon >= 10 ? mon : '0' + mon) + '-' + date.getFullYear()

        return df
    }

    getRiskColor(n) {
        let color = ''

        switch (n) {
            case 10:
                color = "risk-color-CRITICAL"
                break;
            case 8:
                color = "risk-color-SEVERE"
                break;
            case 5:
                color = "risk-color-SUBSTANTIAL"
                break;
            case 2:
                color = "risk-color-MODERATE"
                break;
            case 1:
                color = "risk-color-LOW"
                break;
        }
        return color
    }

    getRiskMsg(n, text) {
        let name = ''
        if (text.toLowerCase() == 'recommendations' || text.toLowerCase() == 'overview') {
            return ''
        }
        switch (n) {
            case 10:
                name = ' (an incident is highly likely / incidents expected daily)'
                break;
            case 8:
                name = ' (an incident is highly likely / incidents expected weekly)'
                break;
            case 5:
                name = ' (an incident is likely / incidents expected monthly)'
                break;
            case 2:
                name = ' (an incident is a realistic possibility, but not likely)'
                break;
            case 1:
                name = ' (an attack is highly unlikely)'
                break;
        }
        return name
    }

    getIconX(n, text) {
        if (text.toLowerCase() == 'recommendations' || text.toLowerCase() == 'overview') {
            return ''
        }
        if (text.toLowerCase() == 'ispslevel') {
            return '<span>  ' + n + '</span>'
        }
        if (text.toLowerCase() == 'overallriskrating'){
            return '<span class="'+this.getRiskColor(n)+'">'+this.getRiskName(n, text)+'</span>';
        }
        switch (n) {
            case 'yes':
                if (text === 'ispscompliant') {
                    return '<span class="greenx"> YES</span>'
                } else {
                    return '<span class="redx"> YES</span>'
                }
                break;
            case 'no':
                if (text === 'ispscompliant') {
                    return '<span class="redx"> NO</span>'
                } else {
                    return '<span class="greenx"> NO</span>'
                }
                break;
            case 'exceptions':
                return '<span class="orangex"> EXCEPTIONS</span>'
                break;
        }
    }

    getRiskName(n, text) {
        let name = ''
        if (text.toLowerCase() == 'recommendations' || text.toLowerCase() == 'overview') {
            return ''
        }
        switch (n) {
            case 10:
                name = 'CRITICAL'
                break;
            case 8:
                name = 'SEVERE'
                break;
            case 5:
                name = 'SUBSTANTIAL'
                break;
            case 2:
                name = 'MODERATE'
                break;
            case 1:
                name = 'LOW'
                break;
        }
        return name
    }

    getRiskIcon(n, text) {
        let img = ''
        if (text.toLowerCase() == 'recommendations' || text.toLowerCase() == 'overview') {
            return ''
        }
        if (text.toLowerCase() == 'ispslevel') {
            return '<span class="bText">' +
                n +
                '</span>'
        }
        switch (n) {
            case 10:
                // img = '<span class="bText" style="color: #ff0000;" class="bText">CRITICAL</span>'
                img = '<span class="bText" style="color: rgb(225, 0, 0);" class="bText">CRITICAL</span>'

                break;
            case 8:
                // img = '<span class="bText" style="color: #ffc000;">SEVERE</span>'
                img = '<span class="bText" style="color: rgb(237, 125, 49);">SEVERE</span>'
                break;
            case 5:
                // img = '<span class="bText" style="color: #ffc000;">SUBSTANTIAL</span>'
                img = '<span class="bText" style="color: rgb( 225, 192, 0);">SUBSTANTIAL</span>'
                break;
            case 2:
                // img = '<span class="bText" style="color: #00f400;">MODERATE</span>'
                img = '<span class="bText" style="color: rgb(0, 176, 80);">MODERATE</span>'
                break;
            case 1:
                // img = '<span class="bText" style="color: #00f400;">LOW</span>'
                img = '<span class="bText" style="color: rgb(122, 173, 71);">LOW</span>'
                break;

            case 'yes':
                if (text === 'ispscompliant') {
                    // img = '<span class="bText" style="color: #00f400;">YES</span>'
                    img = '<span class="bText" style="color: rgb(122, 173, 71);">YES</span>'
                } else {
                    // img = '<span class="bText" style="color: #ff0000;">YES</span>'
                    img = '<span class="bText" style="color: rgb(225, 0, 0);">YES</span>'
                }
                break;
            case 'no':
                if (text === 'ispscompliant') {
                    // img = '<span class="bText" style="color: #ff0000;">NO</span>'
                    img = '<span class="bText" style="color: rgb(225, 0, 0);">NO</span>'
                } else {
                    // img = '<span class="bText" style="color: #00f400;">NO</span>'
                    img = '<span class="bText" style="color: rgb(122, 173, 71);">NO</span>'
                }
                break;
            case 'exceptions':
                // img = '<span class="bText" style="color: #ffc000;">EXCEPTIONS</span>'
                img = '<span class="bText" style="color: rgb( 225, 192, 0);">EXCEPTIONS</span>'
                break;
        }
        return img
    }

    getRiskIconX(n, text) {

        /*CRITICAL (an incident is highly likely / incidents expected daily)
SEVERE (an incident is highly likely / incidents expected weekly)
SUBSTANTIAL (an incident is likely / incidents expected monthly)
MODERATE (an incident is a realistic possibility, but not likely)
LOW (an attack is highly unlikely)*/

        let img = ''
        if (text.toLowerCase() == 'recommendations' || text.toLowerCase() == 'overview') {
            return ''
        }
        if (text.toLowerCase() == 'ispslevel') {
            return '<span class="bText">n</span>'
        }
        switch (n) {
            case 10:
                img = '<span class="bText" style="color: rgb(225, 0, 0);"> CRITICAL</span>' +
                    '<span style="color: #000;font-weight: 400;font-family: Arial, Helvetica, sans-serif;">' +
                    ' (an incident is highly likely / incidents expected daily)' +
                    '</span>';
                break;
            case 8:
                img = '<span class="bText" style="color: rgb(237, 125, 49);"> SEVERE</span>' +
                    '<span style="color: #000;font-weight: 400;font-family: Arial, Helvetica, sans-serif;">' +
                    ' (an incident is highly likely / incidents expected weekly)' +
                    '</span>'
                break;
            case 5:
                img = '<span class="bText" style="color: rgb( 225, 192, 0);"> SUBSTANTIAL</span>' +
                    '<span style="color: #000;font-weight: 400;font-family: Arial, Helvetica, sans-serif;">' +
                    ' (an incident is likely / incidents expected monthly)' +
                    '</span>'
                break;
            case 2:
                img = '<span class="bText" style="color: rgb(0, 176, 80);"> MODERATE</span>' +
                    '<span class="bText" style="color: #000;font-weight: 400;font-family: Arial, Helvetica, sans-serif;">' +
                    ' (an incident is a realistic possibility, but not likely)' +
                    '</span>'
                break;
            case 1:
                img = '<span class="bText" style="color: rgb(122, 173, 71);"> LOW</span>' +
                    '<span class="bText"style="color: #000;font-weight: 400;font-family: Arial, Helvetica, sans-serif;">' +
                    ' (an attack is highly unlikely)' +
                    '</span>'
                break;

            case 'yes':
                // img = '<span class="bText" style="color: #ff0000;">YES</span>'
                if (text === 'ispscompliant') {
                    img = '<span class="bText" style="color: rgb(122, 173, 71);">YES</span>'
                } else {
                    img = '<span class="bText" style="color: rgb(225, 0, 0);">YES</span>'
                }
                break;
            case 'no':
                if (text === 'ispscompliant') {
                    img = '<span class="bText" style="color: rgb(225, 0, 0);">NO</span>'
                } else {
                    img = '<span class="bText" style="color: rgb(122, 173, 71);">NO</span>'
                }
                // img = '<span class="bText" style="color: #00f400;">NO</span>'
                break;
            case 'exceptions':
                img = '<span class="bText" style="color: rgb( 225, 192, 0);">EXCEPTIONS</span>'
                break;


        }
        return img
    }


    getColourIcon(n) {
        if (n) {
            if (n < 4) {
                return '<img src="pdf-html/images/circle-low.jpg" class="signImages"/>'
            } else if (n < 7) {
                return '<img src="pdf-html/images/circle-moderate.jpg" class="signImages"/>'
            } else if (n < 9) {
                return '<img src="pdf-html/images/circle-elevated.jpg" class="signImages"/>'
            } else {
                return '<img src="pdf-html/images/circle-high.jpg" class="signImages"/>'
            }
        } else {
            return ''
        }
    }

    getHTML(n) {
        if (n) {
            if (n < 4) {
                return '<td><img src="pdf-html/images/circle-low.jpg" class="signImages">Low</td>'
            } else if (n < 7) {
                return '<td><img src="pdf-html/images/circle-moderate.jpg" class="signImages"> Moderate</td>'
            } else if (n < 9) {
                return '<td><img src="pdf-html/images/circle-elevated.jpg" class="signImages"> High</td>'
            } else {
                return '<td><img src="pdf-html/images/circle-high.jpg" class="signImages"> High</td>'
            }
        } else {
            return '<td></td>'
        }

    }


    changeDMS() {
        this.isDMS = !this.isDMS
    }

    getDMS(dd, longOrLat) {
        if (this.isDMS) {

            let hemisphere = /^[WE]|(?:lon)/i.test(longOrLat)
                ? dd < 0
                    ? "W"
                    : "E"
                : dd < 0
                    ? "S"
                    : "N";

            const absDD = Math.abs(dd);
            const degrees = this.truncate(absDD);
            const minutes = this.truncate((absDD - degrees) * 60);
            const seconds = ((absDD - degrees - minutes / 60) * Math.pow(60, 2)).toFixed(2);

            let dmsArray = [degrees, minutes, seconds, hemisphere];
            return `${dmsArray[0]}°${dmsArray[1]}'${dmsArray[2]}" ${dmsArray[3]}`;
        } else {
            return Math.round(parseFloat(dd) * 10000) / 10000
        }
    }

    convertDMSToDD(dms) {
        let parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/).filter(val => val !== '');
        let degrees = parseFloat(parts[0]);
        let minutes = parseFloat(parts[1]);
        let seconds = parseFloat(parts[2].replace(',', '.'));
        let direction = parts[3];

        // console.log('degrees: ' + degrees)
        // console.log('minutes: ' + minutes)
        // console.log('seconds: ' + seconds)
        // console.log('direction: ' + direction)

        let dd = degrees + minutes / 60 + seconds / (60 * 60);

        if (direction == 'S' || direction == 'W') {
            dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
    }

    convertDMSToDDMin(dms) {
        let parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/).filter(val => val !== '');
        let degrees = parseFloat(parts[0]);
        let minutes = parseFloat(parts[1].replace(',', '.'));
        // let seconds = parseFloat(parts[2].replace(',','.'));
        let direction = parts[2];

        // console.log('degrees: ' + degrees)
        // console.log('minutes: ' + minutes)
        // console.log('seconds: '+seconds)
        // console.log('direction: ' + direction)

        let dd = degrees + minutes / 60;

        if (direction == 'S' || direction == 'W') {
            dd = dd * -1;
        } // Don't do anything for N or E
        return dd;
    }

    convertLatLngToDD(dms) {
        let parts = dms.split(/[^\d+(\,\d+)\d+(\.\d+)?\w]+/).filter(val => val !== '');

        if (parts.length == 1) {
            return Number(dms)
        } else if (parts.length == 3) {

            let degrees = parseFloat(parts[0]);
            let minutes = parseFloat(parts[1]);
            // let seconds = parseFloat(parts[2].replace(',','.'));
            let direction = parts[2];

            let dd = degrees + minutes / 60;

            if (direction == 'S' || direction == 'W') {
                dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
        } else if (parts.length == 4) {
            let degrees = parseFloat(parts[0]);
            let minutes = parseFloat(parts[1]);
            let seconds = parseFloat(parts[2].replace(',', '.'));
            let direction = parts[3];

            let dd = degrees + minutes / 60 + seconds / (60 * 60);

            if (direction == 'S' || direction == 'W') {
                dd = dd * -1;
            } // Don't do anything for N or E
            return dd;
        }

    }

    capitalizeFirstLetterXMartine(string) {


        if (string.toLowerCase() === 'maritime') {
            string = 'Maritime Security Overview';
        } else if (string.toLowerCase() === 'maritime security' || string.toLowerCase() === 'maritime_security') {
            // }else if (string.toLowerCase() === 'robbery') {
            string = 'Threat of Vessel Robbery';
        } else if (string.toLowerCase() === 'approach') {
            string = 'Threat of Approach and Irregular Maritime Behaviour';
        } else if (string.toLowerCase() === 'shots fired') {
            //change
            string = 'Threat of Shots fired at Vessel';
        } else if (string.toLowerCase() === 'boarding') {
            //change
            string = 'Threat of Illegal Boarding';
        } else if (string.toLowerCase() === 'militaryinterference') {
            //change
            string = 'Threat of Interference from Military and Paramilitary Forces';
        } else if (string.toLowerCase() === 'environmental') {
            //change
            string = 'Threat from Environmental Factor';
        } else if (string.toLowerCase() === 'geopolitical') {
            //change
            string = 'Threat from Geopolitical Events';
        }

        else if (string.toLowerCase() === 'overview') {
            string = 'Region Overview';
        } else if (string.toLowerCase() === 'personnel outside') {
            string = 'Threat to Personnel Outside Port Area';
        } else if (string.toLowerCase() === 'robbery') {
            string = 'Threat of Robbery and Cargo Theft';
        } else if (string.toLowerCase() === 'armed boarding') {
            // string = 'Threat from Illegal Boarding';
            string = 'Threat of Vessel Boarding';
        } else if (string.toLowerCase() === 'hijack kidnap') {
            string = 'Threat of Hijack & Kidnap';
        } else if (string.toLowerCase() === 'terrorism') {
            string = 'Threat from Terrorism';
        } else if (string.toLowerCase() === 'military') {
            string = 'Threat of Military Activity';
        } else if (string.toLowerCase() === 'industrial action') {
            string = 'Threat of Industrial Action';
        } else if (string.toLowerCase() === 'corruption') {
            string = 'Threat from Fraud and Corruption';
        } else if (string.toLowerCase() === 'recommendations') {
            string = 'Recommendations';
        }


        else if (string.toLowerCase() === 'uscglist') {
            string = 'USCG List';
        } else if (string.toLowerCase() === 'ispscompliant') {
            string = 'ISPS Compliant';
        } else if (string.toLowerCase() === 'ispslevel') {
            string = 'ISPS Level';
        } else if (string.toLowerCase() === 'jwrcarea') {
            string = 'JWRC Area'
        }


        return string.charAt(0).toUpperCase() + string.slice(1);


    }

    capitalizeFirstLetter(string) {


        if (string.toLowerCase() === 'maritime') {
            string = 'Maritime Security Overview';
        } else if (string.toLowerCase() === 'description') {
            string = 'Port Description';
        } else if (string.toLowerCase() === 'operational') {
            string = 'Key Operational Details';
        } else if (string.toLowerCase() === 'securitycontext') {
            string = 'Security Context';
        }else if (string.toLowerCase() === 'additional') {
            string = 'Additional Information';
        }
        else if (string.toLowerCase() === 'ashore') {
            string = 'Shoreside Security Overview';
        } else if (string.toLowerCase() === 'migration') {
            string = 'Threat from Migration and Trafficking';
        } else if (string.toLowerCase() === 'overview') {
            string = 'Port Overview';
        } else if (string.toLowerCase() === 'personnel outside') {
            string = 'Threat to Personnel Outside Port Area';
        } else if (string.toLowerCase() === 'robbery') {
            string = 'Threat of Robbery and Cargo Theft';
        } else if (string.toLowerCase() === 'armed boarding') {
            // string = 'Threat from Illegal Boarding';
            string = 'Threat of Vessel Boarding';
        } else if (string.toLowerCase() === 'hijack kidnap') {
            string = 'Threat of Hijack & Kidnap';
            // string = 'Threat of Military Acitvity';


        } else if (string.toLowerCase() === 'terrorism') {
            string = 'Threat from Terrorism';
        } else if (string.toLowerCase() === 'military') {

            string = 'Threat of Military Activity';

        } else if (string.toLowerCase() === 'industrial action') {
            string = 'Threat of Industrial Action';
        } else if (string.toLowerCase() === 'corruption') {
            string = 'Threat from Fraud and Corruption';
        } else if (string.toLowerCase() === 'recommendations') {
            string = 'Recommendations';
        } else if (string.toLowerCase() === 'uscglist') {
            string = 'USCG List';
        } else if (string.toLowerCase() === 'ispscompliant') {
            string = 'ISPS Compliant';
        } else if (string.toLowerCase() === 'ispslevel') {
            string = 'ISPS Level';
        } else if (string.toLowerCase() === 'jwrcarea') {
            string = 'JWRC Area'
        }


        return string.charAt(0).toUpperCase() + string.slice(1);


    }

    capitalizeFirstLetterXC(string) {


        if (string.toLowerCase() === 'maritime') {
            string = 'Maritime Security';
        } else if (string.toLowerCase() === 'ashore') {
            string = 'Shoreside Security';
        } else if (string.toLowerCase() === 'overallriskrating') {
            string = 'Overall Risk Rating';
        } else if (string.toLowerCase() === 'overview') {
            string = 'Overview';
        } else if (string.toLowerCase() === 'personnel outside') {
            string = 'Threat to Personnel Outside Port Area';
        } else if (string.toLowerCase() === 'robbery') {
            string = 'Threat of Robbery and Cargo Theft';
        } else if (string.toLowerCase() === 'armed boarding') {
            // string = 'Threat from Illegal Boarding';
            string = 'Threat of Vessel Boarding';
        } else if (string.toLowerCase() === 'hijack kidnap') {
            string = 'Threat of Hijack & Kidnap';
            // string = 'Threat of Military Acitvity';


        } else if (string.toLowerCase() === 'terrorism') {
            string = 'Threat from Terrorism';
        } else if (string.toLowerCase() === 'military') {

            string = 'Threat of Military Activity';

        } else if (string.toLowerCase() === 'industrial action') {
            string = 'Threat of Industrial Action';
        } else if (string.toLowerCase() === 'corruption') {
            string = 'Threat from Fraud and Corruption';
        } else if (string.toLowerCase() === 'recommendations') {
            string = 'Recommendations';
        } else if (string.toLowerCase() === 'uscglist') {
            string = 'USCG List';
        } else if (string.toLowerCase() === 'ispscompliant') {
            string = 'ISPS Compliant';
        } else if (string.toLowerCase() === 'ispslevel') {
            string = 'ISPS Level';
        } else if (string.toLowerCase() === 'jwrcarea') {
            string = 'JWRC Area'
        }


        return string.charAt(0).toUpperCase() + string.slice(1);


    }

    innerHTML(para) {
        if (para) {
            return '<p class="innerHtmlAlign">' + para + '</p>';
        } else {
            return '';
        }
    }

    convertToArray(obj) {
        var result = Object.keys(obj).map(function (key) {
            return key
        });
        return result
    }

    getTime(time) {
        time = new Date(time)
        const mon = Math.abs(time.getMonth() + 1)
        const dd = Math.abs(time.getDate())
        return ( dd > 9 ? dd : '0' + dd) +
            '/' + (mon > 9 ? mon : '0' + mon) + '/' + time.getFullYear() + ' ' +
            (time.getHours() > 9 ? time.getHours() : '0' + time.getHours()) +
            ':' + (time.getMinutes() > 9 ? time.getMinutes() : '0' + time.getMinutes())
    }

    getDate(time) {
        time = new Date(time)
        return time.getDate() + '/' + Math.abs(time.getMonth() + 1) + '/' + time.getFullYear();
    }

    convertToArrayObj(obj) {
        var result = Object.keys(obj).map(function (key, value) {
            return {key: key.replace('_', ' '), value: obj[key]}
        });
        return result
    }

    convertToArrayObjValue(obj) {
        var result = Object.keys(obj).map(function (key, value) {
            return obj[key]
        });
        return result
    }

    log(tag, params) {
        // console.log(tag, params)
    }

    error(tag, params) {
        // console.error(tag, params)
    }

    getBoundingCoords(centerLat, centerLong, distance) {
        distance = distance * 0.539957  //TODO to check convert km to n mile
        let top = this.maxLatLongOnBearing(centerLat, centerLong, 45, distance);
        let right = this.maxLatLongOnBearing(centerLat, centerLong, 135, distance);
        let bottom = this.maxLatLongOnBearing(centerLat, centerLong, 225, distance);
        let left = this.maxLatLongOnBearing(centerLat, centerLong, 315, distance);

        let result = {
            top: top,
            right: right,
            bottom: bottom,
            left: left
        }

        return result
    }

    maxLatLongOnBearing(centerLat, centerLong, bearing, distance) {
        var latRads = this.toRadians(centerLat);
        var lonRads = this.toRadians(centerLong);
        var bearingRads = this.toRadians(bearing);

        var maxLatRads = Math.asin(Math.sin(latRads) * Math.cos(distance / 6371) + Math.cos(latRads) * Math.sin(distance / 6371) * Math.cos(bearingRads));
        var maxLonRads = lonRads + Math.atan2((Math.sin(bearingRads) * Math.sin(distance / 6371) * Math.cos(latRads)), (Math.cos(distance / 6371) - Math.sin(latRads) * Math.sin(maxLatRads)));

        var maxLat = this.toDegrees(maxLatRads);
        var maxLong = this.toDegrees(maxLonRads);

        let result = {
            latitude: maxLat,
            longitude: maxLong
        }

        return result;
    }

    toDegrees(angle) {
        return angle * (180 / Math.PI);
    }

    toRadians(angle) {
        return angle * (Math.PI / 180);
    }
    getCategories(cId) {
        // console.warn(categories.find(value => value.id === cId).name);
        return categories.find(value => value.id === cId) ? categories.find(value => value.id === cId).name: "";
    }

    dateFormat(timestamp: any) {
        const date = new Date(timestamp * 1000);
        return date.getUTCDate() + ' ' + this.months(date.getUTCMonth()) + ', ' + date.getUTCFullYear();
    }

    private truncate(n) {
        return n > 0 ? Math.floor(n) : Math.ceil(n);
    }

    private months(a: any) {
        let b = "";
        switch (a) {
            case 0:
                b = "January";
                break;
            case 1:
                b = "February";
                break;
            case 2:
                b = "March";
                break;
            case 3:
                b = "April";
                break;
            case 4:
                b = "May";
                break;
            case 5:
                b = "June";
                break;
            case 6:
                b = "July";
                break;
            case 7:
                b = "August";
                break;
            case 8:
                b = "September";
                break;
            case 9:
                b = "October";
                break;
            case 10:
                b = "November";
                break;
            case 11:
                b = "December";
                break;
        }
        return b;
    }


}
