import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {proxyurl, url} from '../utils/const';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private http: HttpHeaders = new HttpHeaders();
  private userDetails: any = {};

  constructor(private httpClient: HttpClient) { }

  login(obj) {
    // const obj = {
    //   username: 'jay@connect91.com',
    //   password: 'T%^v5f3^Sq'
    // }
    // return this.httpClient.post('http://138.68.175.169/dryad/login.php', obj);
    return this.httpClient.post(url.login, obj);
  }
}
