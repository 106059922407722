import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

    emailId:string = '';
    password:string = '';
    comfirmPassword:string ='';
    constructor(private router: Router) { }

  ngOnInit() {
  }

    onLogin(){
        this.router.navigate(['/login/'])
    }
    onSignUp() {

    }
}
