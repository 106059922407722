import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { TraComponent } from "./tra/tra.component";
import { LoginComponent } from "./auth/login/login.component";
import { SignUpComponent } from "./auth/sign-up/sign-up.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { SettingComponent } from "./profile/setting/setting.component";
import { ResetPasswordComponent } from "./profile/reset-password/reset-password.component";
import { ReportsComponent } from "./profile/reports/reports.component";
import { AuthGuard } from "./interceptors/auth.guard";
import { DryadAreaComponent } from "./iframe/dryad-area/dryad-area.component";
import { MaritimeComponent } from "./iframe/maritime/maritime.component";
import { CountryComponent } from "./iframe/country/country.component";
import { PortComponent } from "./iframe/port/port.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "forget-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "sign-up",
    component: SignUpComponent,
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tra",
    component: TraComponent,
  },
  {
    path: "settings",
    component: SettingComponent,
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
  },
  {
    path: "report",
    component: ReportsComponent,
  },
  {
    path: "iframe-port/:portName",
    component: PortComponent,
  },
  {
    path: "iframe-port/:portName/:lat/:long",
    component: PortComponent,
  },
  // {
  //     path: 'iframe-country',
  //     component: CountryComponent
  // },
  {
    path: "iframe-country/:countryCode",
    component: CountryComponent,
  },
  {
    path: "iframe-maritime",
    component: MaritimeComponent,
  },
  {
    path: "iframe-maritime/:maritimeName",
    component: MaritimeComponent,
  },
  // , {
  //     path: 'iframe-dryad-area',
  //     component: DryadAreaComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
