import {Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {
    MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort,
    MatTableDataSource
} from "@angular/material";
import {MapIncidentService} from "../../service/map/map-incident.service";
// import {utilService} from "../../service/util/util.service";
import {PortGraphDialogModalComponent} from "../port-graph-dialog-modal/port-graph-dialog-modal.component";
import {NgxSpinnerService} from "ngx-spinner";
import {SingleDataSet} from "ng2-charts";
import {ChartDataSets} from "chart.js";
// import {HttpHeaders} from "@angular/common/http";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {UtilService} from "../../service/util/util.service";
import {code, url} from "../../utils/const";
import {CountryPopupComponent} from "../country-popup/country-popup.component";
import {MigrationPopupComponent} from "../migration-popup/migration-popup.component";

@Component({
  selector: 'app-incident-dialog-modal',
  templateUrl: './incident-dialog-modal.component.html',
  styleUrls: ['./incident-dialog-modal.component.scss']
})
export class IncidentDialogModalComponent implements OnInit {

  displayedColumns = ['type','time', 'title', 'significant_tag','classification_permissioning','star' ];// 'tags' ,, 'contributor'

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private ngZone:NgZone,
              private mapIncident:MapIncidentService,
              private dialogRef:MatDialogRef<IncidentDialogModalComponent>,
              private matDialog:MatDialog,
              private http:HttpClient,
              public util:UtilService,
              private spinner: NgxSpinnerService
  ) {
    this.data.tableData.paginator = this.paginator
  }

  ngOnInit() {
    this.data.tableData.paginator = this.paginator;
    this.data.tableData.sort = this.sort;
  }


  onIncident(element){
      this.spinner.show()

      let significantTag={}
      let dateSignificantTag={}
      let locationChart={}

      let types = {}

      let dataSourcePop=[]
      let portList=[]
      let incidentList=[]
      let migrationList=[]


      let portObject={}
      this.mapIncident.getIncident(element.id).subscribe(value=>{
          var incidentDataJump = JSON.parse(value)
          var x = incidentDataJump.hits.hits[0]._source
          var lat = 0
          var lon = 0

          if(x.lat){
              lat=x.lat
          }
          if(x.lon){
              lat=x.lon
          }
          var square = this.util.getBoundingCoords(lat, lon,2000)

          let n1 = {lat:square.left.latitude,lon:square.left.longitude};
          let n2 = {lat:square.top.latitude,lon:square.top.longitude};
          let n3 = {lat:square.right.latitude,lon:square.right.longitude};
          let n4 = {lat:square.bottom.latitude,lon:square.bottom.longitude};

          let poly = []
          poly.push(n1);
          poly.push(n2);
          poly.push(n3);
          poly.push(n4);

          const sub = this.mapIncident.getIncidentsDetailsData(this.data.bound,this.data.startTime,this.data.endTime,'',[],poly,4).subscribe(val=>{
              if(val) {
                  var hit = {}
                  hit = JSON.parse(val)
                  for (let data of hit['hits']['hits']) {


                      const x = data['_source']['type']
                      if (x.toLowerCase() == 'port') {
                          const obj = {}
                          obj['value'] = data['_source']['title']
                          obj['status'] = true
                          portList.push(obj)
                      }


                      if (x.toLowerCase() == 'migration') {
                          migrationList.push(data['_source']['title'])
                      }

                      types[x] = true

                      let modal = new IncidentTableModal()
                      modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                      modal.time = data['_source']['index_date'] ? data['_source']['index_date'] : ''
                      modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                      modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                      modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                      modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                      modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                      modal.lat = data['_source']['lat']?data['_source']['lat'] : 0
                      modal.lon = data['_source']['lon']?data['_source']['lon'] : 0

                      modal.type = data['_source']['type']?data['_source']['type'] : ''
                      modal.published = data['_source']['published'] ? data['_source']['published'] : false

                      // markers.push([modal.lat,modal.lon])


                      let date = 'unknown'

                      if (data['_source']['dtg_parts']) {
                          let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                          let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                          date = month + ' ' + year
                      }

                      let l = data['_source']['classification']['permissioning']
                      if (l) {
                          if (Array.isArray(l)) {

                              for (let a of l) {
                                  locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                  locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                              }

                          } else {
                              locationChart[l] = locationChart[l] ? locationChart[l] : {};
                              locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                          }
                      } else {
                          locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                          locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                      }


                      dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                      dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                      significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                      dataSourcePop.push(modal)
                  }

                  /***********************************************************/

                  var label1 = this.util.convertToArray(dateSignificantTag);
                  var label2 = this.util.convertToArray(significantTag);
                  var label3 = this.util.convertToArray(locationChart);

                  var barChartData1: ChartDataSets[]=[];
                  var barChartData2: SingleDataSet = [];
                  var barChartData3: ChartDataSets[]=[];


                  for(let x of label2){
                      let data2 = [];
                      barChartData2.push(significantTag[x])

                      let data1=[]
                      for(let y of label1){
                          data1.push(dateSignificantTag[y][x]?dateSignificantTag[y][x]:0);
                      }
                      barChartData1.push({
                          data:data1,
                          label:x,
                          stack:'a'
                      })

                      let data3=[]
                      for(let y of label3){
                          data3.push(locationChart[y][x]?locationChart[y][x]:0);
                      }
                      barChartData3.push({
                          data:data3,
                          label:x,
                          stack:'a'
                      })
                  }


                  let chart1={
                      label:label1.reverse(),
                      data:barChartData1.reverse()
                  };
                  let chart2={
                      label:label2,
                      data:barChartData2
                  };
                  let chart3={
                      label:label3,
                      data:barChartData3
                  };



                  this.mapIncident.getIncidentsPop(this.data.bound,this.data.startTime,this.data.endTime,'',[],poly,4).subscribe(value1 => {

                      var popmarker = value1

                      this.ngZone.run(()=>{
                          var tag = []
                          this.spinner.hide();

                          const matDialogConfig = new MatDialogConfig();
                          matDialogConfig.height = "80%";
                          matDialogConfig.width = "75%";
                          matDialogConfig.data = {
                              startTime:this.data.startTime,
                              endTime:this.data.endTime,
                              chart1:chart1,
                              chart2:chart3,
                              incidentDetails:incidentDataJump.hits.hits[0]._source,
                              marker: popmarker,
                              dataSource:new MatTableDataSource<PeriodicElement>(dataSourcePop)
                          }
                          const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

                          dialogRef.afterClosed().subscribe(result => {
                              this.util.log('Dialog result: ', result)
                          });
                          this.spinner.hide()

                      })

                  },error=>this.util.error('Get Incidents in Map err: ',error))



              }
              sub.unsubscribe()
          },err=>this.util.error('mirgation err: ',err))


      },err=>this.util.log('err',err))

  }

  onViewMore(element,type,code){
    switch (type){
        case 'Incident':
            this.onIncident(element)
            break;
        case 'Country':
            this.onCountry(code)
            break;
        case 'Dryad':
            this.onMaritine(element.title)
            break;
        case 'Port':
            break;

    }
  }

    createFile() {
        this.spinner.show();

        let table = '<tr>\n' +
            '<th class="bText">Time</th>\n' +
            '<th class="bText">Title</th>\n' +
            '<th class="bText">Type</th>\n' +
            '<th class="bText">Area</th>\n' +
            '</tr>';

        let tb = this.data.table
        console.log('tb:\n',JSON.stringify(tb))
        for(let x =0; x< tb.length;x++){
            table += '<tr>\n' +
                '<th>'+ this.util.getTime(tb[x].time) +'</th>\n' +
                '<th>'+ tb[x].title+'</th>\n' +
                '<th>'+ tb[x].significant_tag+'</th>\n' +
                '<th>'+ tb[x].classification_permissioning+'</th>\n' +
                '</tr>'
        }

        let tableHTML = ''

        tableHTML = '<div class="pageContent" style="">\n' +
            '<p class="bText">Incident Summary Table:</p>' +
            '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
            table+
            '</table></div></div>'

        this.getPDF(tableHTML)

    }

    getPDF(tableHTML){

        let d =new Date()
        // const url = 'http://159.65.152.29/dryad-dompdf/dataTablePDF.php'
        this.http.post(url.dataTable,{
            tableHTML: tableHTML,
            company:this.util.company,
            date: d.getDate()+'/'+Math.abs(d.getMonth()+1)+'/'+d.getFullYear()
        },{
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/pdf')
        }).subscribe(response=>{

            this.downLoadFile(response, "application/pdf")

            console.log(response,'sssss')
        },error2 => {
            console.log('errrrrrrrr',error2)
        })
    }

    downLoadFile(data: any, type: string) {

        this.spinner.hide()
        let blob = new Blob([data], { type: type});

        var link=document.createElement('a');
        link.href=window.URL.createObjectURL(blob);
        link.download = 'IncidentSheet' +this.util.getDateformate()+".pdf";
        link.click();
    }


    onCountry(countryCode){
        this.spinner.show()
        this.mapIncident.getPopDetails(code.COUNTRY,false,1,countryCode,this.data.startTime,this.data.endTime).subscribe(value=>{
            var port = {}
            port = JSON.parse(value)
            console.log('value:',value)


            this.mapIncident.getPopDetails(code.COUNTRY,true,1000,countryCode,this.data.startTime,this.data.endTime).subscribe(val=>{

                console.log('val1000',val)

                let markers = []
                let types = {}
                let dataSourcePop=[]

                let significantTag:any={}
                let dateSignificantTag:any={}

                let locationChart:any={}

                let portList=[]
                let incidentList=[]
                let migrationList=[]

                let portObject={}

                if(val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['index_date'] ? data['_source']['index_date'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat']?data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon']?data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts']? data['_source']['dtg_parts']['month_desc']+', '+data['_source']['dtg_parts']['day']+' '+data['_source']['dtg_parts']['year']:''
                        modal.type = data['_source']['type']?data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        markers.push({
                            location:[modal.lat,modal.lon],
                            title:modal.title,
                            significant_tag:modal.significant_tag,
                            id:modal.id,
                            dtg_parts:modal.dtg_parts
                        })


                        // markers.push([modal.lat,modal.lon])

                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.util.convertToArray(dateSignificantTag);
                    var label2 = this.util.convertToArray(significantTag);
                    var label3 = this.util.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[]=[];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[]=[];

                    for(let c of label2){
                    }


                    for(let x of label2){
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1=[]
                        for(let y of label1){
                            data1.push(dateSignificantTag[y][x]?dateSignificantTag[y][x]:0);
                        }
                        barChartData1.push({
                            data:data1,
                            label:x,
                            stack:'a'
                        })

                        let data3=[]
                        for(let y of label3){
                            data3.push(locationChart[y][x]?locationChart[y][x]:0);
                        }
                        barChartData3.push({
                            data:data3,
                            label:x,
                            stack:'a'
                        })
                    }


                    let chart1={
                        label:label1.reverse(),
                        data:barChartData1.reverse()
                    };
                    let chart2={
                        label:label2,
                        data:barChartData2
                    };
                    let chart3={
                        label:label3,
                        data:barChartData3
                    };


                    const dataSource1 = dataSourcePop.filter(val=>{
                        return val.published
                    })

                    if(port['hits']["hits"].length){
                        const matDialogConfig = new MatDialogConfig()
                        matDialogConfig.height = "70%";
                        matDialogConfig.width = "65%";
                        matDialogConfig.data = {
                            // mLat:this.mLat,
                            // mLng:this.mLng,
                            mLat:0,
                            mLng:0,
                            markers:markers,
                            startTime:this.data.startTime,
                            endTime:this.data.endTime,
                            dataSource:new MatTableDataSource<PeriodicElement>(dataSource1),
                            table:dataSource1,
                            chart1:chart1,
                            chart2:chart2,
                            chart3:chart3,
                            portResponse : port['hits']["hits"][0]['_source']
                        }
                        this.spinner.hide()
                        const dialogRef = this.matDialog.open(CountryPopupComponent,matDialogConfig);
                        dialogRef.afterClosed().subscribe(result => {
                            this.util.log('Dialog result: ',result)
                        });
                    }else{
                        this.spinner.hide()
                    }
                    /*********************************************************/

                    /************************************************************/
                }

            },err2=>{
                this.spinner.hide()
                this.util.error('err',err2)
            })

        },err=>{
            this.spinner.hide()
            this.util.error('err: ',err)
        })
    }
    onMaritine(dryadCode){
        // "GoG - East"
        let markers:any=[]
        this.spinner.show()
        //getMaritineDetails
        this.mapIncident.getPopDetails(code.DRYAD,false,1,dryadCode,this.data.startTime,this.data.endTime).subscribe(value=>{
            var port = {}
            port = JSON.parse(value)
            if(port['hits']["hits"].length == 0){
                this.spinner.hide()
                return;
            }
            //getMaritineChartDetails
            this.mapIncident.getPopDetails(code.DRYAD,true,1000,dryadCode,this.data.startTime,this.data.endTime).subscribe(val=>{


                let types = {}
                let dataSourcePop=[]

                let significantTag:any={}
                let dateSignificantTag:any={}

                let locationChart:any={}

                let portList=[]
                let incidentList=[]
                let migrationList=[]

                let portObject={}

                if(val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['index_date'] ? data['_source']['index_date'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat']?data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon']?data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts']? data['_source']['dtg_parts']['month_desc']+', '+data['_source']['dtg_parts']['day']+' '+data['_source']['dtg_parts']['year']:''
                        modal.type = data['_source']['type']?data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                        modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''

                        markers.push({
                            location:[modal.lat,modal.lon],
                            title:modal.title,
                            significant_tag:modal.significant_tag,
                            id:modal.id,
                            dtg_parts:modal.dtg_parts
                        })


                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

/*<<<<<<< HEAD
        tableHTML = '<div class="pageContent" >\n' +
            '<p class="bText">Incident Summary Table:</p>' +
            '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
            table+
            '</table></div></div>'
=======*/
                        dataSourcePop.push(modal)
                    }
/*>>>>>>> fa3834a222c065a75beba4ea12a5d0671737e0f4*/

                    /***********************************************************/

                    var label1 = this.util.convertToArray(dateSignificantTag);
                    var label2 = this.util.convertToArray(significantTag);
                    var label3 = this.util.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[]=[];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[]=[];



                    for(let x of label2){
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1=[]
                        for(let y of label1){
                            data1.push(dateSignificantTag[y][x]?dateSignificantTag[y][x]:0);
                        }
                        barChartData1.push({
                            data:data1,
                            label:x,
                            stack:'a'
                        })

                        let data3=[]
                        for(let y of label3){
                            data3.push(locationChart[y][x]?locationChart[y][x]:0);
                        }
                        barChartData3.push({
                            data:data3,
                            label:x,
                            stack:'a'
                        })
                    }


                    let chart1={
                        label:label1,
                        data:barChartData1
                    };
                    let chart2={
                        label:label2,
                        data:barChartData2
                    };
                    let chart3={
                        label:label3,
                        data:barChartData3
                    };





                    /*********************************************************/
                    const matDialogConfig = new MatDialogConfig()
                    matDialogConfig.height = "70%";
                    matDialogConfig.width = "65%";

                    const dataSource2 = dataSourcePop.filter(val=>{
                        return val.published
                    })



                    matDialogConfig.data = {
                        // mLat:this.mLat,
                        // mLng:this.mLng,
                        mLat:0,
                        mLng:0,
                        markers:markers,
                        startTime:this.data.startTime,
                        endTime:this.data.endTime,
                        dataSource:new MatTableDataSource<PeriodicElement>(dataSource2),
                        table:dataSource2,
                        chart1:chart1,
                        chart2:chart2,
                        chart3:chart3,
                        portResponse : port['hits']["hits"][0]['_source']
                    }
                    this.spinner.hide()
                    const dialogRef = this.matDialog.open(MigrationPopupComponent,matDialogConfig);
                    dialogRef.afterClosed().subscribe(result => {
                        this.util.log('Dialog result: ',result)
                    });

                    /************************************************************/
                }

            },err2=>this.util.error('err',err2))

        },err=>this.util.error('err: ',err))
    }

    getCategory(str) {
        if(str === 'Dryad') {
            return 'Martime Area';
        }
        return str;
    }
}


class IncidentTableModal{
    id?:any=''
    time?:any
    title?:any
    significant_tag?:any
    tags?:any
    classification_permissioning?:any
    contributor?:any
    lat?:any
    lon?:any
    type?:any
    dtg_parts?:any
    published?:any
    country?:any=''
    code?:any=''

}
export interface PeriodicElement {
    time: number;
    title: string;  // @ts-ignore
    significant_tag: string;
    tags:string;
    classification_permissioning: string;
    contributor:string;
}