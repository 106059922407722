import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../service/authentication.service";
import {UtilService} from "../../service/util/util.service";
import {NgxSpinnerService} from "ngx-spinner";
import {NotificationsService} from "angular2-notifications";
import {HubspotService} from "../../service/hubspot.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    // emailId:string = 'jay@connect91.com'
    // password:string = 'T%^v5f3^Sq'
    emailId: string = '';
    password: string = '';
    notOptions = {
        'position': ['top', 'center'],
        'theClass': "notifcx"
    };

    constructor(private router: Router,
                private spinner: NgxSpinnerService,
                private _service: NotificationsService,
                private auth: AuthenticationService,
                private hubspotService: HubspotService,
                public util: UtilService) {
    }

    ngOnInit() {
        this.spinner.hide();
    }

    onLogin() {
        this.spinner.show();
        this.auth.login({
            "username": this.emailId,
            "password": this.password
        }).subscribe((value: any) => {
            this.spinner.hide();
            if (value['token']) {
                localStorage.setItem('currentUser', value['token']);
                localStorage.setItem('company', value['email']);
                localStorage.setItem('userDetails', JSON.stringify(value));
                this.util.currentUser = value['token'];
                this.util.company = value['email'];
                this.hubspotService.updateOnLogin(value);
                this.router.navigate(['/dashboard/'])
            } else {
                this._service.error('Login', 'username and password is incorrect', {
                    timeOut: 2000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
            }

        });
    }

    onSignUp() {
        this.router.navigate(['/sign-up/'])
    }

}
