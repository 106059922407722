import { Component, OnInit } from '@angular/core';
import {Color, Label, SingleDataSet} from "ng2-charts";
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-port-view-popup',
  templateUrl: './port-view-popup.component.html',
  styleUrls: ['./port-view-popup.component.scss']
})
export class PortViewPopupComponent implements OnInit {

    lineChartData: ChartDataSets[] = [
        { data: [85, 72, 78, 75, 77, 75], label: 'Incident Occurred' },
    ];

    lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

    lineChartOptions = {
        responsive: true,
    };

    lineChartColors: Color[] = [
        {
            borderColor: 'black',
            backgroundColor: 'rgba(255,255,0,0.28)',
        },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    //Pie Chart
    public pieChartOptions: ChartOptions = {
        responsive: true,
    };
    // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
    public pieChartLabels: Label[] = ['Robbery','Boarding','FiredUpon','Approach','Kidnap','Attak',
        'Attempted','Hijack'];

    public pieChartData: SingleDataSet = [300, 50, 100, 200, 0, 0, 0, 90];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];





  constructor(private dialogRef:MatDialogRef<PortViewPopupComponent>) { }


  ngOnInit() {
  }


    onClose(){
        this.dialogRef.close()
    }
}
