import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {url} from "../utils/const";

const hapikey = "82193de6-8485-4c2c-9e63-d2038e68cb41";

@Injectable({
    providedIn: 'root'
})
export class HubspotService {

    constructor(private http: HttpClient) {
    }

    updateOnLogin(user: any) {
        const property = [];
        property.push({
            property: "last_logon",
            value: new Date().setUTCHours(0, 0, 0, 0)
        });

        if (!user.admin) {
            property.push({
                property: "is_atlas_subscriber",
                value: true
            });
            if (user.activation_date && user.subscription_period_months) {
                const activationDate = new Date(user.activation_date).setUTCHours(0, 0, 0, 0);
                const date = new Date(activationDate)
                const months = Number(user.subscription_period_months);
                const expiredDate = date.setUTCMonth(date.getUTCMonth() + months);

                property.push({
                    property: "subscription_expiry_date",
                    value: expiredDate
                });
            }

        } else {
            property.push({
                property: "is_atlas_subscriber",
                value: false
            });
        }
        this.getContactDetails(user.email).subscribe((contact: any) => {
            if (contact.status == "error") {
                property.push({
                    "property": "email",
                    "value": user.email
                });
                property.push({
                    "property": "firstname",
                    "value": user.name
                });

                this.createContact(property);
            } else {
                this.updateContact(user.email, property)
            }
        });

    }

    postDownloadDetails(report_name) {
        const email = localStorage.getItem('company');
        // const email = "aashaykarekar@gmail.com";

        this.getContactDetails(email).subscribe((contact: any) => {
            const noOfDownload = contact.properties && contact.properties.number_of_downloads && contact.properties.number_of_downloads.value
                ? Number(contact.properties.number_of_downloads.value) : 0;
            // console.log("noOfDownload ", contact.properties['number_of_downloads'].value ,"====", noOfDownload)
            const property = [];
            property.push({
                property: "last_report_downloaded",
                value: report_name
            });
            property.push({
                property: "number_of_downloads",
                value: noOfDownload + 1
            });


            this.updateContact(email, property);

        });
    }

    postLast5SearchQueries(search_name) {
        const email = localStorage.getItem('company');
        // const email = "aashaykarekar@gmail.com";

        this.getContactDetails(email).subscribe(async (contact: any) => {
            let list = contact.properties && contact.properties.last_5_search_queries && contact.properties.last_5_search_queries.value
                ? contact.properties.last_5_search_queries.value.split(", ") : [];

            // const list = JSON.parse(JSON.stringify(ll))
            // console.log("aa ", list)
            list.push(search_name)
            // console.log(list, search_name, contact.properties.last_5_search_queries.value)
            if (list.length > 5) {
                list.splice(0, 1)
            }
            const property = [];
            property.push({
                property: "last_5_search_queries",
                value: list.join(", ")
            });
            setTimeout(() => this.updateContact(email, property), 500);
        });
    }

    postLastViewItem(data) {
        const email = localStorage.getItem('company');
        // const email = "aashaykarekar@gmail.com";
        const property = [];
        property.push({
            property: "last_viewed_item",
            value: data + " at " + new Date().toISOString()
        });
        this.updateContact(email, property);
    }

    private updateContact(email, list) {
        // email = "aashaykarekar@gmail.com";
        const body = {
            email: email,
            hapikey: hapikey,
            body: {
                "properties": list
            }
        };

        return this.http.post(url.hubspot, body).subscribe(res => console.log("hubspot", res));
    }

    private createContact(list) {
        const body = {
            body: {
                "properties": list
            }
        };
        this.http.post(url.hubspot_createcontact, body).subscribe(res => console.log("hubspot Create Contact", res))
    }

    private getContactDetails(email) {
        const body = {
            email: email,
            hapikey: hapikey,
        };
        return this.http.post(url.hubspot_getcontact, body);
    }
}