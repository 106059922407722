import { Component, NgZone, OnInit } from '@angular/core';
import { circle, circleMarker, icon, latLng, Map, marker, point, polyline, tileLayer } from 'leaflet';
import { LeafletDrawDirective, LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { MatDialog, MatDialogConfig, MatTableDataSource } from "@angular/material";
import { MapIncidentService } from "../service/map/map-incident.service";
import { UtilService } from "../service/util/util.service";
import { IncidentDialogModalComponent } from "./incident-dialog-modal/incident-dialog-modal.component"
import leafletImage from 'leaflet-image';
import html2canvas from 'html2canvas';
import { Router } from "@angular/router";
import { PortGraphDialogModalComponent } from "./port-graph-dialog-modal/port-graph-dialog-modal.component";
import { IncidentReportService } from "../service/dialog/incident-report.service";
import { PortViewPopupComponent } from "./port-view-popup/port-view-popup.component";
import { ChartDataSets } from "chart.js";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import { CountryPopupComponent } from "./country-popup/country-popup.component";

import * as countriesGeoJson from 'src/assets/countries.geo.json';
import * as dryadAreasJson from 'src/assets/new-dryad-areas.json';
import * as corridorsJson from 'src/assets/transitCorridors.json';

import * as nigeriaterminalsJson from 'src/assets/nigeriaterminals.json';
import * as nigeriaterminalsezJson from 'src/assets/nigeriaterminalsez.json';

import * as patrolJson from 'src/assets/patrol.json';
import * as iofaJson from 'src/assets/iofa.json';

import * as sts1Json from 'src/assets/sts1.json';
import * as sts2Json from 'src/assets/sts2.json';
import * as sts3Json from 'src/assets/sts3.json';
import * as sts4Json from 'src/assets/sts4.json';
import * as sts5Json from 'src/assets/sts5.json';
import * as indianOceanHRA from 'src/assets/indianOceanHRA.json';
import * as westAfricaHRA from 'src/assets/westAfricaHRA.json';

import * as gogjwc from 'src/assets/gogjwc.json';
import * as indianOceanJWC from 'src/assets/indianOceanJWC.json';
import * as asian12 from 'src/assets/asian12.json';
import * as middleeast12 from 'src/assets/middleeastjwc.json';
import * as southamerica12 from 'src/assets/southamericajwc.json';
import * as africa12 from 'src/assets/africajwc.json';
import * as allworldeez from 'src/assets/allworldeez.json';
import * as allworld12eez from 'src/assets/allworld12eez.json';
import * as nigeriaeez from 'src/assets/nigeriaeez.json';
import * as soatomeeez from 'src/assets/soatomeeez.json';
import { PortPopupComponent } from "./port-popup/port-popup.component";
import { categories, code, countryCode, geoJSON, noDataMsg, portResponseData } from "../utils/const";
import { SingleDataSet } from "ng2-charts";
import { MigrationPopupComponent } from "./migration-popup/migration-popup.component";
import { isNullOrUndefined, isUndefined } from "util";
import { HubspotService } from "../service/hubspot.service";

declare let L;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    testJson = { 'features': [] };

    utcDate = new Date().toUTCString();

    latestNotification: any;

    notOptions = {
        'position': ['top', 'center'],
        'theClass': "notifc"
    }
    // zoom:number = 3

    dryadAreas = (dryadAreasJson as any).default;
    countriesGeo = (countriesGeoJson as any).default;
    transitCorridors = (corridorsJson as any).default;

    nigeriaterminalsJson = (nigeriaterminalsJson as any).default;
    nigeriaterminalsezJson = (nigeriaterminalsezJson as any).default;

    partolJson = (patrolJson as any).default;
    iofaJson = (iofaJson as any).default;

    sts1Json = (sts1Json as any).default;
    sts2Json = (sts2Json as any).default;
    sts3Json = (sts3Json as any).default;
    sts4Json = (sts4Json as any).default;
    sts5Json = (sts5Json as any).default;

    indianOceanHRAJson = (indianOceanHRA as any).default;

    westAfricaHRAJson = (westAfricaHRA as any).default;

    indianOceanJWCJSON = (indianOceanJWC as any).default;

    gogJWCJSON = (gogjwc as any).default;

    asia12JSON = (asian12 as any).default;

    middleeast12JSON = (middleeast12 as any).default;

    southamericaJSON = (southamerica12 as any).default;

    africa12JSON = (africa12 as any).default;

    allworldeezJSON = (allworldeez as any).default;

    allworld12eezJSON = (allworld12eez as any).default;

    nigeriaeezJSON = (nigeriaeez as any).default;

    soatomeeezJSON = (soatomeeez as any).default;

    sts1: any;
    sts2: any;
    sts3: any;
    sts4: any;
    sts5: any;

    indianOceanHRA: any;
    westAfricaHRA: any;
    patrolZones: any;
    iofa: any;
    dryadPolygons: any;
    countryAreas: any;
    corridors: any;
    nigeriaterminals: any;
    nigeriaterminalsez: any;

    indianOceanJWC: any;
    gogJWC: any;
    asia12: any;
    middleeast12: any;
    southamerica12: any;
    africa12: any;

    allworldeez: any;
    allworld12eez: any;
    nigeriaeez: any;
    soatomeeez: any;

    // streetMaps = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
    //     detectRetina: true,
    //     attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    // });

    streetMaps = tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    });

    seaMarks = tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
        attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    });
    layersControl = {
        baseLayers: {
            'Street Maps': this.streetMaps,
            'Sea Marks': this.seaMarks
        }
    };


    // darkMap = tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
    //     detectRetina: true,
    //     attribution: '&amp;copy; &lt;a href="https://www.openstreetmap.org/copyright"&gt;OpenStreetMap&lt;/a&gt; contributors'
    // });
    Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });
    options = {
        layers: [this.Esri_WorldImagery, this.seaMarks],
        minZoom: 3,
        maxZoom: 14,
        zoomControl: false,
        drawControl: false,
        zoom: 3,//6
        center: latLng([20.632784250388028, -12.304687500000002])
        // center: latLng([0.2856433479945185,  0.7580566406250001])
    };
    darkMap = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
        detectRetina: true,
        attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ'
    });
    mapCanvas: any
    circle = circleMarker([46.78465227596462, -121.74141269177198], {
        radius: 50, color: '#ff0000',
        fillOpacity: 0.6,
        weight: 1
    })
    circle2 = circle([46.8523, -121.7603], {
        radius: 1000, color: '#ff0000',
        fillOpacity: 0.6,
        weight: 1
    });
    summit = marker([46.8523, -121.7603], {
        icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'leaflet/marker-icon.png',
            shadowUrl: 'leaflet/marker-shadow.png'
        })
    });
    mLat: any = 0
    mLng: any = 0
    drawOptions = {
        position: 'bottomleft',
        draw: {
            polygon: true,
            polyline: false,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false
        }
    };


    map: any

    allPort: any = []

    public drawClear = true;
    ports: {
        took: number; timed_out: boolean; _shards: { total: number; successful: number; skipped: number; failed: number };
        hits: { total: number; max_score: null; hits: any[] }; aggregations: { filter_agg: { "2": { buckets: ({ "3": { location: { lat: number; lon: number }; count: number }; key: string; doc_count: number })[] }; doc_count: number } }; status: number
    }
    countryList: any = []


    /*******************************/
    migrationList: any = []
    incidentList: any = []
    portList: any = []
    totalIncidentCount: number = 0
    startTime: any
    endTime: any
    search: any = ''
    filterList: any = []
    poly: any = []
    zoom: number = 2
    zoomLevel: number = 3
    maxCount: number = 0
    dataSource: any = []
    markerList: any = []
    incidentData: any
    popmarker: any
    dataSourcePop = []
    significantTag: any = {}
    dateSignificantTag: any = {}
    locationChart: any = {}
    chart1: any = {}
    chart2: any = {}
    portMarkerList: any = []
    isPort: boolean = false
    isOceanMap: boolean = true
    whichMap: number = 0
    public crossHair = []
    newsMarkers = []

    constructor(private mapIncident: MapIncidentService,
        private matDialog: MatDialog,
        public util: UtilService,
        private spinner: NgxSpinnerService,
        private incidentReportService: IncidentReportService,
        private ngZone: NgZone,
        private router: Router,
        private hubspotService: HubspotService,
        private _service: NotificationsService) {
        const date = new Date().setMonth(new Date().getMonth() - 6)
        this.startTime = new Date(date)
        this.endTime = new Date()
        this.incidentReportService.setStartTime(this.startTime)
        this.incidentReportService.setEndTime(this.endTime)

        this.getAllFilter()


    }

    onMapReady(map: Map) {

        this.mapIncident.getIncident("inc.202002-009").subscribe((value: any) => {
            if (!JSON.parse(value).code) {
                this.incidentData = JSON.parse(value);
                this.initails(map);
            }
        }, err => this.util.log('err', err));
    }


    initails(map: Map) {
        this.map = map
        this.newsMarker();

        this.mapIncident.getPort().subscribe(val => {
            console.log('ALl Port SHOW: : ', val)
            this.ports = JSON.parse(val)

            let hits = this.ports.hits.hits

            this.allPort = [];
            for (let hit of hits) {
                if (hit._index == "portal-wpi") {
                    if (hit._source) {
                        //console.log('port cort', hit._source.title, JSON.stringify(hit._source.location))
                        let obj = {
                            value: hit._source.title,
                            location: hit._source.location,
                            status: true
                        }
                        this.allPort.push(obj);
                    }
                }
            }
            //console.log('this.allport', JSON.stringify(this.allPort))

            this.addPort()
        }, err => console.log(err))


        L.control.zoom({
            position: 'bottomleft'
        }).addTo(map);

        setInterval(() => {
            let bounds = {
                "_southWest": { "lat": -10.141931686131018, "lng": -132.36328125000003 },
                "_northEast": { "lat": 46.195042108660154, "lng": 107.75390625 }
            };
            let today = new Date();
            let yesterday = new Date();
            yesterday = new Date(yesterday.setDate(yesterday.getDate() - 2));
            this.mapIncident.getIncidentsDetailsData("", yesterday, today, "", this.filterList, [], 1).subscribe(value => {
                if (value) {
                    if (localStorage.getItem('latestNotification')) {
                        this.latestNotification = localStorage.getItem('latestNotification');
                    }
                    var hit = {}
                    hit = JSON.parse(value)
                    for (let data of hit['hits']['hits']) {
                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'incident') {
                            const obj = {}
                            let title = data['_source']['title']
                            if (title != this.latestNotification) {
                                this.latestNotification = title;
                                localStorage.setItem('latestNotification', this.latestNotification);
                                let dtg = data['_source']['dtg']
                                const timestamp = new Date(dtg).getTime();
                                const todate = new Date(timestamp).getDate();
                                const tomonth = new Date(timestamp).getMonth() + 1;
                                const toyear = new Date(timestamp).getFullYear();
                                const original_date = tomonth + '/' + todate + '/' + toyear;


                                const toast = this._service.create("Notification", title + ' - ' + original_date);
                                /*const toast = this._service.html('<div style="position:absolute!important;width:max-content; top:87px;background: #1bc21b;' +
                                    'padding: 14px 16px 0px 16px;' +
                                    'margin-left: -200px;' +
                                    'text-align: left;">' +
                                    '<p>'+title + ' - ' + dtg+'</p>' +'</div>');
*/

                                toast.click.subscribe((event) => {
                                    // alert("clicked");
                                    var markerBounds = L.latLngBounds([[data['_source']['lat'], data['_source']['lon']]]);
                                    this.zoomLevel = 6;
                                    for (let marker of this.markerList) {
                                        this.map.removeLayer(marker);
                                    }
                                    this.markerList = []
                                    this.onCord(this.map.getBounds())
                                    map.fitBounds(markerBounds);
                                    map.setZoom(6);
                                });
                            }
                            break;
                        }
                    }
                }
            });
        }, 30000);


        /********************************************************************************************/
        this.map.on(L.Draw.Event.CREATED, (e) => {
            let type = e.layerType,
                layer = e.layer;
            //console.log("hito CREATED", e);
            if (type === 'polygon') {
                // Do marker specific actions
                //console.log("hi to Created");
                this.poly = [];
                for (let l in layer._latlngs[0]) {
                    let n = { lat: layer._latlngs[0][l].lat, lon: layer._latlngs[0][l].lng };
                    this.poly.push(n);
                }
                if (this.drawClear) {
                    this.drawClear = false;
                    for (let marker of this.markerList) {
                        this.map.removeLayer(marker);
                    }
                }

                this.markerList = []
                this.onCord(this.map.getBounds())
                this.map.addLayer(layer);
            }

            // Do whatever else you need to. (save to db; add to map etc)
            this.map.removeLayer(layer);
        });


        this.map.on(L.Draw.Event.EDITED, (e) => {
            let type = e.layerType;
            let layers = e.layers._layers;
            const x = Object.keys(layers)
            const layer = e.layers._layers[x[0]];
            //console.log("hito EDITED", e);
            //console.log("hito EDITED", layer);

            // Do marker specific actions
            //console.log("hi to Created");
            this.poly = [];
            for (let l in layer._latlngs[0]) {
                let n = { lat: layer._latlngs[0][l].lat, lon: layer._latlngs[0][l].lng };
                this.poly.push(n);
            }

            for (let marker of this.markerList) {
                this.map.removeLayer(marker);
            }

            this.markerList = []
            this.onCord(this.map.getBounds())
            this.map.addLayer(layer);

            // Do whatever else you need to. (save to db; add to map etc)
            // this.map.removeLayer(layer);
        });

        this.map.on(L.Draw.Event.DELETED, (e) => {
            //console.log("hito DELETED", e);
            this.poly = [];
            this.drawClear = true;
            for (let marker of this.markerList) {
                this.map.removeLayer(marker);
            }
            this.markerList = []
            this.onCord(this.map.getBounds())
        });

        /********************************************************************************************/


        this.util.log('onMapReady: ', this.map)


        this.onCord(this.map.getBounds())


        //On Zoom in and out event get trigger
        /*
         L.marker([ 20.632784250388028, -12.304687500000002 ], {
         icon: icon({
         iconSize: [ 25, 41 ],
         iconAnchor: [ 13, 41 ],
         iconUrl: 'leaflet/marker-icon.png',
         shadowUrl: 'leaflet/marker-shadow.png'
         })
         }).on('click',()=>{

         this.ngZone.run(()=>{
         var tag = []

         const matDialogConfig = new MatDialogConfig();
         matDialogConfig.height = "80%";
         matDialogConfig.width = "70%";
         matDialogConfig.data = {
         canvas:this.mapCanvas,
         startTime:this.startTime,
         endTime:this.endTime,
         tag:tag.join(', '),
         chart1:this.chart1,
         chart2:this.chart2,
         incidentDetails:this.incidentData.hits.hits[0]._source, //Incident_Details._source,
         marker:this.popmarker,
         dataSource: new MatTableDataSource<PeriodicElement>(this.dataSourcePop)
         }
         const dialogRef = this.matDialog.open(PortGraphDialogModalComponent,matDialogConfig);

         dialogRef.afterClosed().subscribe(result => {
         this.util.log('Dialog result: ',result)
         });
         })


         }).addTo(this.map)*/


        var square = this.util.getBoundingCoords(16.966666666666665, 82.3, 2000)

        let n1 = { lat: square.left.latitude, lon: square.left.longitude };
        let n2 = { lat: square.top.latitude, lon: square.top.longitude };
        let n3 = { lat: square.right.latitude, lon: square.right.longitude };
        let n4 = { lat: square.bottom.latitude, lon: square.bottom.longitude };
        let poly = []
        poly.push(n1);
        poly.push(n2);
        poly.push(n3);
        poly.push(n4);

        //console.log('poly', poly)

        this.getPopMapMark(this.map.getBounds(), poly)

        this.getDetailsDataSourcePop(poly)
        map.on("zoom", event => {

            if (event.target._zoom <= 6) {
                // alert(event.target._zoom)
                // //console.log('Zoom Bounds: ',event)
                this.util.log('Zoom: ', {
                    _zoom: event.target._zoom,
                    _lastCenter: event.target._lastCenter
                })

                this.zoomLevel = event.target._zoom

                const zoom = event.target._zoom
                if (zoom == 3) {
                    this.zoom = 2
                } else {
                    this.zoom = event.target._zoom - 2
                }
                for (let marker of this.markerList) {
                    this.map.removeLayer(marker);
                }
                this.markerList = []
                this.onCord(this.map.getBounds())
            }

            this.addPort()
        })
        // map.on("moveend", event => {
        //
        //     if(event.target._zoom < 5){
        //         // alert(event.target._zoom)
        //         // //console.log('Zoom Bounds: ',event)
        //         this.util.log('Zoom: ',{
        //             _zoom: event.target._zoom,
        //             _lastCenter:event.target._lastCenter
        //         })
        //
        //         this.zoomLevel = event.target._zoom
        //
        //         const zoom = event.target._zoom
        //         if(zoom == 3){
        //             this.zoom = 2
        //         } else{
        //             this.zoom = event.target._zoom - 2
        //         }
        //         for(let marker of this.markerList){
        //             this.map.removeLayer(marker);
        //         }
        //         this.markerList=[]
        //         this.onCord(this.map.getBounds())
        //     }
        // })

        //when click on Map
        this.map.on("click", e => {
            this.ngZone.run(() => {
                this.util.log('clickOnMap: ', e.latlng)
                // this.onCountry()
            })
        });

        this.map.on('mousemove', event => {
            // this.util.log('hover',event.latlng)

            this.ngZone.run(() => {
                this.mLat = event.latlng.lat;
                this.mLng = event.latlng.lng;
            })
        })

        this.mapCountryAreas();
        this.mapCorridors();
        this.mapNigeriaTerminals();
        this.mapNigeriaTerminalsEZ();
        this.mapPatrolZones();
        this.mapIOFA();
        this.mapSTS();
        this.mapIndianOceanHRA();
        this.mapWestAficaHRA();
        this.mapIndianOceanJWC();
        this.mapGogJWC();
        this.mapAsia12();
        this.mapMiddleEast12();
        this.mapSouthAmerica12();
        this.mapAfrica12();
        this.mapAllWorldEEZ();
        this.mapAllWorld12EEZ();
        this.mapNigeriaEEZ();
        this.mapSoaTomeEEZ();

        setTimeout(() => {
            this.setEEZ(); 
            this.mapDryadAreas();
        }, 1000);
    }

    ngOnInit() {

        // if (!localStorage.getItem('currentUser')) {
        //     this.router.navigate(['/login/']);
        //     return;
        // }
    }

    getAllFilter() {

        this.filterList = []
        // let obj1 = new Filter()
        // obj1.match_phrase.type = 'port'
        // this.filterList.push(obj1)

        let obj2 = new Filter()
        obj2.match_phrase.type = 'incident'
        this.filterList.push(obj2)

        let obj3 = new Filter()
        obj3.match_phrase.type = 'migration'
        this.filterList.push(obj3)

        let obj4 = new Filter()
        obj4.match_phrase.type = 'country'
        this.filterList.push(obj4)

        let obj5 = new Filter()
        obj5.match_phrase.type = 'dryad'
        this.filterList.push(obj5)

    }

    onCord(bound, country = true) {
        this.incidentReportService.setStartTime(this.startTime)
        this.incidentReportService.setEndTime(this.endTime)

        this.util.log('onCord: ', {
            search: this.search,
            filterList: this.filterList
        })

        this.ngZone.run(() => {
            this.spinner.show();
        });

        if (this.filterList.length) {
            this.mapIncident.getIncidents(bound, this.startTime, this.endTime, this.search, this.filterList, this.poly, this.zoom, this.zoomLevel).subscribe(value => {
                // this.mapIncident.getIncidents(bound,this.startTime,this.endTime,this.search,this.filterList,this.poly,this.zoom,5).subscribe(value => {
                //     //console.log('Get Incidents in Map: ',JSON.parse(value))
                this.addMarkerToWorld(JSON.parse(value))

            }, error => this.util.error('Get Incidents in Map err: ', error))

        } else {
            //console.log('none selected in filter')

            this.ngZone.run(() => {
                this.spinner.hide();
            });
            // this.addMarkerToWorld(JSON.parse(value))
        }


        this.getDetailsDataSource()
        if (country) {
            this.getCountryList()
        }
    }

    //this.map.getBounds()
    getPopMapMarkPop(poly, id) {


        this.mapIncident.getIncidentsPop(this.map.getBounds(), this.startTime, this.endTime, this.search, this.filterList, poly, this.zoom).subscribe(value => {

            //console.log('Get Incidents in Map: ', JSON.parse(value))
            // this.addMarkerToWorld(JSON.parse(value))
            this.popmarker = value

            this.getPopIncidentdata(id)

        }, error => this.util.error('Get Incidents in Map err: ', error))
    }

    getPopMapMark(bound, poly) {


        this.mapIncident.getIncidentsPop(bound, this.startTime, this.endTime, this.search, this.filterList, poly, this.zoom).subscribe(value => {

            //console.log('Get Incidents in Map: ', JSON.parse(value))
            // this.addMarkerToWorld(JSON.parse(value))
            this.popmarker = value
        }, error => this.util.error('Get Incidents in Map err: ', error))
    }

    getPopData(lat, lon, id) {
        this.significantTag = {}
        this.dateSignificantTag = {}

        this.locationChart = {}


        var square = this.util.getBoundingCoords(lat, lon, 2000)

        let n1 = { lat: square.left.latitude, lon: square.left.longitude };
        let n2 = { lat: square.top.latitude, lon: square.top.longitude };
        let n3 = { lat: square.right.latitude, lon: square.right.longitude };
        let n4 = { lat: square.bottom.latitude, lon: square.bottom.longitude };
        let poly = []
        poly.push(n1);
        poly.push(n2);
        poly.push(n3);
        poly.push(n4);

        let types = {}
        this.dataSourcePop = []
        this.portList = []
        this.incidentList = []
        this.migrationList = []

        let portObject = {}

        //console.log('filter:', JSON.stringify(this.filterList))
        const sub = this.mapIncident.getIncidentsDetailsData(this.map.getBounds(), this.startTime, this.endTime, this.search, this.filterList, poly, this.zoom).subscribe(value => {
            //console.log('see more', value)
            if (value) {
                var hit = {}
                hit = JSON.parse(value)
                for (let data of hit['hits']['hits']) {


                    const x = data['_source']['type']
                    if (x.toLowerCase() == 'port') {
                        const obj = {}
                        obj['value'] = data['_source']['title']
                        obj['status'] = true
                        this.portList.push(obj)
                    }


                    if (x.toLowerCase() == 'migration') {
                        // if(data['_source']['title'])
                        this.migrationList.push(data['_source']['title'])
                    }

                    types[x] = true

                    let modal = new IncidentTableModal()
                    modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                    modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                    modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                    modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                    modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                    modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                    modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                    modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                    modal.published = data['_source']['published'] ? data['_source']['published'] : false
                    modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                    modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''


                    let date = 'unknown'

                    if (data['_source']['dtg_parts']) {
                        let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                        let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                        date = month + ' ' + year
                    }

                    let l = data['_source']['classification']['permissioning']
                    if (l) {
                        if (Array.isArray(l)) {

                            for (let a of l) {
                                this.locationChart[a] = this.locationChart[a] ? this.locationChart[a] : {};
                                this.locationChart[a][modal.significant_tag] = this.locationChart[a][modal.significant_tag] ? this.locationChart[a][modal.significant_tag] + 1 : 1;

                            }

                        } else {
                            this.locationChart[l] = this.locationChart[l] ? this.locationChart[l] : {};
                            this.locationChart[l][modal.significant_tag] = this.locationChart[l][modal.significant_tag] ? this.locationChart[l][modal.significant_tag] + 1 : 1;
                        }
                    } else {
                        this.locationChart['unknown'] = this.locationChart['unknown'] ? this.locationChart['unknown'] : {};
                        this.locationChart['unknown'][modal.significant_tag] = this.locationChart['unknown'][modal.significant_tag] ? this.locationChart['unknown'][modal.significant_tag] + 1 : 1;
                    }


                    this.dateSignificantTag[date] = this.dateSignificantTag[date] ? this.dateSignificantTag[date] : {}
                    this.dateSignificantTag[date][modal.significant_tag] = this.dateSignificantTag[date][modal.significant_tag] ? this.dateSignificantTag[date][modal.significant_tag] + 1 : 1;


                    this.dataSourcePop.push(modal)


                    this.significantTag[modal.significant_tag] = this.significantTag[modal.significant_tag] ? this.significantTag[modal.significant_tag] + 1 : 1;

                }

                // this.incidentReportService.setDataSource(this.dataSource)

                // this.util.log('mirgationlist : ',JSON.stringify(this.migrationList))
                //console.log('dataSourcePop: ', JSON.stringify(this.locationChart), JSON.stringify(this.dateSignificantTag))
                this.createGraphParamsPop(poly, id)
            }
            sub.unsubscribe()
        }, err => this.util.error('mirgation err: ', err))


    }

    getDetailsDataSourcePop(poly) {


        let types = {}
        this.dataSourcePop = []
        this.portList = []
        this.incidentList = []
        this.migrationList = []

        let portObject = {}
        const sub = this.mapIncident.getIncidentsDetailsData(this.map.getBounds(), this.startTime, this.endTime, this.search, this.filterList, poly, this.zoom).subscribe(value => {
            //console.log('see more', value)
            if (value) {
                var hit = {}
                hit = JSON.parse(value)
                for (let data of hit['hits']['hits']) {


                    const x = data['_source']['type']
                    if (x.toLowerCase() == 'port') {
                        const obj = {}
                        obj['value'] = data['_source']['title']
                        obj['status'] = true
                        this.portList.push(obj)
                    }


                    if (x.toLowerCase() == 'migration') {
                        // if(data['_source']['title'])
                        this.migrationList.push(data['_source']['title'])
                    }

                    types[x] = true

                    let modal = new IncidentTableModal()
                    modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                    modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                    modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                    modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                    modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                    modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                    modal.published = data['_source']['published'] ? data['_source']['published'] : false
                    modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                    modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''

                    let date = 'unknown'

                    if (data['_source']['dtg_parts']) {
                        let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                        let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                        date = month + ' ' + year
                    }

                    let l = data['_source']['classification']['permissioning']
                    if (l) {
                        if (Array.isArray(l)) {

                            for (let a of l) {
                                this.locationChart[a] = this.locationChart[a] ? this.locationChart[a] : {};
                                this.locationChart[a][modal.significant_tag] = this.locationChart[a][modal.significant_tag] ? this.locationChart[a][modal.significant_tag] + 1 : 1;

                            }

                        } else {
                            this.locationChart[l] = this.locationChart[l] ? this.locationChart[l] : {};
                            this.locationChart[l][modal.significant_tag] = this.locationChart[l][modal.significant_tag] ? this.locationChart[l][modal.significant_tag] + 1 : 1;
                        }
                    } else {
                        this.locationChart['unknown'] = this.locationChart['unknown'] ? this.locationChart['unknown'] : {};
                        this.locationChart['unknown'][modal.significant_tag] = this.locationChart['unknown'][modal.significant_tag] ? this.locationChart['unknown'][modal.significant_tag] + 1 : 1;
                    }


                    this.dateSignificantTag[date] = this.dateSignificantTag[date] ? this.dateSignificantTag[date] : {}
                    this.dateSignificantTag[date][modal.significant_tag] = this.dateSignificantTag[date][modal.significant_tag] ? this.dateSignificantTag[date][modal.significant_tag] + 1 : 1;


                    this.dataSourcePop.push(modal)


                    this.significantTag[modal.significant_tag] = this.significantTag[modal.significant_tag] ? this.significantTag[modal.significant_tag] + 1 : 1;

                }

                // this.incidentReportService.setDataSource(this.dataSource)

                // this.util.log('mirgationlist : ',JSON.stringify(this.migrationList))
                //console.log('dataSourcePop: ', JSON.stringify(this.locationChart), JSON.stringify(this.dateSignificantTag))
                this.createGraphParams()
            }
            sub.unsubscribe()
        }, err => this.util.error('mirgation err: ', err))
    }

    createGraphParamsPop(poly, id) {

        var label1 = this.convertToArray(this.dateSignificantTag);
        var label2 = this.convertToArray(this.significantTag);
        var label3 = this.convertToArray(this.locationChart);

        var barChartData1: ChartDataSets[] = [];
        var barChartData2: ChartDataSets[] = [];
        var barChartData3: ChartDataSets[] = [];

        for (let x of label2) {
            let data2 = [];
            for (let c of label2) {
                if (c == x) {
                    data2.push(this.significantTag[x])
                } else {
                    data2.push(0)
                }
            }

            barChartData2.push({
                data: data2,
                label: x,
                stack: 'a'
            })

            let data1 = []
            for (let y of label1) {
                data1.push(this.dateSignificantTag[y][x] ? this.dateSignificantTag[y][x] : 0);
            }
            barChartData1.push({
                data: data1,
                label: x,
                stack: 'a'
            })

            let data3 = []
            for (let y of label3) {
                data3.push(this.locationChart[y][x] ? this.locationChart[y][x] : 0);
            }
            barChartData3.push({
                data: data3,
                label: x,
                stack: 'a'
            })
        }


        this.chart1 = {
            label: label1.reverse(),
            data: barChartData1.reverse()
        };
        this.chart2 = {
            label: label3,
            data: barChartData3
        };
        //console.log('chart1', JSON.stringify(this.chart1));
        //console.log('chart2', JSON.stringify(this.chart2));

        this.getPopMapMarkPop(poly, id)


    }

    createGraphParams() {

        var label1 = this.convertToArray(this.dateSignificantTag);
        var label2 = this.convertToArray(this.significantTag);
        var label3 = this.convertToArray(this.locationChart);

        var barChartData1: ChartDataSets[] = [];
        var barChartData2: ChartDataSets[] = [];
        var barChartData3: ChartDataSets[] = [];

        for (let x of label2) {
            let data2 = [];
            for (let c of label2) {
                if (c == x) {
                    data2.push(this.significantTag[x])
                } else {
                    data2.push(0)
                }
            }

            barChartData2.push({
                data: data2,
                label: x,
                stack: 'a'
            })

            let data1 = []
            for (let y of label1) {
                data1.push(this.dateSignificantTag[y][x] ? this.dateSignificantTag[y][x] : 0);
            }
            barChartData1.push({
                data: data1,
                label: x,
                stack: 'a'
            })

            let data3 = []
            for (let y of label3) {
                data3.push(this.locationChart[y][x] ? this.locationChart[y][x] : 0);
            }
            barChartData3.push({
                data: data3,
                label: x,
                stack: 'a'
            })
        }


        this.chart1 = {
            label: label1,
            data: barChartData1
        };
        this.chart2 = {
            label: label3,
            data: barChartData3
        };
        //console.log('chart1', JSON.stringify(this.chart1));
        //console.log('chart2', JSON.stringify(this.chart2))
    }

    getDetailsDataSource() {

        let types = {}
        this.dataSource = []
        this.portList = []
        this.incidentList = []
        this.migrationList = []

        let portObject = {}
        const sub = this.mapIncident.getIncidentsDetailsData(this.map.getBounds(), this.startTime, this.endTime, this.search, this.filterList, this.poly, this.zoom).subscribe(value => {
            this.util.log('see more', value)
            if (value) {
                var hit = {}
                hit = JSON.parse(value)
                for (let data of hit['hits']['hits']) {


                    const x = data['_source']['type']
                    if (x.toLowerCase() == 'port') {
                        const obj = {}
                        obj['value'] = data['_source']['title']
                        obj['status'] = true
                        this.portList.push(obj)
                    }


                    if (x.toLowerCase() == 'migration') {
                        // if(data['_source']['title'])
                        this.migrationList.push(data['_source']['title'])
                    }

                    types[x] = true

                    let modal = new IncidentTableModal()
                    modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                    modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                    modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                    modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                    modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : ''
                    modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                    modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                    modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                    modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                    modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                    modal.published = data['_source']['published'] ? data['_source']['published'] : false
                    modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                    modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''
                    this.dataSource.push(modal)
                }

                this.incidentReportService.setDataSource(this.dataSource)

                this.util.log('mirgationlist : ', JSON.stringify(this.migrationList))
                this.util.log('dataSource: ', JSON.stringify(this.dataSource))
            }
            sub.unsubscribe()
        }, err => this.util.error('mirgation err: ', err))
    }

    getCountryList() {
        this.countryList = [];

        for (let data of this.countriesGeo.features) {
            let obj = {}
            obj['value'] = data.properties.name
            obj['status'] = true

            this.countryList.push(obj)
        }

        return;

        this.mapIncident.getCountry(this.startTime, this.endTime, this.search, this.filterList).subscribe(value => {
            this.util.log('get country in Map: ', JSON.parse(value))

            value = JSON.parse(value)//

            for (let data of value["aggregations"]["2"]["buckets"]) {
                let obj = {}
                obj['value'] = data['key']
                obj['status'] = true

                this.countryList.push(obj)
            }

        }, err => this.util.log('get country in Map err: ', err))
    }

    addPort() {

        for (let marker of this.portMarkerList) {
            this.map.removeLayer(marker);
        }
        this.portMarkerList = []
        if (this.isPort && this.zoomLevel >= 6) {
            let hits = this.ports.hits.hits
            //console.log('xports SerrrXXX:\n', JSON.stringify(hits))

            hits = hits.filter(value => {
                if (!this.search) {
                    return true
                } else {
                    if (value._index == "portal-wpi") {
                        if (value._source && value._source.location) {
                            return value._source.title == this.search
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }
            })

            //console.log('xports SerrrXXX:\n', JSON.stringify(hits))


            for (let hit of hits) {
                // if(hit._source.published) {
                if (hit._index == "portal-wpi" && hit._source.location) {
                    if (hit._source && hit._source.location[0] && hit._source.location[1]) {
                        //console.log('location latlng X:\n', JSON.stringify([hit._source.location[1], hit._source.location[0]]))
                        /*if (this.search){
                            this.map.flyTo([hit._source.location[1], hit._source.location[0]], 6)
                        }*/

                        let x = new L.marker([hit._source.location[1], hit._source.location[0]], {
                            icon: icon({
                                iconSize: [20, 25],
                                // iconAnchor: [0,0],
                                iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-000080-ffffff-P@2x.png'
                            })
                        }).bindPopup("<h4>Port Name: " + hit._source.title + "</h4>" +
                            "<p><b>latitude:  </b>" + this.util.getDMS(hit._source.location[0], 'lat') + "</p>" +
                            "<p><b>longitude: </b>" + this.util.getDMS(hit._source.location[1], 'long') + "</p>")
                            .on('mouseover', (d) => {
                                x.openPopup()
                            }).on('mouseout', (d) => {
                                x.closePopup()
                                //console.log('on mouseout')
                            }).on('click', () => {
                                this.ngZone.run(() => {
                                    this.onPort(hit._id, hit._source.name, hit._source.title)
                                })
                            }).addTo(this.map);
                        this.portMarkerList.push(x)
                    }
                }
                // }
            }
        }
    }

    addMarkerToWorld(dumpIncidentJson: {
        took: number; timed_out: boolean; _shards: { total: number; successful: number; skipped: number; failed: number };
        hits: { total: number; max_score: null; hits: any[] }; aggregations: { filter_agg: { "2": { buckets: ({ "3": { location: { lat: number; lon: number }; count: number }; key: string; doc_count: number })[] }; doc_count: number } }; status: number
    }) {




        // if(this.zoomLevel >= 5){
        if (this.zoomLevel >= 6) {
            var hits = dumpIncidentJson.hits.hits

            this.maxCount = 2
            for (let hit of hits) {

                if (hit._source.published) {
                    // //console.log('out')
                    if (hit._index == "portal-incidents") {
                        if (hit._source && hit._source.lat && hit._source.lon) {
                            // let circlecolor = '#68ff65';
                            let circlecolor = 'white';
                            let symbol = '';
                            switch (hit._source.significant_tag) {
                                case "Hijack":
                                    circlecolor = 'f8433f';
                                    symbol = 'H';
                                    break;
                                case "Kidnap":
                                    circlecolor = 'fd9b3e';
                                    symbol = 'K';
                                    break;
                                case "Kidnapping":
                                    circlecolor = 'fd9b3e';
                                    symbol = 'K';
                                    break;
                                case "Attack":
                                    circlecolor = 'c27ba0';
                                    symbol = 'A';
                                    break;
                                case "Fired Upon":
                                    circlecolor = '8e7cc3';
                                    symbol = 'F';
                                    break;
                                case "Boarding":
                                    circlecolor = '6d9eeb';
                                    symbol = 'B';
                                    break;
                                case "Robbery":
                                    circlecolor = 'b7b7b7';
                                    symbol = 'R';
                                    break;
                                case "Attempted":
                                    circlecolor = '93c47d';
                                    symbol = 'AT';
                                    break;
                                case "Approach":
                                    circlecolor = '2f6318';
                                    symbol = 'AP';
                                    break;
                                default:
                                    circlecolor = 'white';
                            }
                            // //console.log(JSON.stringify(hit._source))
                            let x = new L.marker([hit['_source']['lat'], hit['_source']['lon']], {
                                icon: icon({
                                    iconSize: [20, 25],
                                    // iconAnchor: [0, 0],
                                    iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-' + circlecolor + '-' + circlecolor + '-' + symbol + '@2x.png'
                                    // shadowUrl: 'leaflet/marker-shadow.png'
                                })
                            }).bindPopup("<h4> " + hit._source.title + "" + "</h4>" +
                                "<p><b>Latitude: </b>" + this.util.getDMS(hit._source.lat, 'lat') + "</p>" +
                                "<p><b>Longitude: </b>" + this.util.getDMS(hit._source.lon, 'long') + "</p>" +
                                "<p><b>Date : </b>" + hit._source.dtg_parts.month_desc + ', '
                                + hit._source.dtg_parts.day + ' '
                                + hit._source.dtg_parts.year + "</p>", {
                                bucket: hit
                            }).on('mouseover', (d) => {
                                x.openPopup()
                            }).on('mouseout', (d) => {
                                x.closePopup()
                                //console.log('on mouseout')
                            }).on('click', () => {

                                this.ngZone.run(() => {
                                    this.spinner.show();
                                    this.getPopData(hit._source.lat, hit._source.lon, hit._source.id)
                                })

                            }).addTo(this.map);
                            this.markerList.push(x)

                        }
                    }
                    if (hit._index == "portal-wpi") {
                        if (hit._source && hit._source.location[0] && hit._source.location[1]) {
                            // //console.log(JSON.stringify(hit._source))

                            // let x =   new L.circle([hit._source.location[1],hit._source.location[0]],{
                            //     radius:8500,
                            //     color:'orange',
                            // })
                            let x = new L.marker([hit._source.location[1], hit._source.location[0]], {
                                icon: icon({
                                    iconSize: [20, 25],
                                    // iconAnchor: [0, 0],
                                    iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-000080-ffffff-P@2x.png'
                                    // shadowUrl: 'leaflet/marker-shadow.png'
                                })
                            }).bindPopup("<p><b>Name:</b>" + hit._source.title + "</p>" +
                                "<p><b>latitude: </b>" + this.util.getDMS(hit._source.location[0], 'lat') + "</p>" +
                                "<p><b>longitude: </b>" + this.util.getDMS(hit._source.location[1], 'long') + "</p>", {
                                bucket: hit
                            }).on('mouseover', (d) => {
                                x.openPopup()
                            }).on('mouseout', (d) => {
                                x.closePopup()
                                //console.log('on mouseout')
                            }).on('click', () => {

                                //hit._source.name
                                this.ngZone.run(() => {
                                    // this.spinner.show();
                                    this.onPort(hit._id, hit._source.name, hit._source.title)
                                    // this.getPopData(hit._source.lat,hit._source.lon,hit._source.id)
                                })

                            }).addTo(this.map);
                            this.markerList.push(x)

                        }
                    }
                    if (hit._index == "portal-migration") {
                        if (hit._source && hit._source.location[0] && hit._source.location[1]) {
                            // //console.log(JSON.stringify(hit._source))
                            const nosOfDead = hit._source.migration['number_dead'] ? hit._source.migration['number_dead'] : 0;
                            const nosOfSurvivors = hit._source.migration['number_of_survivors'] ? hit._source.migration['number_of_survivors'] : 0;
                            let x = new L.circle([hit._source.location[1], hit._source.location[0]], {
                                radius: 8500,
                                color: 'pink',
                            }).bindPopup("<h6><b>" + hit._source.title + "</b></h6>" +
                                "<p><b>latitude: </b>" +
                                this.util.getDMS(hit._source.location[0], 'lat') +
                                "</p>" +

                                "<p><b>longitude: </b>" + this.util.getDMS(hit._source.location[1], 'long') +
                                "</p>" +
                                "<p><b>No.s of Dead : </b>" + nosOfDead + '</p>' +
                                "<p><b>No.s of Survivors : </b>" + nosOfSurvivors + '</p>'
                                , {
                                    bucket: hit
                                }).on('mouseover', (d) => {
                                    x.openPopup()
                                }).on('mouseout', (d) => {
                                    x.closePopup()
                                    //console.log('on mouseout')
                                }).on('click', () => {
                                    //console.log('on click', JSON.stringify(hit._source))
                                    //hit._source.name
                                    // this.ngZone.run(()=>{
                                    //     // this.spinner.show();
                                    //     this.onPort(hit._source.name)
                                    //     // this.getPopData(hit._source.lat,hit._source.lon,hit._source.id)
                                    // })

                                }).addTo(this.map);
                            this.markerList.push(x)

                        }
                    }
                }


            }

        } else {
            var buckets = dumpIncidentJson.aggregations.filter_agg["2"].buckets

            this.maxCount = 0;
            for (let bucket of buckets) {
                if (bucket["3"].count > this.maxCount) {
                    this.maxCount = bucket["3"].count;
                }
            }
            for (let bucket of buckets) {

                let x = new L.circle([bucket["3"].location.lat, bucket["3"].location.lon], {
                    radius: bucket["3"].count * 2500,
                    color: this.getColorMarkerForIncidentCount(bucket["3"].count, this.maxCount),
                }).bindPopup("<h4><b>count:<b> " + bucket["3"].count + "" +
                    "</h4></br><h4></h4><b>latitude: <b>" + this.util.getDMS(bucket["3"].location.lat, 'lat') + "</h4></br><h4></h4><b>longitude: <b>" + this.util.getDMS(bucket["3"].location.lon, 'long') + "</h4>", {
                    bucket: bucket
                }).on('mouseover', (d) => {
                    x.openPopup()
                    //console.log('on mouseover')
                }).on('mouseout', (d) => {
                    x.closePopup()
                    //console.log('on mouseout')
                }).on('click', () => {
                    /*
                                        this.ngZone.run(()=>{
                                            if(bucket["3"].count == 1) {
                                                var tag = []

                                                const dataSource4 = this.dataSourcePop.filter(val=>{
                                                    return val.published
                                                })

                                                const matDialogConfig = new MatDialogConfig();
                                                matDialogConfig.height = "70%";
                                                matDialogConfig.width = "65%";
                                                matDialogConfig.data = {
                                                    canvas: this.mapCanvas,
                                                    startTime: this.startTime,
                                                    endTime: this.endTime,
                                                    tag: tag.join(', '),
                                                    chart1: this.chart1,
                                                    chart2: this.chart2,
                                                    incidentDetails: this.incidentData.hits.hits[0]._source, //Incident_Details._source,
                                                    marker: this.popmarker,
                                                    dataSource: new MatTableDataSource<PeriodicElement>(dataSource4),
                                                    table:dataSource4
                                                    // dataSource: new MatTableDataSource<PeriodicElement>(this.dataSourcePop),
                                                    // table:this.dataSourcePop
                                                }
                                                const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

                                                dialogRef.afterClosed().subscribe(result => {
                                                    this.util.log('Dialog result: ', result)
                                                });
                                            }
                                        })*/


                }).addTo(this.map);
                this.markerList.push(x)

            }

        }
        this.ngZone.run(() => {
            this.spinner.hide();
        });


        /*
         html2canvas(document.getElementById('map1'), {
         allowTaint: true,
         useCORS: true,
         }).then((canvas) => {
         this.util.log('html2canvas: ',canvas.toDataURL())
         this.mapCanvas = canvas
         this.incidentReportService.setMapCanvas(canvas)
         }).catch(err=>{this.util.error('html2canvas err:',err)});
         */

    }

    getColorMarkerForIncidentCount(n, maxCount) {

        let color: string = '#68ff65';
        if (n < maxCount / 4) {
            color = '#68ff65'
        } else if (n < (maxCount / 4) * 2) {
            color = '#fff928'
        } else if (n < (maxCount / 4) * 3) {
            color = '#ff8a1f'
        } else {
            color = '#ff0721'
        }
        return color
    }

    onIsPort(event) {
        this.isPort = event.isPort
        this.addPort()
    }

    onApply(event) {
        this.startTime = event.startDate
        this.endTime = event.endDate
        for (let marker of this.markerList) {
            this.map.removeLayer(marker);
        }
        this.markerList = []
        this.util.log('onApply', this.map.getBounds())
        this.onCord(this.map.getBounds())
    }

    onSearch(event) {
        console.log('location latlng : ', JSON.stringify(event.searchObj))
        // console.log('onSearch: ', JSON.stringify(event.search))
        if (event.searchObj.length) {
            //console.log('onSearch', event.searchObj[0].location)
            this.map.flyTo([event.searchObj[0].location[1], event.searchObj[0].location[0]], 6)
        }
        for (let marker of this.markerList) {
            this.map.removeLayer(marker);
        }
        this.markerList = []
        this.filterList = event.filter
        this.search = event.search
        if (event.search && event.search !== "") {
            this.hubspotService.postLast5SearchQueries(event.search);
        }


        this.onCord(this.map.getBounds(), false)
        this.addPort()
    }

    convertToArray(obj) {
        var result = Object.keys(obj).map(function (key) {
            return key
        });
        return result
    }

    roundIt(n) {
        return Math.round(n);
    }

    getPopIncidentdata(id) {
        this.mapIncident.getIncident(id).subscribe(value => {
            this.incidentData = JSON.parse(value)
            //console.log('incidentData: ', this.incidentData, this.incidentData.hits.hits[0]._source)

            this.ngZone.run(() => {
                var tag = []
                this.spinner.hide();

                const dataSource5 = this.dataSourcePop.filter(val => {
                    return val.published
                })

                const matDialogConfig = new MatDialogConfig();
                matDialogConfig.height = "70%";
                matDialogConfig.width = "65%";
                matDialogConfig.data = {
                    canvas: this.mapCanvas,
                    startTime: this.startTime,
                    endTime: this.endTime,
                    tag: tag.join(', '),
                    chart1: this.chart1,
                    chart2: this.chart2,
                    incidentDetails: this.incidentData.hits.hits[0]._source, //Incident_Details._source,
                    marker: this.popmarker,
                    dataSource: new MatTableDataSource<PeriodicElement>(dataSource5),
                    table: dataSource5
                    // dataSource: new MatTableDataSource<PeriodicElement>(this.dataSourcePop),
                    // table:this.dataSourcePop
                }
                const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    this.util.log('Dialog result: ', result)
                });
            })


        }, err => this.util.log('err', err))
    }

    onSeeData() {


        const matDialogConfig = new MatDialogConfig()

        const dataSource = this.dataSource.filter(val => {
            return val.published
        })

        //console.log('dataSource: ', JSON.stringify(dataSource))
        matDialogConfig.height = "70%"
        matDialogConfig.width = "65%"
        matDialogConfig.data = {
            tableData: new MatTableDataSource<PeriodicElement>(dataSource),
            table: dataSource,
            bound: this.map.getBounds(),
            startTime: this.startTime,
            endTime: this.endTime
        }

        const dialogRef = this.matDialog.open(IncidentDialogModalComponent, matDialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            this.util.log('Dialog result: ', result)
        });
    }

    onCountry(countryCode, countryName) {
        this.spinner.show()
        this.mapIncident.getPopDetails(code.COUNTRY, false, 1, countryCode, this.startTime, this.endTime).subscribe(value => {
            var port = {}
            //console.warn('country', value);

            if (value == '') {
                const toast = this._service.error(countryName, noDataMsg, {
                    timeOut: 2000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
                this.spinner.hide()
                return;
            }
            port = JSON.parse(value)

            if (port['hits']["hits"].length == 0) {
                const toast = this._service.error(countryName, noDataMsg, {
                    timeOut: 2000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
                this.spinner.hide()
                return;
            }


            this.mapIncident.getPopDetails(code.COUNTRY, true, 1000, countryCode, this.startTime, this.endTime).subscribe(val => {


                let markers = []
                let types = {}
                let dataSourcePop = []

                let significantTag: any = {}
                let dateSignificantTag: any = {}

                let locationChart: any = {}

                let portList = []
                let incidentList = []
                let migrationList = []

                let portObject = {}

                if (val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts'] ? data['_source']['dtg_parts']['month_desc'] + ', ' + data['_source']['dtg_parts']['day'] + ' ' + data['_source']['dtg_parts']['year'] : ''
                        modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                        modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''
                        markers.push({
                            location: [modal.lat, modal.lon],
                            title: modal.title,
                            significant_tag: modal.significant_tag,
                            id: modal.id,
                            dtg_parts: modal.dtg_parts
                        })


                        // markers.push([modal.lat,modal.lon])

                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.convertToArray(dateSignificantTag);
                    var label2 = this.convertToArray(significantTag);
                    var label3 = this.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[] = [];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[] = [];

                    for (let c of label2) {
                    }


                    for (let x of label2) {
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1 = []
                        for (let y of label1) {
                            data1.push(dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0);
                        }
                        barChartData1.push({
                            data: data1,
                            label: x,
                            stack: 'a'
                        })

                        let data3 = []
                        for (let y of label3) {
                            data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                        }
                        barChartData3.push({
                            data: data3,
                            label: x,
                            stack: 'a'
                        })
                    }


                    let chart1 = {
                        label: label1.reverse(),
                        data: barChartData1.reverse()
                    };
                    let chart2 = {
                        label: label2,
                        data: barChartData2
                    };
                    let chart3 = {
                        label: label3,
                        data: barChartData3
                    };

                    console.log("Chartjs: ", JSON.stringify({  chart1: chart1,
                        chart2: chart2,
                        chart3: chart3
                      }))

                    const dataSource1 = dataSourcePop.filter(val => {
                        return val.published
                    })

                    if (port['hits']["hits"].length) {
                        const matDialogConfig = new MatDialogConfig()
                        matDialogConfig.height = "70%";
                        matDialogConfig.width = "65%";
                        matDialogConfig.data = {
                            mLat: this.mLat,
                            mLng: this.mLng,
                            markers: markers,
                            startTime: this.startTime,
                            endTime: this.endTime,
                            dataSource: new MatTableDataSource<PeriodicElement>(dataSource1),
                            table: dataSource1,
                            chart1: chart1,
                            chart2: chart2,
                            chart3: chart3,
                            portResponse: port['hits']["hits"][0]['_source']
                        }
                        this.spinner.hide()
                        const dialogRef = this.matDialog.open(CountryPopupComponent, matDialogConfig);
                        dialogRef.afterClosed().subscribe(result => {
                            this.util.log('Dialog result: ', result)
                        });
                    } else {
                        this.spinner.hide()
                    }
                }

            }, err2 => {
                this.spinner.hide()
                this.util.error('err', err2)
            })

        }, err => {
            this.spinner.hide()
            this.util.error('err: ', err)
        })
    }

    onMaritine(dryadCode) {
        // "GoG - East"


        let markers: any = []
        this.spinner.show()
        //getMaritineDetails
        this.mapIncident.getPopDetails(code.DRYAD, false, 1, dryadCode, this.startTime, this.endTime).subscribe(value => {
            var port = {}
            port = JSON.parse(value)
            if (port['hits']["hits"].length == 0) {
                const toast = this._service.error(dryadCode, noDataMsg, {
                    timeOut: 2000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });

                this.spinner.hide()
                return;
            }
            //getMaritineChartDetails
            this.mapIncident.getPopDetails(code.DRYAD, true, 1000, dryadCode, this.startTime, this.endTime).subscribe(val => {


                let types = {}
                let dataSourcePop = []

                let significantTag: any = {}
                let dateSignificantTag: any = {}

                let locationChart: any = {}

                let portList = []
                let incidentList = []
                let migrationList = []

                let portObject = {}

                if (val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts'] ? data['_source']['dtg_parts']['month_desc'] + ', ' + data['_source']['dtg_parts']['day'] + ' ' + data['_source']['dtg_parts']['year'] : ''
                        modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                        modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''

                        markers.push({
                            location: [modal.lat, modal.lon],
                            title: modal.title,
                            significant_tag: modal.significant_tag,
                            id: modal.id,
                            dtg_parts: modal.dtg_parts
                        })


                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.convertToArray(dateSignificantTag);
                    var label2 = this.convertToArray(significantTag);
                    var label3 = this.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[] = [];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[] = [];


                    for (let x of label2) {
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1 = []
                        for (let y of label1) {
                            data1.push(dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0);
                        }
                        barChartData1.push({
                            data: data1,
                            label: x,
                            stack: 'a'
                        })

                        let data3 = []
                        for (let y of label3) {
                            data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                        }
                        barChartData3.push({
                            data: data3,
                            label: x,
                            stack: 'a'
                        })
                    }


                    let chart1 = {
                        label: label1,
                        data: barChartData1
                    };
                    let chart2 = {
                        label: label2,
                        data: barChartData2
                    };
                    let chart3 = {
                        label: label3,
                        data: barChartData3
                    };


                    /*********************************************************/
                    const matDialogConfig = new MatDialogConfig()
                    matDialogConfig.height = "70%";
                    matDialogConfig.width = "65%";

                    const dataSource2 = dataSourcePop.filter(val => {
                        return val.published
                    })


                    matDialogConfig.data = {
                        mLat: this.mLat,
                        mLng: this.mLng,
                        markers: markers,
                        startTime: this.startTime,
                        endTime: this.endTime,
                        dataSource: new MatTableDataSource<PeriodicElement>(dataSource2),
                        table: dataSource2,
                        chart1: chart1,
                        chart2: chart2,
                        chart3: chart3,
                        portResponse: port['hits']["hits"][0]['_source']
                    }
                    this.spinner.hide()
                    const dialogRef = this.matDialog.open(MigrationPopupComponent, matDialogConfig);
                    dialogRef.afterClosed().subscribe(result => {
                        this.util.log('Dialog result: ', result)
                    });

                    /************************************************************/
                }

            }, err2 => this.util.error('err', err2))

        }, err => this.util.error('err: ', err))
    }

    onPort(searchBy, searchByName, searchByTitle) {
        let markers: any = []
        this.spinner.show()
        this.mapIncident.getPopDetails(code.PORTAL_RISK, false, 1, searchByName, this.startTime, this.endTime, searchByTitle).subscribe(value => {
            var port = {}
            port = JSON.parse(value)
            if (port['hits']["hits"].length == 0 || isUndefined(port['hits']["hits"][0]['_source']['risk'])) {
                // const toast = this._service.error(searchByName,noDataMsg, {
                const toast = this._service.error(searchByName, '<p>no data found - check your subscription settings</p><a href="https://dg.dryadglobal.com/port-request" target="_blank">Port Request</a>', {
                    timeOut: 2000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
                this.spinner.hide()
                return;
            }

            this.mapIncident.getPopDetails(code.PORTAL_RISK, true, 1000, searchByName, this.startTime, this.endTime).subscribe(val => {


                let types = {}
                let dataSourcePop = []

                let significantTag: any = {}
                let dateSignificantTag: any = {}

                let locationChart: any = {}

                let portList = []
                let incidentList = []
                let migrationList = []

                let portObject = {}

                if (val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts'] ? data['_source']['dtg_parts']['month_desc'] + ', ' + data['_source']['dtg_parts']['day'] + ' ' + data['_source']['dtg_parts']['year'] : ''
                        modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                        modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''

                        // markers.push([modal.lat,modal.lon])


                        markers.push({
                            location: [modal.lat, modal.lon],
                            title: modal.title,
                            significant_tag: modal.significant_tag,
                            id: modal.id,
                            dtg_parts: modal.dtg_parts,
                            d: data['_source']['dtg_parts']
                        })

                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.convertToArray(dateSignificantTag);
                    var label2 = this.convertToArray(significantTag);
                    var label3 = this.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[] = [];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[] = [];


                    for (let x of label2) {
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1 = []
                        for (let y of label1) {
                            data1.push(dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0);
                        }
                        barChartData1.push({
                            data: data1,
                            label: x,
                            stack: 'a'
                        })

                        let data3 = []
                        for (let y of label3) {
                            data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                        }
                        barChartData3.push({
                            data: data3,
                            label: x,
                            stack: 'a'
                        })
                    }


                    let chart1 = {
                        label: label1,
                        data: barChartData1
                    };
                    let chart2 = {
                        label: label2,
                        data: barChartData2
                    };
                    let chart3 = {
                        label: label3,
                        data: barChartData3
                    };


                    /*********************************************************/
                    const dataSource3 = dataSourcePop.filter(val => {
                        return val.published
                    })

                    const matDialogConfig = new MatDialogConfig()
                    matDialogConfig.height = "70%";
                    matDialogConfig.width = "65%";
                    matDialogConfig.data = {
                        mLat: this.mLat,
                        mLng: this.mLng,
                        markers: markers,
                        startTime: this.startTime,
                        endTime: this.endTime,
                        dataSource: new MatTableDataSource<PeriodicElement>(dataSource3),
                        table: dataSource3,
                        chart1: chart1,
                        chart2: chart2,
                        chart3: chart3,
                        portResponse: port['hits']["hits"][0]['_source']
                    }
                    this.spinner.hide()
                    const dialogRef = this.matDialog.open(PortPopupComponent, matDialogConfig);
                    dialogRef.afterClosed().subscribe(result => {
                        this.util.log('Dialog result: ', result)
                    });

                    /************************************************************/
                }

            }, err2 => this.util.error('err', err2))

        }, err => this.util.error('err: ', err))
    }

    mapCountryAreas() {
        this.countryAreas = L.geoJSON(this.countriesGeo, {
            color: '#ffffff00', onEachFeature: (feature, layer) => {
                layer.on({
                    mouseover: function over(e) {
                        (e.target).setStyle({
                            color: 'yellow',
                            weight: 2,
                            opacity: 0.5
                        })
                        // layer.bindPopup(L.popup().setContent(layer.feature.properties.name))
                        // layer.openPopup()
                    },
                    mouseout: function out(e) {
                        (e.target).setStyle({
                            color: 'transparent',
                            weight: 0,
                            opacity: 0
                        });
                        // layer.closePopup()
                    }
                })
            }
        }).bindPopup(function (layer) {
            return layer.feature.properties.name;
        }).on('click', (val) => {
            this.ngZone.run(() => {
                //console.log('val : ', val.layer.feature.properties['name'], val.layer.feature.id, val.layer.feature)
                let code = countryCode[val.layer.feature.id]
                //console.log('code: ', countryCode[val.layer.feature.id])
                //val.layer.feature.id
                // alert(code);
                this.onCountry(code, val.layer.feature.properties['name'])

            })
        }).addTo(this.map);
    }

    mapDryadAreas() {
        this.dryadPolygons = L.geoJSON(this.dryadAreas, {
            color: 'grey', weight: 1, opacity: 1, onEachFeature: (feature, layer) => {
                layer.on({
                    mouseover: function over(e) {
                        (e.target).setStyle({
                            color: 'yellow',
                            weight: 2,
                            opacity: 0.5
                        })
                        // layer.bindPopup(L.popup().setContent(layer.feature.properties.name))
                        // layer.openPopup()
                    },
                    mouseout: function out(e) {
                        (e.target).setStyle({
                            color: 'grey',
                            weight: 1,
                            opacity: 1
                        });
                        // layer.closePopup()
                    }
                })
            }
        }).bindPopup(function (layer) {
            return layer.feature.properties.Title_1;
        }).on('click', (val) => {
            this.ngZone.run(() => {

                // //console.log('val : ',val.layer.feature.properties['Title_1'])
                let x = "GoG - East"
                if (val.layer.feature.properties['title']) {
                    x = val.layer.feature.properties['title']
                }
                this.onMaritine(x)
            })
        }).addTo(this.map);
    }

    getReturn(layer) {
        return layer.feature.properties.Title_1;
    }

    mapCorridors() {
        this.corridors = L.geoJSON(this.transitCorridors, { color: 'grey' })
            .bindPopup(function (layer) {
                return layer.feature.properties.title;
            })
        // .addTo(this.map);
    }

    mapNigeriaTerminals() {
        this.nigeriaterminals = L.geoJSON(this.nigeriaterminalsJson, {
            pointToLayer: function (feature, latlng) {
                return L.marker(latlng, {
                    icon: icon({
                        iconSize: [20, 25],
                        // iconAnchor: [0, 0],
                        iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-000080-ffffff-T@2x.png'
                        // shadowUrl: 'leaflet/marker-shadow.png'
                    })
                });
            }
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapNigeriaTerminalsEZ() {
        this.nigeriaterminalsez = L.geoJSON(this.nigeriaterminalsJson, {
            pointToLayer: function (feature, latlng) {
                return L.circle(latlng, { radius: 9260, color: "red", weight: 1 });
            }
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapPatrolZones() {
        this.patrolZones = L.geoJSON(this.partolJson, { color: 'maroon' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapIOFA() {
        this.iofa = L.geoJSON(this.iofaJson, {
            color: 'maroon',
            pointToLayer: function (feature, latlng) {
                return L.marker(latlng, {
                    icon: icon({
                        iconSize: [20, 25],
                        // iconAnchor: [0,0],
                        iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-ff7800-ff7800-V@2x.png'
                        // iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-000080-ffffff-V@2x.png'
                    })
                });
                /*return L.circleMarker(latlng, {
                    radius : 8,
                    fillColor : "#ff7800",
                    color : "#000",
                    weight : 1,
                    opacity : 1,
                    fillOpacity : 0.8
                });*/
            }
        }).bindPopup(function (layer) {
            return "<h4>Vessel Name: " + layer.feature.properties.Vessel + "</h4>" +
                "<p><b>latitude:  </b>" + layer.feature.properties.Lat + "</p>" +
                "<p><b>longitude: </b>" + layer.feature.properties.Long + "</p>" +
                "<p><b>Operator: </b>" + layer.feature.properties.Operator + "</p>" +
                "<p><b>Location: </b>" + layer.feature.properties.Location + "</p>"
                ;
        })
        // .addTo(this.map);
    }

    mapSTS() {
        this.sts1 = L.geoJSON(this.sts1Json, { color: 'orange' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
        this.sts2 = L.geoJSON(this.sts2Json, { color: 'orange' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
        this.sts3 = L.geoJSON(this.sts3Json, { color: 'orange' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
        this.sts4 = L.geoJSON(this.sts4Json, { color: 'orange' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
        this.sts5 = L.geoJSON(this.sts5Json, { color: 'orange' })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapIndianOceanHRA() {
        this.indianOceanHRA = L.geoJSON(this.indianOceanHRAJson, { color: 'lightpink', weight: 2, opacity: 1 })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapWestAficaHRA() {
        this.westAfricaHRA = L.geoJSON(this.westAfricaHRAJson, { color: 'lightpink', weight: 2, opacity: 1 })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapIndianOceanJWC() {
        this.indianOceanJWC = L.geoJSON(this.indianOceanJWCJSON, { color: 'lightpink', weight: 2, opacity: 1 })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapGogJWC() {
        this.gogJWC = L.geoJSON(this.gogJWCJSON, {
            fillColor: 'lightpurple',
            weight: 1,
            opacity: 1,
            color: 'red',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapAsia12() {
        this.asia12 = L.geoJSON(this.asia12JSON, {
            fillColor: 'lightpurple',
            weight: 1,
            opacity: 1,
            color: 'red',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapMiddleEast12() {
        this.middleeast12 = L.geoJSON(this.middleeast12JSON, {
            fillColor: 'lightpurple',
            weight: 1,
            opacity: 1,
            color: 'red',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapSouthAmerica12() {
        this.southamerica12 = L.geoJSON(this.southamericaJSON, {
            fillColor: 'lightpurple',
            weight: 1,
            opacity: 1,
            color: 'red',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapAfrica12() {
        this.africa12 = L.geoJSON(this.africa12JSON, {
            fillColor: 'lightpurple',
            weight: 1,
            opacity: 1,
            color: 'red',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapAllWorldEEZ() {
        this.allworldeez = L.geoJSON(this.allworldeezJSON, {
            fillColor: 'blue',
            weight: 1.5,
            opacity: 1,
            color: 'blue',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
    }

    mapAllWorld12EEZ() {
        this.allworld12eez = L.geoJSON(this.allworld12eezJSON, {
            fillColor: 'blue',
            weight: 1.5,
            opacity: 1,
            color: 'blue',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
    }

    mapNigeriaEEZ() {
        this.nigeriaeez = L.geoJSON(this.nigeriaeezJSON, {
            fillColor: 'blue',
            weight: 1.5,
            opacity: 1,
            color: 'blue',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    mapSoaTomeEEZ() {
        this.soatomeeez = L.geoJSON(this.soatomeeezJSON, {
            fillColor: 'blue',
            weight: 1.5,
            opacity: 1,
            color: 'blue',
            fillOpacity: 0.2
        })
            .bindPopup(function (layer) {
                return layer.feature.properties.Name;
            })
        // .addTo(this.map);
    }

    onChangeMapMode() {
        this.isOceanMap = !this.isOceanMap
        // if(!this.isOceanMap){
        //     this.map.removeLayer(this.streetMaps);
        //     this.map.addLayer(this.darkMap);
        // }else{
        //     this.map.removeLayer(this.darkMap);
        //     this.map.addLayer(this.streetMaps);
        // }
        if (this.whichMap >= 3) {
            this.whichMap = 0
        }

        switch (this.whichMap) {
            case 0:
                this.map.removeLayer(this.Esri_WorldImagery);
                this.map.addLayer(this.darkMap);
                break;
            case 1:
                this.map.removeLayer(this.darkMap);
                this.map.addLayer(this.streetMaps);
                break;
            case 2:
                this.map.removeLayer(this.streetMaps);
                this.map.addLayer(this.Esri_WorldImagery);
                break;
        }
        this.whichMap++;
    }

    addRemoveSeaMarks(event) {
        if (event.isSeaMarks) {
            this.map.addLayer(this.seaMarks);
        } else {
            this.map.removeLayer(this.seaMarks);
        }
    }

    addRemoveDryad(event) {
        if (event.isDryadPolygons) {
            this.map.addLayer(this.dryadPolygons);
        } else {
            this.map.removeLayer(this.dryadPolygons);
        }
    }

    addRemoveCorridors(event) {
        if (event.isCorridors) {
            this.map.addLayer(this.corridors);
        } else {
            this.map.removeLayer(this.corridors);
        }
    }

    addRemoveTerminals(event) {
        if (event.isTerminals) {
            this.map.addLayer(this.nigeriaterminals);
            this.map.addLayer(this.nigeriaterminalsez);
        } else {
            this.map.removeLayer(this.nigeriaterminals);
            this.map.removeLayer(this.nigeriaterminalsez);
        }
    }

    addRemoveNigeriaTerminals(event) {
        if (event.isNigeriaTerminals) {
            this.map.addLayer(this.nigeriaterminals);
        } else {
            this.map.removeLayer(this.nigeriaterminals);
        }
    }

    addRemoveNigeriaTerminalsEZ(event) {
        if (event.isNigeriaTerminalsEZ) {
            this.map.addLayer(this.nigeriaterminalsez);
        } else {
            this.map.removeLayer(this.nigeriaterminalsez);
        }
    }

    addRemovePatrolZones(event) {
        if (event.isPatrolZones) {
            this.map.addLayer(this.patrolZones);
        } else {
            this.map.removeLayer(this.patrolZones);
        }
    }

    addRemoveIOFA(event) {
        if (event.isIOFA) {
            this.map.addLayer(this.iofa);
        } else {
            this.map.removeLayer(this.iofa);
        }
    }

    addRemoveSTS(event) {
        if (event.isSTS) {
            this.map.addLayer(this.sts1);
            this.map.addLayer(this.sts2);
            this.map.addLayer(this.sts3);
            this.map.addLayer(this.sts4);
            this.map.addLayer(this.sts5);
        } else {
            this.map.removeLayer(this.sts1);
            this.map.removeLayer(this.sts2);
            this.map.removeLayer(this.sts3);
            this.map.removeLayer(this.sts4);
            this.map.removeLayer(this.sts5);
        }
    }

    addRemoveHRA(event) {
        if (event.isHRA) {
            this.map.addLayer(this.indianOceanHRA);
            this.map.addLayer(this.westAfricaHRA);
            this.map.addLayer(this.corridors);
        } else {
            this.map.removeLayer(this.indianOceanHRA);
            this.map.removeLayer(this.westAfricaHRA);
            this.map.removeLayer(this.corridors);
        }
    }

    addRemoveIndianOceanHRA(event) {
        if (event.isIndianOceanHRA) {
            this.map.addLayer(this.indianOceanHRA);
        } else {
            this.map.removeLayer(this.indianOceanHRA);
        }
    }

    addRemoveWestAfricaHRA(event) {
        if (event.isWestAfricaHRA) {
            this.map.addLayer(this.westAfricaHRA);
        } else {
            this.map.removeLayer(this.westAfricaHRA);
        }
    }

    addRemoveIndianOceanJWC(event) {
        if (event.isIndianOceanJWC) {
            this.map.addLayer(this.indianOceanJWC);
        } else {
            this.map.removeLayer(this.indianOceanJWC);
        }
    }

    addRemoveGogJWC(event) {
        if (event.isGogJWC) {
            this.map.addLayer(this.gogJWC);
        } else {
            this.map.removeLayer(this.gogJWC);
        }
    }

    addRemoveAsia12(event) {
        if (event.isAsia12) {
            this.map.addLayer(this.asia12);
        } else {
            this.map.removeLayer(this.asia12);
        }
    }

    addRemoveMiddleEast12(event) {
        if (event.isMiddleEast12) {
            this.map.addLayer(this.middleeast12);
        } else {
            this.map.removeLayer(this.middleeast12);
        }
    }

    addRemoveAfrica12(event) {
        if (event.isAfrica12) {
            this.map.addLayer(this.southamerica12);
        } else {
            this.map.removeLayer(this.southamerica12);
        }
    }

    addRemoveSouthAmerica12(event) {
        if (event.isSouthAmerica12) {
            this.map.addLayer(this.africa12);
        } else {
            this.map.removeLayer(this.africa12);
        }
    }

    setEEZ() {
        this.map.addLayer(this.allworldeez);
        this.map.addLayer(this.allworld12eez);
        this.map.addLayer(this.nigeriaeez);
        this.map.addLayer(this.soatomeeez);
    }

    addRemoveEEZ(event) {
        if (event.isEEZ) {
            this.map.addLayer(this.allworldeez);
            this.map.addLayer(this.allworld12eez);
            this.map.addLayer(this.nigeriaeez);
            this.map.addLayer(this.soatomeeez);
        } else {
            this.map.removeLayer(this.allworldeez);
            this.map.removeLayer(this.allworld12eez);
            this.map.removeLayer(this.nigeriaeez);
            this.map.removeLayer(this.soatomeeez);
        }
    }

    addRemoveAllWorldEEZ(event) {
        if (event.isAllWordEEZ) {
            this.map.addLayer(this.allworldeez);
        } else {
            this.map.removeLayer(this.allworldeez);
        }
    }

    addRemoveAllWorld12EEZ(event) {
        if (event.isAllWord12EEZ) {
            this.map.addLayer(this.allworld12eez);
        } else {
            this.map.removeLayer(this.allworld12eez);
        }
    }

    addRemoveNigeriaEEZ(event) {
        if (event.isNigeriaEEZ) {
            this.map.addLayer(this.nigeriaeez);
        } else {
            this.map.removeLayer(this.nigeriaeez);
        }
    }

    addRemoveSoaTomeEEZ(event) {
        if (event.isSoaTomeEEZ) {
            this.map.addLayer(this.soatomeeez);
        } else {
            this.map.removeLayer(this.soatomeeez);
        }
    }

    createdNotification(event) {

    }

    destroyedNotification(event) {

    }

    onSetLocation(event) {
        this.onSetLocationClear();
        //console.log('typeof', typeof event)
        if (typeof event !== 'undefined' && typeof event.latitude == 'number' && typeof event.longitude == 'number') {
            this.map.flyTo([event.latitude, event.longitude], 6)
            let x = new L.marker([event.latitude, event.longitude], {
                icon: icon({
                    iconSize: [65, 65],
                    // iconUrl: 'https://cdn0.iconfinder.com/data/icons/location-and-maps/24/crosshair-simple-512.png'
                    iconUrl: 'assets/icon/red-cursor-dryad.png'
                    // iconUrl: 'https://static.thenounproject.com/png/67373-200.png'
                })
            }).addTo(this.map);
            this.crossHair.push(x)
        }
    }

    onSetLocationClear() {
        for (let x of this.crossHair) {
            this.map.removeLayer(x);
        }
    }

    //TODO WORLD NEWS...

    onNews(event) {
        if (event.isNews) {
            this.newsMarker();
        } else {
            this.removeNewsMarker();
        }
    }

    newsMarker() {
        this.removeNewsMarker();
        const regions = {
            "regions": [
                { "world": "Africa", "region": "Al Shabab", "resId": 67 },
                { "world": "Africa", "region": "Cameroon", "resId": 180 },
                { "world": "Africa", "region": "Central African Republic", "resId": 171 },
                { "world": "Africa", "region": "DR Congo", "resId": 152 },
                { "world": "Africa", "region": "Kenya", "resId": 135 },
                { "world": "Africa", "region": "Nigeria", "resId": 158 },
                { "world": "Africa", "region": "Sahel", "resId": 166 },
                { "world": "Africa", "region": "Somalia", "resId": 138 },
                { "world": "Africa", "region": "South Africa", "resId": 155 },
                { "world": "Africa", "region": "Sudan", "resId": 147 },
                { "world": "Africa", "region": "Tanzania", "resId": 157 },
                { "world": "Africa", "region": "Uganda", "resId": 144 },
                { "world": "Africa", "region": "Zimbabwe", "resId": 174 },

                { "world": "America", "region": "Brazil", "resId": 64 },
                { "world": "America", "region": "Canada", "resId": 170 },
                { "world": "America", "region": "Caribbean", "resId": 154 },
                { "world": "America", "region": "Colombia", "resId": 63 },
                { "world": "America", "region": "Guyana", "resId": 140 },
                { "world": "America", "region": "Latin America", "resId": 169 },
                { "world": "America", "region": "Mexico", "resId": 133 },
                { "world": "America", "region": "Nicaragua", "resId": 167 },
                { "world": "America", "region": "Venezuela", "resId": 70 },
                { "world": "America", "region": "Bangladesh", "resId": 153 },

                { "world": "Asia", "region": "Central Asia", "resId": 57 },
                { "world": "Asia", "region": "China", "resId": 24 },
                { "world": "Asia", "region": "Hong Kong", "resId": 12 },
                { "world": "Asia", "region": "India", "resId": 154 },
                { "world": "Asia", "region": "Japan", "resId": 149 },
                { "world": "Asia", "region": "Kashmir", "resId": 55 },
                { "world": "Asia", "region": "Koreas", "resId": 9 },
                { "world": "Asia", "region": "Maldives", "resId": 173 },
                { "world": "Asia", "region": "Myanmar", "resId": 148 },
                { "world": "Asia", "region": "Sri Lanka", "resId": 172 },
                { "world": "Asia", "region": "Taiwan", "resId": 142 },
                { "world": "Asia", "region": "Thailand", "resId": 151 },
                { "world": "Asia", "region": "Vietnam", "resId": 150 },

                { "world": "Europe", "region": "Balkans", "resId": 29 },
                { "world": "Europe", "region": "Baltics", "resId": 52 },
                { "world": "Europe", "region": "Belarus", "resId": 31 },
                { "world": "Europe", "region": "Caucasus", "resId": 4 },
                { "world": "Europe", "region": "Central and Eastern Europe", "resId": 129 },
                { "world": "Europe", "region": "France", "resId": 162 },
                { "world": "Europe", "region": "Germany", "resId": 161 },
                { "world": "Europe", "region": "Hungary", "resId": 20 },
                { "world": "Europe", "region": "Ireland", "resId": 139 },
                { "world": "Europe", "region": "Italy", "resId": 163 },
                { "world": "Europe", "region": "Minsk Monitor", "resId": 62 },
                { "world": "Europe", "region": "Moldova", "resId": 137 },
                { "world": "Europe", "region": "North Europe", "resId": 160 },
                { "world": "Europe", "region": "Poland", "resId": 30 },
                { "world": "Europe", "region": "Russia", "resId": 18 },
                { "world": "Europe", "region": "Spain", "resId": 146 },
                { "world": "Europe", "region": "UK", "resId": 141 },
                { "world": "Europe", "region": "Ukraine", "resId": 0 },
                { "world": "Europe", "region": "Visegrad", "resId": 127 },
                { "world": "Latam", "region": "Honduras", "resId": 178 },



                { "world": "Middle East", "region": "Afghanistan", "resId": 56 },
                { "world": "Middle East", "region": "Algeria", "resId": 176 },
                { "world": "Middle East", "region": "Egypt", "resId": 16 },
                { "world": "Middle East", "region": "Hezbollah", "resId": 71 },
                { "world": "Middle East", "region": "Iran", "resId": 66 },
                { "world": "Middle East", "region": "Iraq", "resId": 65 },
                { "world": "Middle East", "region": "ISIS", "resId": 5 },
                { "world": "Middle East", "region": "Israel-Palestine", "resId": 2 },
                { "world": "Middle East", "region": "Kurds", "resId": 50 },
                { "world": "Middle East", "region": "Lebanon", "resId": 74 },
                { "world": "Middle East", "region": "Libya", "resId": 54 },
                { "world": "Middle East", "region": "Pakistan", "resId": 69 },
                { "world": "Middle East", "region": "Qatar", "resId": 73 },
                { "world": "Middle East", "region": "Saudi Arabia", "resId": 73 },
                { "world": "Middle East", "region": "Syria", "resId": 3 },
                { "world": "Middle East", "region": "Tunisia", "resId": 175 },
                { "world": "Middle East", "region": "Turkey", "resId": 19 },
                { "world": "Middle East", "region": "Yemen", "resId": 53 },

                { "world": "Money", "region": "Corruption", "resId": 136 },
                { "world": "Money", "region": "Energy", "resId": 143 },
                { "world": "Oceania", "region": "Indonesia", "resId": 156 },
                { "world": "Pacific", "region": "Philippines", "resId": 72 },
                { "world": "Texas", "region": "Houston", "resId": 168 },

                { "world": "USA", "region": "Alabama", "resId": 97 },
                { "world": "USA", "region": "Alaska", "resId": 122 },
                { "world": "USA", "region": "Arizona", "resId": 89 },
                { "world": "USA", "region": "Arkansas", "resId": 105 },
                { "world": "USA", "region": "California", "resId": 75 },
                { "world": "USA", "region": "Colorado", "resId": 96 },
                { "world": "USA", "region": "Connecticut", "resId": 103 },
                { "world": "USA", "region": "Delaware", "resId": 119 },
                { "world": "USA", "region": "District Columbia", "resId": 125 },
                { "world": "USA", "region": "Florida", "resId": 77 },
                { "world": "USA", "region": "Georgia", "resId": 82 },
                { "world": "USA", "region": "Hawaii", "resId": 114 },
                { "world": "USA", "region": "Idaho", "resId": 113 },
                { "world": "USA", "region": "Illinois", "resId": 79 },
                { "world": "USA", "region": "Indiana", "resId": 90 },
                { "world": "USA", "region": "Iowa", "resId": 104 },
                { "world": "USA", "region": "Kansas", "resId": 108 },
                { "world": "USA", "region": "Kentucky", "resId": 126 },
                { "world": "USA", "region": "Louisiana", "resId": 99 },
                { "world": "USA", "region": "Maine", "resId": 115 },
                { "world": "USA", "region": "Maryland", "resId": 93 },
                { "world": "USA", "region": "Massachusetts", "resId": 88 },
                { "world": "USA", "region": "Michigan", "resId": 84 },
                { "world": "USA", "region": "Minnesota", "resId": 95 },
                { "world": "USA", "region": "Mississippi", "resId": 106 },
                { "world": "USA", "region": "Missouri", "resId": 92 },
                { "world": "USA", "region": "Montana", "resId": 118 },
                { "world": "USA", "region": "Nebraska", "resId": 111 },
                { "world": "USA", "region": "Nevada", "resId": 109 },
                { "world": "USA", "region": "New Hampshire", "resId": 116 },
                { "world": "USA", "region": "New Jersey", "resId": 85 },
                { "world": "USA", "region": "New Mexico", "resId": 110 },
                { "world": "USA", "region": "New York", "resId": 78 },
                { "world": "USA", "region": "North Carolina", "resId": 83 },
                { "world": "USA", "region": "North Dakota", "resId": 121 },
                { "world": "USA", "region": "Ohio", "resId": 81 },
                { "world": "USA", "region": "Oklahoma", "resId": 102 },
                { "world": "USA", "region": "Oregon", "resId": 101 },
                { "world": "USA", "region": "Pennsylvania", "resId": 80 },
                { "world": "USA", "region": "Puerto Rico", "resId": 128 },
                { "world": "USA", "region": "Rhode Island", "resId": 117 },
                { "world": "USA", "region": "South Carolina", "resId": 98 },
                { "world": "USA", "region": "South Dakota", "resId": 120 },
                { "world": "USA", "region": "Tennessee", "resId": 91 },
                { "world": "USA", "region": "Texas", "resId": 76 },
                { "world": "USA", "region": "US protests", "resId": 15 },
                { "world": "USA", "region": "USA", "resId": 22 },
                { "world": "USA", "region": "Utah", "resId": 107 },
                { "world": "USA", "region": "Vermont", "resId": 123 },
                { "world": "USA", "region": "Virginia", "resId": 86 },
                { "world": "USA", "region": "Washington", "resId": 87 },
                { "world": "USA", "region": "West Virginia", "resId": 112 },
                { "world": "USA", "region": "Wisconsin", "resId": 94 },
                { "world": "USA", "region": "Wyoming", "resId": 124 },

                { "world": "World", "region": "Africa", "resId": 8 },
                { "world": "World", "region": "Al Qaeda", "resId": 130 },
                { "world": "World", "region": "America", "resId": 11 },
                { "world": "World", "region": "Arctic", "resId": 49 },
                { "world": "World", "region": "Asia", "resId": 6 },
                { "world": "World", "region": "Avia", "resId": 34 },
                { "world": "World", "region": "Climate", "resId": 145 },
                { "world": "World", "region": "Cyberwar", "resId": 21 },
                { "world": "World", "region": "Digital Sherlocks", "resId": 134 },
                { "world": "World", "region": "Disasters", "resId": 27 },
                { "world": "World", "region": "Drugs War", "resId": 131 },
                { "world": "World", "region": "Europe", "resId": 10 },
                { "world": "World", "region": "Far-left", "resId": 165 },
                { "world": "World", "region": "Far-right", "resId": 164 },
                { "world": "World", "region": "Fifa 2018", "resId": 58 },
                { "world": "World", "region": "Health", "resId": 36 },
                { "world": "World", "region": "Human Rights", "resId": 28 },
                { "world": "World", "region": "Life Style", "resId": 35 },
                { "world": "World", "region": "Medecins Sans Frontieres", "resId": 132 },
                { "world": "World", "region": "Middle East", "resId": 7 },
                { "world": "World", "region": "Pacific", "resId": 23 },
                { "world": "World", "region": "Piracy", "resId": 68 },
                { "world": "World", "region": "Rio Olympic Games", "resId": 59 },
                { "world": "World", "region": "Roads", "resId": 51 },
                { "world": "World", "region": "Sports", "resId": 37 },
                { "world": "World", "region": "Trade Wars", "resId": 17 },
                { "world": "World", "region": "Travel", "resId": 32 },
                { "world": "World", "region": "War", "resId": 33 },
                { "world": "World", "region": "Weapons", "resId": 177 },
                { "world": "World", "region": "Wildlife", "resId": 38 },
                { "world": "World", "region": "Women", "resId": 11 },
                { "world": "World", "region": "World", "resId": 1 }

            ]
        };
        //console.log('newsMarker');
        for (const region of regions.regions) {
            this.newsMarkerPoint(region.region);
        }

    }

    removeNewsMarker() {
        for (let x of this.newsMarkers) {
            this.map.removeLayer(x);
        }
    }

    newsMarkerPointX(country) {
        this.mapIncident.getCountryNews(country).subscribe((val: any) => {
            //console.log('newsMarker', JSON.stringify(val));
            // this.map.mar

            for (const data of val) {
                let content = '';
                if (data.properties) {
                    if (data.properties.picture) {
                        content += '<a href="' + data.properties.picpath + '" target="_blank">' +
                            '<img style=" width: 300px" src="' + data.properties.picture + '"/>' +
                            '</a>';
                    }

                    content += '<h4>' + data.properties.title.split('http')[0] + '</h4>' +
                        '<p>' + data.properties.message.split('http')[0] +
                        '<a href="' + data.properties.link + '" target="_blank">Click here to read more</a>' +
                        '</p>' +
                        '<p>' +
                        '<a href="' + data.properties.source + '" target="_blank">News source</a>' +
                        '</p>'

                }


                /*"<a href=\"https://africa.liveuamap.com/en/images/is14/speech_green.png\" target=\"_blank\"><img style=\" width: 300px\" src=\"https://pbs.twimg.com/tweet_video_thumb/CVnmYgKW4AADNLx.png\"/></a>" +
                                    "<h4>The new trafficking route into Europe from Nigeria</h4>\n"+
                                    "<p>11:14 The new trafficking route into Europe from Nigeria https://africa.liveuamap.com/en/https://africa.liveuamap.com//en/2015/7-december-the-new-trafficking-route-into-europe-from-nigeria.\n" +
                                    "<a target=\"_blank\" href=\"https://africa.liveuamap.com/en/2015/7-december-the-new-trafficking-route-into-europe-from-nigeria\">Click here to read more</a>\n" +
                                    "</p>"*/
                let x = new L.marker(data.geometry.coordinates.reverse(), {
                    icon: icon({
                        iconSize: [20, 25],
                        // iconUrl: 'http://server.dryadglobal.com/icon/3.png'
                        iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-68ceed-ffffff-N@2x.png'
                    })
                }).bindPopup(content)
                    .on('mouseover', (d) => {
                        x.openPopup()
                    }).on('mouseout', (d) => {
                        // x.closePopup()
                    }).addTo(this.map);
                this.newsMarkers.push(x);
            }


        });
    }

    getCategories(cId) {
        // //console.warn(categories.find(value => value.id === cId).name);
        return categories.find(value => value.id === cId) ? categories.find(value => value.id === cId).name : "";
    }

    newsMarkerPoint(country) {
        this.mapIncident.getCountryNewsVenues(country).subscribe((val: any) => {
            //console.log('newsMarker', JSON.stringify(val));

            for (const data of val) {
                let content = '';

                /*if (data.picture) {
                    content += '<a href="' + data.picpath + '" target="_blank">' +
                        '<img style=" width: 300px" src="' + data.picture + '"/>' +
                        '</a>';
                }*/

                const cId = data.cat_id ? data.cat_id : 94;
                content += '<h4>' + data.name.split('http')[0] + '</h4>' +

                    '<p>' + this.util.getCategories(cId) + ' - ' + this.util.dateFormat(data.timestamp) + '</p>' +
                    '<p>' +
                    '<a href="' + data.link + '" target="_blank">Click here to read more</a>' +
                    '<a style="position: relative; left: 113px;" href="' + data.source + '" target="_blank">News source</a>' +
                    '</p>';


                let x = new L.marker([data.lat, data.lng], {
                    icon: icon({
                        iconSize: [30, 30],
                        iconUrl: `https://atlas.dryadglobal.com/map-icons/${cId}.png`
                        // iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-68ceed-ffffff-N@2x.png'
                    })
                }).bindPopup(content)
                    .on('click', (d) => {
                        x.openPopup()
                    }).on('mouseout', (d) => {
                        // x.closePopup()
                    }).addTo(this.map);
                this.newsMarkers.push(x);
            }
        });
    }


}


class Filter {
    match_phrase: MatchPhrase = new MatchPhrase()
}

class MatchPhrase {
    type: string = ''
}

class IncidentTableModal {
    id?: any = ''
    time?: any
    title?: any
    significant_tag?: any
    tags?: any
    classification_permissioning?: any
    contributor?: any
    lat?: any
    lon?: any
    dtg_parts?: any
    type?: any
    published?: boolean
    country?: any = ''
    code?: any = ''
}

export interface PeriodicElement {
    time: number;
    title: string;  // @ts-ignore
    significant_tag: string;
    tags: string;
    classification_permissioning: string;
    contributor: string;
}
