import { Component, Inject, NgZone, OnInit, ViewChild } from "@angular/core";
import { Color, Label, SingleDataSet } from "ng2-charts";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MatPaginator,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { IncidentReportService } from "../../service/dialog/incident-report.service";
import {
  circle,
  circleMarker,
  icon,
  latLng,
  Map,
  marker,
  tileLayer,
} from "leaflet";
import { MapIncidentService } from "../../service/map/map-incident.service";
import { UtilService } from "../../service/util/util.service";
import { NgxSpinnerService } from "ngx-spinner";
declare let L;
import html2canvas from "html2canvas";
import * as jspdf from "jspdf";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { code, url } from "../../utils/const";
import { __values } from "tslib";
import { HubspotService } from "../../service/hubspot.service";

class IncidentTableModal {
  id?: any = "";
  time?: any;
  title?: any;
  significant_tag?: any;
  tags?: any;
  classification_permissioning?: any;
  contributor?: any;
  lat?: any;
  lon?: any;
  dtg_parts?: any;
  type?: any;
  published?: boolean;
  country?: any = "";
  code?: any = "";
}

export interface PeriodicElement {
  time: number;
  title: string; // @ts-ignore
  significant_tag: string;
  tags: string;
  classification_permissioning: string;
  contributor: string;
}

@Component({
  selector: "app-port-graph-dialog-modal",
  templateUrl: "./port-graph-dialog-modal.component.html",
  styleUrls: ["./port-graph-dialog-modal.component.scss"],
})
export class PortGraphDialogModalComponent implements OnInit {
  isPdfOption = false;
  isDateRangeVisible = false;

  /* start checkbox ts by sucheta */
  /*checkboxes: any[] = [
        {name: "Overview", value: false },
        {name: "RiskSummary", value: false },
        {name: "RiskCategories", value: false },
        {name: "MaritimeCrimeData", value: false },
        {name: "IncidentSummaryMap", value: false },
        {name: "IncidentSummaryTable", value: false },
    ]*/
  checkbox = {
    isOverview: false,
    isRiskSummary: false,
    isRiskCategories: false,
    isMaritimeCrimeData: false,
    isIncidentSummaryMap: false,
    isIncidentSummaryTable: false,
  };

  maxCount = 0;
  streetMaps = tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}",
    {
      detectRetina: true,
      attribution:
        'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors',
    }
  );

  darkMap = tileLayer(
    "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png",
    {
      detectRetina: true,
      attribution:
        '&amp;copy; &lt;a href="https://www.openstreetmap.org/copyright"&gt;OpenStreetMap&lt;/a&gt; contributors',
    }
  );

  layersControl = {
    baseLayers: {
      "Street Maps": this.streetMaps,
    },
  };

  mapCanvas: any;
  circle = circleMarker([46.78465227596462, -121.74141269177198], {
    radius: 50,
    color: "#ff0000",
    fillOpacity: 0.6,
    weight: 1,
  });
  circle2 = circle([46.8523, -121.7603], {
    radius: 1000,
    color: "#ff0000",
    fillOpacity: 0.6,
    weight: 1,
  });
  summit = marker([46.8523, -121.7603], {
    icon: icon({
      iconSize: [25, 41],
      iconAnchor: [13, 41],
      iconUrl: "leaflet/marker-icon.png",
      shadowUrl: "leaflet/marker-shadow.png",
    }),
  });

  mLat: any = 0;
  mLng: any = 0;

  options = {
    layers: [this.streetMaps],
    minZoom: 3,
    maxZoom: 10,
    zoomControl: true,
    drawControl: false,
    zoom: 5,
    attributionControl: false,
    scrollWheelZoom: false,
    center: latLng([16.966666666666665, 82.3]),
  };

  drawOptions = {
    position: "bottomleft",
    draw: {
      polygon: true,
      polyline: false,
      rectangle: false,
      marker: false,
      circle: false,
      circlemarker: false,
    },
  };

  /************************************/
  displayedColumns = [
    "type",
    "time",
    "title",
    "significant_tag",
    "classification_permissioning",
    "star",
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  //Chartjs
  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75], label: "Incident Occurred" },
  ];

  lineChartLabels: Label[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
  ];

  lineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Incident Count",
          },
        },
      ],
    },
  };

  lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "rgba(255,255,0,0.28)",
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";

  //horizontal bar graph

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Incident Count",
          },
        },
      ],
    },
  };
  public barChartType: ChartType = "horizontalBar";
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [1, 2, 3], label: "Kidnap", stack: "a" },
    { data: [1, 2, 3], label: "Hijack", stack: "a" },
    { data: [1, 2, 3], label: "Robbery", stack: "a" },
    { data: [1, 2, 3], label: "Attack", stack: "a" },
  ];
  public barChartLabels: string[] = ["P", "R", "B"];

  ///vertical bar

  public barChartOptions1: ChartOptions = {
    responsive: true,
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: "Incident Count",
          },
        },
      ],
    },
  };
  public barChartLabels1: Label[] = [
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
  ];
  public barChartType1: ChartType = "bar";
  public barChartLegend1 = true;
  public barChartPlugins1 = [];

  public barChartData1: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: "Series A", stack: "a" },
    { data: [28, 48, 40, 19, 86, 27, 90], label: "Series B", stack: "a" },
  ];

  isDateTypeChange: boolean = true;

  startDate: any;
  endDate: any;
  timeType: any = "years";
  timeCount: number = 2;

  iStartDate: any;
  iEndDate: any;
  iRange: any = 2000;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialog: MatDialog,
    private http: HttpClient,
    private dialogRef: MatDialogRef<PortGraphDialogModalComponent>,
    private mapIncident: MapIncidentService,
    private hubspotService: HubspotService,
    private spinner: NgxSpinnerService,
    private ngZone: NgZone,
    public util: UtilService,
    private incidentReportService: IncidentReportService
  ) {
    console.log("dialog data x: ", JSON.stringify(data.incidentData));

    this.hubspotService.postLastViewItem(
      "Incident: " + this.data.incidentDetails.title
    );
    this.iStartDate = this.data.startTime;
    this.iEndDate = this.data.endTime;
    const date = new Date().setUTCFullYear(new Date().getUTCFullYear() - 2);
    const start = new Date(this.data.startTime);

    let sm = start.getMonth() + 1;
    let sMonth = sm >= 10 ? sm : "0" + sm;

    let sd = start.getDate();
    let sDate = sd >= 10 ? sd : "0" + sd;

    this.startDate = start.getFullYear() + "-" + sMonth + "-" + sDate;

    const end = new Date(this.data.endTime);

    let em = end.getMonth() + 1;
    let eMonth = em >= 10 ? em : "0" + em;

    let ed = end.getDate();
    let eDate = ed >= 10 ? ed : "0" + ed;

    this.endDate = end.getFullYear() + "-" + eMonth + "-" + eDate;

    // this.getPopMapMarkPop([]);
  }

  map: any;
  onMapReady(map: Map) {
    this.map = map;

    var latlon = [
      this.data.incidentDetails.lat ? this.data.incidentDetails.lat : 0,
      this.data.incidentDetails.lon ? this.data.incidentDetails.lon : 0,
    ];
    console.log("latlon", latlon);
    map.panTo(
      new L.LatLng(
        this.data.incidentDetails.lat ? this.data.incidentDetails.lat : 0,
        this.data.incidentDetails.lon ? this.data.incidentDetails.lon : 0
      )
    );
    // this.options.center = latLng(latlon)
    this.getPopMapMarkPop([]);
    // this.addMarkerToWorld(JSON.parse(this.data.marker))
  }

  onChange() {
    this.isDateTypeChange = !this.isDateTypeChange;
  }

  onApply() {
    this.iRange = this.range;

    if (this.isDateTypeChange) {
      this.data.endTime = new Date(this.endDate);
      this.data.startTime = new Date(this.startDate);
      // this.getPopData()
      this.iEndDate = new Date(this.endDate);
      this.iStartDate = new Date(this.startDate);
    } else {
      const d = new Date();
      var x;
      switch (this.timeType) {
        case "minutes":
          x = d.setMinutes(d.getMinutes() - this.timeCount);
          break;
        case "hours":
          x = d.setHours(d.getHours() - this.timeCount);
          break;
        case "days":
          x = d.setDate(d.getDate() - this.timeCount);
          break;
        case "months":
          x = d.setMonth(d.getMonth() - this.timeCount);
          break;
        case "years":
          x = d.setUTCFullYear(d.getFullYear() - this.timeCount);
          // this.startTime=new Date(x)
          break;
      }
      this.data.endTime = new Date();
      this.data.startTime = new Date(x);
      // this.getPopData()
      this.iEndDate = new Date();
      this.iStartDate = new Date(x);
    }
  }
  range = 2000;

  onRangeApply() {
    console.log("range", this.range);
    this.getPopData();
  }

  dataSourcePop = [];
  significantTag: any = {};
  dateSignificantTag: any = {};
  locationChart: any = {};

  portList = [];
  incidentList = [];
  migrationList = [];

  getPopData() {
    this.spinner.show();
    this.significantTag = {};
    this.dateSignificantTag = {};

    this.locationChart = {};

    var square = this.util.getBoundingCoords(
      this.data.incidentDetails.lat,
      this.data.incidentDetails.lon,
      this.range
    );

    let n1 = { lat: square.left.latitude, lon: square.left.longitude };
    let n2 = { lat: square.top.latitude, lon: square.top.longitude };
    let n3 = { lat: square.right.latitude, lon: square.right.longitude };
    let n4 = { lat: square.bottom.latitude, lon: square.bottom.longitude };
    let poly = [];
    poly.push(n1);
    poly.push(n2);
    poly.push(n3);
    poly.push(n4);

    let types = {};
    this.dataSourcePop = [];
    this.portList = [];
    this.incidentList = [];
    this.migrationList = [];

    let portObject = {};
    const sub = this.mapIncident
      .getIncidentsDetailsData(
        this.map.getBounds(),
        this.data.startTime,
        this.data.endTime,
        "",
        [],
        poly,
        5
      )
      .subscribe(
        (value) => {
          console.log("see more", value);
          if (value) {
            var hit = {};
            hit = JSON.parse(value);
            for (let data of hit["hits"]["hits"]) {
              const x = data["_source"]["type"];
              if (x.toLowerCase() == "port") {
                const obj = {};
                obj["value"] = data["_source"]["title"];
                obj["status"] = true;
                this.portList.push(obj);
              }

              if (x.toLowerCase() == "migration") {
                // if(data['_source']['title'])
                this.migrationList.push(data["_source"]["title"]);
              }

              types[x] = true;

              let modal = new IncidentTableModal();
              modal.title = data["_source"]["title"]
                ? data["_source"]["title"]
                : "";
              modal.time = data["_source"]["dtg"] ? data["_source"]["dtg"] : "";
              modal.classification_permissioning = data["_source"][
                "classification"
              ]["permissioning"]
                ? Array.isArray(
                    data["_source"]["classification"]["permissioning"]
                  )
                  ? data["_source"]["classification"]["permissioning"].join()
                  : data["_source"]["classification"]["permissioning"]
                : "                 ";
              modal.tags = data["_source"]["tags"]
                ? data["_source"]["tags"].join()
                : "";
              modal.significant_tag = data["_source"]["significant_tag"]
                ? data["_source"]["significant_tag"]
                : "unknown";
              modal.contributor = data["_source"]["contributor"]
                ? data["_source"]["contributor"]
                : "              ";
              modal.id = data["_source"]["id"] ? data["_source"]["id"] : "";

              let date = "unknown";

              if (data["_source"]["dtg_parts"]) {
                let month = data["_source"]["dtg_parts"]["month_desc"]
                  ? data["_source"]["dtg_parts"]["month_desc"]
                  : "";
                let year = data["_source"]["dtg_parts"]["year"]
                  ? data["_source"]["dtg_parts"]["year"]
                  : "unknown";
                date = month + " " + year;
              }

              let l = data["_source"]["classification"]["permissioning"];
              if (l) {
                if (Array.isArray(l)) {
                  for (let a of l) {
                    this.locationChart[a] = this.locationChart[a]
                      ? this.locationChart[a]
                      : {};
                    this.locationChart[a][modal.significant_tag] = this
                      .locationChart[a][modal.significant_tag]
                      ? this.locationChart[a][modal.significant_tag] + 1
                      : 1;
                  }
                } else {
                  this.locationChart[l] = this.locationChart[l]
                    ? this.locationChart[l]
                    : {};
                  this.locationChart[l][modal.significant_tag] = this
                    .locationChart[l][modal.significant_tag]
                    ? this.locationChart[l][modal.significant_tag] + 1
                    : 1;
                }
              } else {
                this.locationChart["unknown"] = this.locationChart["unknown"]
                  ? this.locationChart["unknown"]
                  : {};
                this.locationChart["unknown"][modal.significant_tag] = this
                  .locationChart["unknown"][modal.significant_tag]
                  ? this.locationChart["unknown"][modal.significant_tag] + 1
                  : 1;
              }

              this.dateSignificantTag[date] = this.dateSignificantTag[date]
                ? this.dateSignificantTag[date]
                : {};
              this.dateSignificantTag[date][modal.significant_tag] = this
                .dateSignificantTag[date][modal.significant_tag]
                ? this.dateSignificantTag[date][modal.significant_tag] + 1
                : 1;

              this.dataSourcePop.push(modal);

              this.significantTag[modal.significant_tag] = this.significantTag[
                modal.significant_tag
              ]
                ? this.significantTag[modal.significant_tag] + 1
                : 1;
            }

            this.createGraphParamsPop(poly);

            this.data.dataSource = new MatTableDataSource<PeriodicElement>(
              this.dataSourcePop
            );
          }
          this.data.dataSource.paginator = this.paginator;
          this.data.dataSource.sort = this.sort;
          sub.unsubscribe();
        },
        (err) => this.util.error("mirgation err: ", err)
      );
  }

  createGraphParamsPop(poly) {
    var label1 = this.util.convertToArray(this.dateSignificantTag);
    var label2 = this.util.convertToArray(this.significantTag);
    var label3 = this.util.convertToArray(this.locationChart);

    var barChartData1: ChartDataSets[] = [];
    var barChartData2: ChartDataSets[] = [];
    var barChartData3: ChartDataSets[] = [];

    for (let x of label2) {
      let data2 = [];
      for (let c of label2) {
        if (c == x) {
          data2.push(this.significantTag[x]);
        } else {
          data2.push(0);
        }
      }

      barChartData2.push({
        data: data2,
        label: x,
        stack: "a",
      });

      let data1 = [];
      for (let y of label1) {
        data1.push(
          this.dateSignificantTag[y][x] ? this.dateSignificantTag[y][x] : 0
        );
      }
      barChartData1.push({
        data: data1,
        label: x,
        stack: "a",
      });

      let data3 = [];
      for (let y of label3) {
        data3.push(this.locationChart[y][x] ? this.locationChart[y][x] : 0);
      }
      barChartData3.push({
        data: data3,
        label: x,
        stack: "a",
      });
    }

    this.data.chart1 = {
      label: label1,
      data: barChartData1,
    };
    this.data.chart2 = {
      label: label3,
      data: barChartData3,
    };

    this.getPopMapMarkPop(poly);
  }

  incidentMarker: any;
  getPopMapMarkPop(poly) {
    this.mapIncident
      .getIncidents(
        this.map.getBounds(),
        this.data.startTime,
        this.data.endTime,
        "",
        [],
        poly,
        3,
        6
      )
      .subscribe(
        (value) => {
          console.log("Get Incidents in Map: ", JSON.parse(value));
          // this.addMarkerToWorld(JSON.parse(value))
          // this.popmarker = value
          this.addMarkerToWorld(JSON.parse(value));
          this.incidentMarker = JSON.parse(value);
          this.spinner.hide();
          this.createImageById("map1");
          // this.createImageById('chart1')
          // this.createImageById('chart2')
        },
        (error) => this.util.error("Get Incidents in Map err: ", error)
      );
  }

  markerList: any = [];

  addMarkerToWorld(dumpIncidentJson: {
    took: number;
    timed_out: boolean;
    _shards: {
      total: number;
      successful: number;
      skipped: number;
      failed: number;
    };
    hits: { total: number; max_score: null; hits: any[] };
    aggregations: {
      filter_agg: {
        "2": {
          buckets: {
            "3": { location: { lat: number; lon: number }; count: number };
            key: string;
            doc_count: number;
          }[];
        };
        doc_count: number;
      };
    };
    status: number;
  }) {
    for (let marker of this.markerList) {
      this.map.removeLayer(marker);
    }

    // var buckets = dumpIncidentJson.aggregations.filter_agg["2"].buckets
    //
    // this.maxCount = 0;
    // for(let bucket of buckets){
    //     if(bucket["3"].count > this.maxCount){
    //         this.maxCount = bucket["3"].count;
    //     }
    // }
    // for(let bucket of buckets){
    //
    //     let x =   new L.circle([bucket["3"].location.lat,bucket["3"].location.lon],{
    //         radius:bucket["3"].count*2500,
    //         color:this.getColorMarkerForIncidentCount(bucket["3"].count,this.maxCount),
    //     }).bindPopup("<h4><b>count:<b> "+bucket["3"].count+"" +
    //         "</h4></br><h4></h4><b>latitude: <b>"+this.util.getDMS(marker[0],'lat')+"</h4></br><h4></h4><b>longitude: <b>"+this.util.getDMS(marker[1],'long')+"</h4>").on('mouseover',(d)=>{
    //         x.openPopup()
    //     }).addTo(this.map);
    //
    //     this.markerList.push(x)
    //
    // }

    var hits = dumpIncidentJson.hits.hits;

    this.maxCount = 2;
    for (let hit of hits) {
      if (hit._source.published) {
        // console.log('out')
        if (hit._index == "portal-incidents") {
          if (hit._source && hit._source.lat && hit._source.lon) {
            // let circlecolor = '#68ff65';
            let circlecolor = "white";
            let symbol = "";
            switch (hit._source.significant_tag) {
              case "Hijack":
                circlecolor = "f8433f";
                symbol = "H";
                break;
              case "Kidnap":
                circlecolor = "fd9b3e";
                symbol = "K";
                break;
              case "Kidnapping":
                circlecolor = "fd9b3e";
                symbol = "K";
                break;
              case "Attack":
                circlecolor = "c27ba0";
                symbol = "A";
                break;
              case "Fired Upon":
                circlecolor = "8e7cc3";
                symbol = "F";
                break;
              case "Boarding":
                circlecolor = "6d9eeb";
                symbol = "B";
                break;
              case "Robbery":
                circlecolor = "b7b7b7";
                symbol = "R";
                break;
              case "Attempted":
                circlecolor = "93c47d";
                symbol = "AT";
                break;
              case "Approach":
                circlecolor = "2f6318";
                symbol = "AP";
                break;
              default:
                circlecolor = "white";
            }
            // console.log(JSON.stringify(hit._source))
            let x = new L.marker(
              [hit["_source"]["lat"], hit["_source"]["lon"]],
              {
                icon: icon({
                  iconSize: [20, 25],
                  iconAnchor: [0, 0],
                  iconUrl:
                    "https://assets.mapquestapi.com/icon/v2/marker-sm-" +
                    circlecolor +
                    "-" +
                    circlecolor +
                    "-" +
                    symbol +
                    "@2x.png",
                  // shadowUrl: 'leaflet/marker-shadow.png'
                }),
              }
            )
              .bindPopup(
                "<p class='bText'> " +
                  hit._source.title +
                  "" +
                  "</p>" +
                  "<p><b>Latitude: </b>" +
                  this.util.getDMS(hit._source.lat, "lat") +
                  "</p>" +
                  "<p><b>Longitude: </b>" +
                  this.util.getDMS(hit._source.lon, "long") +
                  "</p>" +
                  "<p><b>Date : </b>" +
                  hit._source.dtg_parts.month_desc +
                  ", " +
                  hit._source.dtg_parts.day +
                  " " +
                  hit._source.dtg_parts.year +
                  "</p>",
                {
                  bucket: hit,
                }
              )
              .on("mouseover", (d) => {
                x.openPopup();
              })
              .on("click", () => {
                this.ngZone.run(() => {
                  this.spinner.show();
                  this.getPopData();
                });
              })
              .addTo(this.map);
            this.markerList.push(x);
          }
        }
      }
    }
  }

  getColorMarkerForIncidentCount(n, maxCount) {
    let color: string = "#68ff65";
    if (n < maxCount / 4) {
      color = "#68ff65";
    } else if (n < (maxCount / 4) * 2) {
      color = "#fff928";
    } else if (n < (maxCount / 4) * 3) {
      color = "#ff8a1f";
    } else {
      color = "#ff0721";
    }
    return color;
  }

  createImageById(id) {
    html2canvas(document.getElementById(id), {
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas) => {
        if (id == "map1") {
          this.mapCanvas = canvas;
        }
        console.log(id, canvas.toDataURL());
      })
      .catch((err) => {
        this.util.error("html2canvas err:", err);
      });
  }

  ngOnInit() {
    this.data.dataSource.paginator = this.paginator;
    this.data.dataSource.sort = this.sort;
  }

  onClose() {
    this.dialogRef.close();
  }

  chartHovered(event) {}
  chartClicked(event) {}

  isFullScreen: boolean = false;
  onResize() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $(".cdk-overlay-pane").css("max-width", "100vw");
      $(".cdk-overlay-pane").css("height", "100%");
      $(".cdk-overlay-pane").css("width", "100%");
    } else {
      $(".cdk-overlay-pane").css("height", "70%");
      $(".cdk-overlay-pane").css("width", "65%");
    }
  }

  // convertImagesToPDF(){
  convertToPDF() {
    let x1 = document.getElementById("convertopdfUser");
    let x2 = document.getElementById("convertopdfUser2");
    let x3 = document.getElementById("convertopdfUser3");
    let x4 = document.getElementById("convertopdfUser4");

    this.spinner.show();
    html2canvas(x1, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas1) => {
      html2canvas(x2, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas2) => {
        html2canvas(x3, {
          allowTaint: true,
          useCORS: true,
        }).then((canvas3) => {
          html2canvas(x4, {
            allowTaint: true,
            useCORS: true,
          }).then((canvas4) => {
            this.spinner.hide();
            this.toPDF(canvas1, canvas2, canvas3, canvas4, x1, x2, x3, x4);
          });
        });
      });
    });
  }

  toPDF(canvas1, canvas2, canvas3, canvas4, x1, x2, x3, x4) {
    var pdf = new jspdf("p", "pt", "a4");
    var xfact = 1150;

    let j = 0;
    for (var i = 0; i <= x1.clientHeight / xfact; i++) {
      //! This is all just html2canvas stuff
      var srcImg = canvas1;
      var sX = 0;
      var sY = xfact * i; // start 980 pixels down for every new page
      //! var sWidth  = 778;
      var sWidth = 850;
      var sHeight = 1150;
      var dX = 0;
      var dY = 0;
      //! var dWidth  = 778;
      var dWidth = 850;
      var dHeight = 1150;

      var onePageCanvas = document.createElement("canvas");
      onePageCanvas.setAttribute("width", "850");
      onePageCanvas.setAttribute("height", "1150");
      var ctx = onePageCanvas.getContext("2d");

      sHeight = xfact;
      ctx.drawImage(
        srcImg,
        sX,
        sY,
        x1.clientWidth,
        sHeight,
        dX,
        dY,
        dWidth,
        dHeight
      );

      var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

      var width = onePageCanvas.width;
      var height = onePageCanvas.clientHeight;

      if (i > 0) {
        pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
      }
      pdf.setPage(i + 1);
      j = i + 1;
      //! now we add content to that page!
      pdf.addImage(canvasDataURL, "PNG", 0, 0, width * 0.72, height * 0.72);
    }

    let onePageCanvas2 = document.createElement("canvas");
    onePageCanvas2.setAttribute("width", "850");
    onePageCanvas2.setAttribute("height", "1150");
    let ctx2 = onePageCanvas2.getContext("2d");

    ctx2.drawImage(canvas2, 0, 0, x2.clientWidth, 1150, 0, 0, 850, 1150);

    let canvasDataURL2 = onePageCanvas2.toDataURL("image/png", 1.0);
    let width2 = onePageCanvas2.width;
    let height2 = onePageCanvas2.clientHeight;
    if (j > 0) {
      pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
    }
    pdf.setPage(j + 1);

    pdf.addImage(canvasDataURL2, "PNG", 0, 0, width2 * 0.72, height2 * 0.72);

    let onePageCanvas3 = document.createElement("canvas");
    onePageCanvas3.setAttribute("width", "850");
    onePageCanvas3.setAttribute("height", "1150");
    let ctx3 = onePageCanvas3.getContext("2d");

    ctx3.drawImage(canvas3, 0, 0, x3.clientWidth, 1150, 0, 0, 850, 1150);

    let canvasDataURL3 = onePageCanvas3.toDataURL("image/png", 1.0);
    let width3 = onePageCanvas3.width;
    let height3 = onePageCanvas3.clientHeight;
    if (j > 0) {
      pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
    }

    pdf.setPage(j + 2);

    pdf.addImage(canvasDataURL3, "PNG", 0, 0, width3 * 0.72, height3 * 0.72);

    let onePageCanvas4 = document.createElement("canvas");
    onePageCanvas4.setAttribute("width", "850");
    onePageCanvas4.setAttribute("height", "1150");
    let ctx4 = onePageCanvas4.getContext("2d");

    ctx4.drawImage(canvas4, 0, 0, x4.clientWidth, 1150, 0, 0, 850, 1150);

    let canvasDataURL4 = onePageCanvas4.toDataURL("image/png", 1.0);
    let width4 = onePageCanvas4.width;
    let height4 = onePageCanvas4.clientHeight;
    if (j > 0) {
      pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
    }
    pdf.setPage(j + 3);

    pdf.addImage(canvasDataURL4, "PNG", 0, 0, width4 * 0.72, height4 * 0.72);

    pdf.save("Test3.pdf");
  }

  convertToPDFx() {
    var data = document.getElementById("convertopdfUser");
    console.log((data.clientHeight / data.clientWidth) * 850);

    //! var xfact = data.clientHeight/data.clientWidth * 850

    var xfact = 1150;

    html2canvas(data, {
      allowTaint: true,
      useCORS: true,
    }).then((canvas) => {
      var pdf = new jspdf("p", "pt", "a4");

      for (var i = 0; i <= data.clientHeight / xfact; i++) {
        //! This is all just html2canvas stuff
        var srcImg = canvas;
        var sX = 0;
        var sY = xfact * i; // start 980 pixels down for every new page
        //! var sWidth  = 778;
        var sWidth = 850;
        var sHeight = 1150;
        var dX = 0;
        var dY = 0;
        //! var dWidth  = 778;
        var dWidth = 850;
        var dHeight = 1150;

        var onePageCanvas = document.createElement("canvas");
        onePageCanvas.setAttribute("width", "850");
        onePageCanvas.setAttribute("height", "1150");
        var ctx = onePageCanvas.getContext("2d");
        // details on this usage of this function:
        // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
        // ctx.drawImage("<h1>ss</h1>" +
        //     "<img src='{{canvas}}'>",sX,sY,data.clientWidth,sHeight,dX,dY,dWidth,dHeight)

        sHeight = xfact;
        ctx.drawImage(
          srcImg,
          sX,
          sY,
          data.clientWidth,
          sHeight,
          dX,
          dY,
          dWidth,
          dHeight
        );

        // ctx.drawImage(srcImg,sX,sY,data.clientWidth,data.clientHeight,dX,dY,data.clientWidth,data.clientHeight);

        // document.body.appendChild(canvas);
        var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

        var width = onePageCanvas.width;
        var height = onePageCanvas.clientHeight;

        //! If we're on anything other than the first page,
        // add another page
        if (i > 0) {
          pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
        }
        //! now we declare that we're working on that page
        pdf.setPage(i + 1);
        //! now we add content to that page!
        pdf.addImage(canvasDataURL, "PNG", 0, 0, width * 0.72, height * 0.72);
      }
      //! after the for loop is finished running, we save the pdf.
      pdf.save("Test3.pdf");
    });
  }

  /***************Jump to another****************/
  incidentDataJump: any;
  onViewMore(element) {
    this.spinner.show();

    let significantTag = {};
    let dateSignificantTag = {};
    let locationChart = {};

    let types = {};

    let dataSourcePop = [];
    let portList = [];
    let incidentList = [];
    let migrationList = [];

    let portObject = {};
    this.mapIncident.getIncident(element.id).subscribe(
      (value) => {
        var incidentDataJump = JSON.parse(value);
        var x = incidentDataJump.hits.hits[0]._source;
        var lat = 0;
        var lon = 0;

        if (x.lat) {
          lat = x.lat;
        }
        if (x.lon) {
          lat = x.lon;
        }
        var square = this.util.getBoundingCoords(lat, lon, 2000);

        let n1 = { lat: square.left.latitude, lon: square.left.longitude };
        let n2 = { lat: square.top.latitude, lon: square.top.longitude };
        let n3 = { lat: square.right.latitude, lon: square.right.longitude };
        let n4 = { lat: square.bottom.latitude, lon: square.bottom.longitude };

        let poly = [];
        poly.push(n1);
        poly.push(n2);
        poly.push(n3);
        poly.push(n4);

        const filter = [
          { match_phrase: { type: "incident" } },
          { match_phrase: { type: "migration" } },
          { match_phrase: { type: "country" } },
          { match_phrase: { type: "dryad" } },
        ];
        const sub = this.mapIncident
          .getIncidentsDetailsData(
            this.map.getBounds(),
            this.data.startTime,
            this.data.endTime,
            "",
            filter,
            poly,
            4
          )
          .subscribe(
            (val) => {
              if (val) {
                var hit = {};
                hit = JSON.parse(val);
                for (let data of hit["hits"]["hits"]) {
                  const x = data["_source"]["type"];
                  if (x.toLowerCase() == "port") {
                    const obj = {};
                    obj["value"] = data["_source"]["title"];
                    obj["status"] = true;
                    portList.push(obj);
                  }

                  if (x.toLowerCase() == "migration") {
                    migrationList.push(data["_source"]["title"]);
                  }

                  types[x] = true;

                  let modal = new IncidentTableModal();
                  modal.title = data["_source"]["title"]
                    ? data["_source"]["title"]
                    : "";
                  modal.time = data["_source"]["dtg"]
                    ? data["_source"]["dtg"]
                    : "";
                  modal.classification_permissioning = data["_source"][
                    "classification"
                  ]["permissioning"]
                    ? Array.isArray(
                        data["_source"]["classification"]["permissioning"]
                      )
                      ? data["_source"]["classification"][
                          "permissioning"
                        ].join()
                      : data["_source"]["classification"]["permissioning"]
                    : "                 ";
                  modal.tags = data["_source"]["tags"]
                    ? data["_source"]["tags"].join()
                    : "";
                  modal.significant_tag = data["_source"]["significant_tag"]
                    ? data["_source"]["significant_tag"]
                    : "unknown";
                  modal.contributor = data["_source"]["contributor"]
                    ? data["_source"]["contributor"]
                    : "              ";
                  modal.id = data["_source"]["id"] ? data["_source"]["id"] : "";
                  modal.lat = data["_source"]["lat"]
                    ? data["_source"]["lat"]
                    : 0;
                  modal.lon = data["_source"]["lon"]
                    ? data["_source"]["lon"]
                    : 0;
                  modal.type = data["_source"]["type"]
                    ? data["_source"]["type"]
                    : "";
                  modal.published = data["_source"]["published"]
                    ? data["_source"]["published"]
                    : false;

                  // markers.push([modal.lat,modal.lon])

                  let date = "unknown";

                  if (data["_source"]["dtg_parts"]) {
                    let month = data["_source"]["dtg_parts"]["month_desc"]
                      ? data["_source"]["dtg_parts"]["month_desc"]
                      : "";
                    let year = data["_source"]["dtg_parts"]["year"]
                      ? data["_source"]["dtg_parts"]["year"]
                      : "unknown";
                    date = month + " " + year;
                  }

                  let l = data["_source"]["classification"]["permissioning"];
                  if (l) {
                    if (Array.isArray(l)) {
                      for (let a of l) {
                        locationChart[a] = locationChart[a]
                          ? locationChart[a]
                          : {};
                        locationChart[a][modal.significant_tag] = locationChart[
                          a
                        ][modal.significant_tag]
                          ? locationChart[a][modal.significant_tag] + 1
                          : 1;
                      }
                    } else {
                      locationChart[l] = locationChart[l]
                        ? locationChart[l]
                        : {};
                      locationChart[l][modal.significant_tag] = locationChart[
                        l
                      ][modal.significant_tag]
                        ? locationChart[l][modal.significant_tag] + 1
                        : 1;
                    }
                  } else {
                    locationChart["unknown"] = locationChart["unknown"]
                      ? locationChart["unknown"]
                      : {};
                    locationChart["unknown"][modal.significant_tag] =
                      locationChart["unknown"][modal.significant_tag]
                        ? locationChart["unknown"][modal.significant_tag] + 1
                        : 1;
                  }

                  dateSignificantTag[date] = dateSignificantTag[date]
                    ? dateSignificantTag[date]
                    : {};
                  dateSignificantTag[date][modal.significant_tag] =
                    dateSignificantTag[date][modal.significant_tag]
                      ? dateSignificantTag[date][modal.significant_tag] + 1
                      : 1;

                  significantTag[modal.significant_tag] = significantTag[
                    modal.significant_tag
                  ]
                    ? significantTag[modal.significant_tag] + 1
                    : 1;

                  dataSourcePop.push(modal);
                }

                /***********************************************************/

                var label1 = this.util.convertToArray(dateSignificantTag);
                var label2 = this.util.convertToArray(significantTag);
                var label3 = this.util.convertToArray(locationChart);

                var barChartData1: ChartDataSets[] = [];
                var barChartData2: SingleDataSet = [];
                var barChartData3: ChartDataSets[] = [];

                for (let x of label2) {
                  let data2 = [];
                  barChartData2.push(significantTag[x]);

                  let data1 = [];
                  for (let y of label1) {
                    data1.push(
                      dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0
                    );
                  }
                  barChartData1.push({
                    data: data1,
                    label: x,
                    stack: "a",
                  });

                  let data3 = [];
                  for (let y of label3) {
                    data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                  }
                  barChartData3.push({
                    data: data3,
                    label: x,
                    stack: "a",
                  });
                }

                let chart1 = {
                  label: label1.reverse(),
                  data: barChartData1.reverse(),
                };
                let chart2 = {
                  label: label2,
                  data: barChartData2,
                };
                let chart3 = {
                  label: label3,
                  data: barChartData3,
                };

                this.mapIncident
                  .getIncidentsPop(
                    this.map.getBounds(),
                    this.data.startTime,
                    this.data.endTime,
                    "",
                    [],
                    poly,
                    4
                  )
                  .subscribe(
                    (value1) => {
                      var popmarker = value1;

                      this.ngZone.run(() => {
                        var tag = [];
                        const dataSource4 = dataSourcePop.filter((val) => {
                          return val.published;
                        });
                        this.spinner.hide();

                        const matDialogConfig = new MatDialogConfig();
                        matDialogConfig.height = "70%";
                        matDialogConfig.width = "65%";
                        matDialogConfig.data = {
                          startTime: this.data.startTime,
                          endTime: this.data.endTime,
                          chart1: chart1,
                          chart2: chart3,
                          incidentDetails:
                            incidentDataJump.hits.hits[0]._source,
                          marker: popmarker,
                          dataSource: new MatTableDataSource<PeriodicElement>(
                            dataSource4
                          ),
                          table: dataSource4,
                        };
                        const dialogRef = this.matDialog.open(
                          PortGraphDialogModalComponent,
                          matDialogConfig
                        );

                        dialogRef.afterClosed().subscribe((result) => {
                          this.util.log("Dialog result: ", result);
                        });
                        this.spinner.hide();
                      });
                    },
                    (error) =>
                      this.util.error("Get Incidents in Map err: ", error)
                  );
              }
              sub.unsubscribe();
            },
            (err) => this.util.error("mirgation err: ", err)
          );
      },
      (err) => this.util.log("err", err)
    );
  }

  /*
    onViewMore(element){
        this.spinner.show()
        console.log('onViewMore: ',JSON.stringify(element))
        this.mapIncident.getIncident(element.id).subscribe(value=>{
            this.incidentDataJump = JSON.parse(value)
            var x = this.incidentDataJump.hits.hits[0]._source
            this.getPopDataToJump(x.lat,x.lon,element.id)
        },err=>this.util.log('err',err))
    }
*/

  /* getPopDataToJump(lat,lon,id){

        this.significantTag={}
        this.dateSignificantTag={}
        this.locationChart={}

        var square = this.util.getBoundingCoords(lat, lon,2000)

        let n1 = {lat:square.left.latitude,lon:square.left.longitude};
        let n2 = {lat:square.top.latitude,lon:square.top.longitude};
        let n3 = {lat:square.right.latitude,lon:square.right.longitude};
        let n4 = {lat:square.bottom.latitude,lon:square.bottom.longitude};

        let poly = []
        poly.push(n1);
        poly.push(n2);
        poly.push(n3);
        poly.push(n4);

        let types = {}
        this.dataSourcePop=[]
        this.portList=[]
        this.incidentList=[]
        this.migrationList=[]

        let portObject={}
        const sub = this.mapIncident.getIncidentsDetailsData(this.map.getBounds(),this.data.startTime,this.data.endTime,'',[],poly,4).subscribe(value=>{
            console.log('see more',value)
            if(value){
                var hit ={}
                hit = JSON.parse(value)
                for (let data of hit['hits']['hits']){


                    const x = data['_source']['type']
                    if(x.toLowerCase()=='port'){
                        const obj={}
                        obj['value']=data['_source']['title']
                        obj['status']=true
                        this.portList.push(obj)
                    }


                    if(x.toLowerCase()=='migration'){
                        // if(data['_source']['title'])
                        this.migrationList.push(data['_source']['title'])
                    }

                    types[x] = true

                    let modal = new IncidentTableModal()
                    modal.title = data['_source']['title']?data['_source']['title']:''
                    modal.time = data['_source']['dtg']?data['_source']['dtg']:''
                    modal.classification_permissioning = data['_source']['classification']['permissioning']?Array.isArray(data['_source']['classification']['permissioning'])?data['_source']['classification']['permissioning'].join():data['_source']['classification']['permissioning']:'                 '
                    modal.tags = data['_source']['tags']? data['_source']['tags'].join():'';
                    modal.significant_tag = data['_source']['significant_tag']?data['_source']['significant_tag']:'unknown'
                    modal.contributor = data['_source']['contributor']?data['_source']['contributor']:'              '
                    modal.id = data['_source']['id'] ? data['_source']['id']:''

                    let date = 'unknown'

                    if(data['_source']['dtg_parts']){
                        let month = data['_source']['dtg_parts']['month_desc']?data['_source']['dtg_parts']['month_desc']:''
                        let year = data['_source']['dtg_parts']['year']?data['_source']['dtg_parts']['year']:'unknown'
                        date = month +' '+year
                    }

                    let l =data['_source']['classification']['permissioning']
                    if(l){
                        if(Array.isArray(l)){

                            for(let a of l){
                                this.locationChart[a]=this.locationChart[a]?this.locationChart[a]:{};
                                this.locationChart[a][modal.significant_tag]=this.locationChart[a][modal.significant_tag]?this.locationChart[a][modal.significant_tag]+1:1;

                            }

                        }else{
                            this.locationChart[l]=this.locationChart[l]?this.locationChart[l]:{};
                            this.locationChart[l][modal.significant_tag]=this.locationChart[l][modal.significant_tag]?this.locationChart[l][modal.significant_tag]+1:1;
                        }
                    }else{
                        this.locationChart['unknown']=this.locationChart['unknown']?this.locationChart['unknown']:{};
                        this.locationChart['unknown'][modal.significant_tag]=this.locationChart['unknown'][modal.significant_tag]?this.locationChart['unknown'][modal.significant_tag]+1:1;
                    }



                    this.dateSignificantTag[date] = this.dateSignificantTag[date]?this.dateSignificantTag[date]:{}
                    this.dateSignificantTag[date][modal.significant_tag]=this.dateSignificantTag[date][modal.significant_tag]?this.dateSignificantTag[date][modal.significant_tag]+1:1;


                    this.dataSourcePop.push(modal)


                    this.significantTag[modal.significant_tag] = this.significantTag[modal.significant_tag]? this.significantTag[modal.significant_tag]+1 : 1;

                }
                console.log('dataSourcePop: ',JSON.stringify(this.locationChart),JSON.stringify(this.dateSignificantTag))
                this.createGraphParamsPopToJump(poly,id)
            }
            sub.unsubscribe()
        },err=>this.util.error('mirgation err: ',err))


    }
*/
  chartJump1: any;
  chartJump2: any;

  /* createGraphParamsPopToJump(poly,id){

        var label1 = this.util.convertToArray(this.dateSignificantTag);
        var label2 = this.util.convertToArray(this.significantTag);
        var label3 = this.util.convertToArray(this.locationChart);

        var barChartData1: ChartDataSets[]=[];
        var barChartData2: ChartDataSets[]=[];
        var barChartData3: ChartDataSets[]=[];

        for(let x of label2){
            let data2 = [];
            for(let c of label2){
                if(c==x){
                    data2.push(this.significantTag[x])
                }else{
                    data2.push(0)
                }
            }

            barChartData2.push({
                data:data2,
                label:x,
                stack:'a'
            })

            let data1=[]
            for(let y of label1){
                data1.push(this.dateSignificantTag[y][x]?this.dateSignificantTag[y][x]:0);
            }
            barChartData1.push({
                data:data1,
                label:x,
                stack:'a'
            })

            let data3=[]
            for(let y of label3){
                data3.push(this.locationChart[y][x]?this.locationChart[y][x]:0);
            }
            barChartData3.push({
                data:data3,
                label:x,
                stack:'a'
            })
        }


        this.chartJump1={
            label:label1.reverse(),
            data:barChartData1.reverse()
        };
        this.chartJump2={
            label:label3,
            data:barChartData3
        };
  
        this.getPopMapMarkPopJump(poly,id)


    }
*/
  /*
    getPopMapMarkPopJump(poly,id){


        this.mapIncident.getIncidentsPop(this.map.getBounds(),this.data.startTime,this.data.endTime,'',[],poly,4).subscribe(value => {

            var popmarker = value

            this.ngZone.run(()=>{
                var tag = []
                this.spinner.hide();

                const matDialogConfig = new MatDialogConfig();
                matDialogConfig.height = "80%";
                matDialogConfig.width = "75%";
                matDialogConfig.data = {
                    startTime: this.data.startTime,
                    endTime: this.data.endTime,
                    chart1: this.chartJump1,
                    chart2: this.chartJump2,
                    incidentDetails: this.incidentDataJump.hits.hits[0]._source, //Incident_Details._source,
                    marker: popmarker,
                    dataSource: new MatTableDataSource<PeriodicElement>(this.dataSourcePop)
                }
                const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    this.util.log('Dialog result: ', result)
                });
            })

        },error=>this.util.error('Get Incidents in Map err: ',error))
    }
*/

  createFile() {
    this.spinner.show();

    let table =
      "<tr>\n" +
      '<th class="bText">Time</th>\n' +
      '<th class="bText">Title</th>\n' +
      '<th class="bText">Significant Type</th>\n' +
      '<th class="bText">Area</th>\n' +
      "</tr>";

    let tb = this.data.table;
    for (let x = 0; x < tb.length; x++) {
      table +=
        "<tr>\n" +
        "<th>" +
        this.util.getTime(tb[x].time) +
        "</th>\n" +
        "<th>" +
        tb[x].title +
        "</th>\n" +
        "<th>" +
        tb[x].significant_tag +
        "</th>\n" +
        "<th>" +
        tb[x].classification_permissioning +
        "</th>\n" +
        "</tr>";
    }

    let tableHTML = "";
    tableHTML =
      '<div class="page_break"></div><div class="pageDiv">\n' +
      '<div class="pageContent">\n' +
      '<p class="bText fontSize18">Incident Summary Table: </p><br>' +
      '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
      table +
      "</table></div></div>";

    let x4 = document.getElementById("cGraph");
    let x1 = document.getElementById("cGraph1");
    let x2 = document.getElementById("cGraph2");
    let x3 = document.getElementById("iMap");

    // this.spinner.show()
    html2canvas(x1, {
      allowTaint: true,
      useCORS: true,
    })
      .then((charts) => {
        html2canvas(x2, {
          allowTaint: true,
          useCORS: true,
        })
          .then((charts2) => {
            html2canvas(x3, {
              allowTaint: true,
              useCORS: true,
            })
              .then((map1) => {
                console.log(charts.toDataURL());
                this.getPDF(
                  tableHTML,
                  charts.toDataURL(),
                  charts2.toDataURL(),
                  map1.toDataURL()
                );
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  getPDF(tableHTML, charts, charts2, map1) {
    let d = new Date();
    // const url = 'http://159.65.152.29/dryad-dompdf/incidentPdf.php'
    this.http
      .post(
        url.incident,
        {
          title: "Incident Threat Assessment",
          name: this.data.incidentDetails.title,
          type: this.data.incidentDetails.significant_tag,
          time:
            this.data.incidentDetails.dtg_parts.day +
            "-" +
            this.data.incidentDetails.dtg_parts.month_desc +
            "-" +
            this.data.incidentDetails.dtg_parts.year,
          location:
            this.util.getDMS(this.data.incidentDetails.lat, "lat") +
            ", " +
            this.util.getDMS(this.data.incidentDetails.lon, "long"),
          area: this.data.incidentDetails.classification.dryad_area.join(", "),
          country: this.data.incidentDetails.country.description,
          chartImg: charts,
          chartImg2: charts2,
          mapImg: map1,
          tableHTML: tableHTML,
          company: this.util.company,
          date:
            d.getDate() +
            "/" +
            Math.abs(d.getMonth() + 1) +
            "/" +
            d.getFullYear(),
          analysisText: "Maritime Crime Data within " + this.range + " Nm",
          graphText:
            "Reporting Period from: " +
            this.util.getDate(this.data.startTime) +
            " to " +
            this.util.getDate(this.data.endTime),
          narrative: this.data.incidentDetails.notes.join(", "),
        },
        {
          responseType: "arraybuffer",
          headers: new HttpHeaders()
            .set("Content-Type", "application/x-www-form-urlencoded")
            .set("Accept", "application/pdf"),
        }
      )
      .subscribe(
        (response) => {
          this.downLoadFile(response, "application/pdf");

          console.log(response, "sssss");
        },
        (error2) => {
          console.log("errrrrrrrr", error2);
        }
      );
  }

  dateRangeVisible(bool) {
    this.isDateRangeVisible = bool;
  }

  createFileOption() {
    this.isPdfOption = !this.isPdfOption;
  }

  GetStats(value) {
    console.log("GetStats", JSON.stringify(this.checkbox));
  }

  createPDF() {
    let m1 = document.getElementById("cMap");
    let g1 = document.getElementById("cbgGraph1");
    let g2 = document.getElementById("cbgGraph2");

    if (
      this.checkbox.isMaritimeCrimeData ||
      this.checkbox.isIncidentSummaryMap
    ) {
      if (
        this.checkbox.isMaritimeCrimeData &&
        this.checkbox.isIncidentSummaryMap
      ) {
        this.spinner.show();
        html2canvas(g1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((charts1) => {
            html2canvas(g2, {
              allowTaint: true,
              useCORS: true,
            })
              .then((charts2) => {
                html2canvas(m1, {
                  allowTaint: true,
                  useCORS: true,
                })
                  .then((map1) => {
                    this.spinner.hide();
                    this.genratePDF(
                      charts1.toDataURL(),
                      charts2.toDataURL(),
                      map1.toDataURL()
                    );
                  })
                  .catch((err) => {
                    this.spinner.hide();
                    console.error(err);
                  });
              })
              .catch((err) => {
                this.spinner.hide();
                console.error(err);
              });
          })
          .catch((err) => {
            this.spinner.hide();
            console.error(err);
          });
      } else if (this.checkbox.isMaritimeCrimeData) {
        this.spinner.show();

        html2canvas(g1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((charts1) => {
            html2canvas(g2, {
              allowTaint: true,
              useCORS: true,
            })
              .then((charts2) => {
                this.spinner.hide();
                this.genratePDF(charts1.toDataURL(), charts2.toDataURL(), "");
              })
              .catch((err) => {
                this.spinner.hide();
                console.log(err);
              });
          })
          .catch((err) => {
            this.spinner.hide();
            console.log(err);
          });
      } else if (this.checkbox.isIncidentSummaryMap) {
        this.spinner.show();
        html2canvas(m1, {
          allowTaint: true,
          useCORS: true,
        })
          .then((map1) => {
            this.spinner.hide();
            this.genratePDF("", "", map1.toDataURL());
          })
          .catch((err) => {
            this.spinner.hide();
            console.log(err);
          });
      }
    } else {
      this.genratePDF("", "", "");
    }
  }

  genratePDF(chartImg1, chartImg2, mapImage) {
    const title = "Incident Report";
    const name = this.data.incidentDetails.title;
    let summaryHTML = "";
    let graphImageHTML = "";
    let mapImageHTML = "";
    let tableHTML = "";

    let topTableHTML = "";
    let topdetailsHTML = "";
    let summary = "";
    let topTable =
      '<tr><th class="bText">Threat Type</th><th class="bText textCenter">Level </th></tr>';

    if (this.checkbox.isOverview) {
      summary =
        '<p><span class="bText">Type: </span> ' +
        this.data.incidentDetails.significant_tag +
        " </p>\n" +
        '<p><span class="bText">Date: </span> ' +
        this.data.incidentDetails.dtg_parts.day +
        "-" +
        this.data.incidentDetails.dtg_parts.month_desc +
        "-" +
        this.data.incidentDetails.dtg_parts.year +
        "</p>\n" +
        '<p><span class="bText">Location: </span> ' +
        this.util.getDMS(this.data.incidentDetails.lat, "lat") +
        ", " +
        this.util.getDMS(this.data.incidentDetails.lon, "long") +
        "</p>\n" +
        '<p><span class="bText">Maritime Region: </span> ' +
        this.data.incidentDetails.classification.dryad_area.join(", ") +
        " </p>\n" +
        '<p class="bText ">Narrative</p>\n' +
        '<p style="text-align: justify;">' +
        this.data.incidentDetails.notes.join(", ") +
        "</p>\n" +
        '<p class="bText">' +
        "Maritime Crime Data within " +
        this.range +
        " Nm" +
        "</p>\n";
    }
    summaryHTML =
      '<div class="pageDiv">\n' +
      '            <div class="pageContent">\n' +
      '                 <p class="bText fontSize18"> ' +
      title +
      "</p>" +
      '                 <p class="bText cFont">' +
      name +
      "</p>" +
      summary +
      "            </div>";

    if (this.checkbox.isMaritimeCrimeData) {
      const graphText =
        "Maritime Crime Data: " +
        this.util.getDate(this.data.startTime) +
        " to " +
        this.util.getDate(this.data.endTime);

      graphImageHTML =
        // '<div class="page_break"></div>\n' +
        '            <div class="pageDiv">\n' +
        '                <div class="pageContent">\n' +
        '                    <p class="bText fontSize18">' +
        graphText +
        "</p><br><br>\n" +
        '                    <img class="cGraph" src = "' +
        chartImg1 +
        '"/><br><br>\n' +
        '                    <img class="cGraph" src = "' +
        chartImg2 +
        '"/><br><br>\n' +
        "             </div>\n" +
        "            </div>";
    }

    if (this.checkbox.isIncidentSummaryMap) {
      mapImageHTML =
        // '<div class="page_break"></div>\n' +
        '            <div class="pageDiv">\n' +
        '                <div class="pageContent">\n' +
        '                    <p class="bText fontSize18">Incident Plot </p><br><br>\n' +
        '                    <img class="cMap" src = "' +
        mapImage +
        '"/>' +
        "                </div>\n" +
        "            </div>";
    }

    if (this.checkbox.isIncidentSummaryTable) {
      const tb = this.data.table;
      let table =
        "<tr>\n" +
        '<th class="bText">Time</th>\n' +
        '<th class="bText">Title</th>\n' +
        '<th class="bText">Type</th>\n' +
        '<th class="bText">Area</th>\n' +
        "</tr>";
      for (let x = 0; x < tb.length; x++) {
        table +=
          "<tr>\n" +
          "<td>" +
          this.util.getTime(tb[x].time) +
          "</td>\n" +
          "<td>" +
          tb[x].title +
          "</td>\n" +
          "<td>" +
          tb[x].significant_tag +
          "</td>\n" +
          "<td>" +
          tb[x].classification_permissioning +
          "</td>\n" +
          "</tr>";
      }

      tableHTML =
        '<div class="page_break"></div>' +
        '<div class="pageDiv">' +
        '<div class="pageContent">' +
        '<p class="bText fontSize18">Incident Summary Table:</p><br>' +
        '<table border="1" cellpadding="5" cellspacing="0" width="100%">' +
        table +
        "</table>" +
        "</div>" +
        "</div>";
    }

    let d = new Date();
    const body = {
      reportTitle: "Incident Report ",
      reportFor: this.data.incidentDetails.title,
      company: this.util.company,
      date:
        d.getDate() + "/" + Math.abs(d.getMonth() + 1) + "/" + d.getFullYear(),
      summaryHTML: summaryHTML,
      graphImageHTML: graphImageHTML,
      mapImageHTML: mapImageHTML,
      tableHTML: tableHTML,
    };

    /*                const bodyFileName = {
                    coverFileName: 'dryad_pdf_body.pdf',
                    bodyFileName: 'dryad1.pdf'
                };
                this.http.post(url.download,bodyFileName,{
                    responseType: 'arraybuffer',
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept', 'application/pdf')
                }).subscribe(response=>{
                    this.downLoadFile(response, "application/pdf")
                },error2 => {
                    // this.spinner.hide();
                    console.error(url.country+' error: ',error2)
                });*/
    setTimeout(() => this.spinner.show(), 100);

    this.http.post(url.cover, body).subscribe(
      (coverFileName) => {
        console.log("sucess", coverFileName);
        this.http.post(url.country, body).subscribe(
          (bodyFileName) => {
            console.log("sucess", bodyFileName);
            let fileNames = {
              coverFileName: coverFileName,
              bodyFileName: bodyFileName,
            };
            setTimeout(() => {
              this.http
                .post(url.download, fileNames, {
                  responseType: "arraybuffer",
                  headers: new HttpHeaders()
                    //.set('Content-Type', 'application/x-www-form-urlencoded')
                    .set("Accept", "application/pdf"),
                })
                .subscribe(
                  (response) => {
                    this.spinner.hide();
                    this.downLoadFile(response, "application/pdf");
                  },
                  (error2) => {
                    this.spinner.hide();
                    console.error("download error: ", error2);
                  }
                );
            }, 1000);
          },
          (error2) => {
            this.spinner.hide();
            console.error(url.country + " error: ", error2);
          }
        );
      },
      (err) => {
        this.spinner.hide();
        console.error("cover", err);
      }
    );
  }

  downLoadFile(data: any, type: string) {
    this.spinner.hide();
    let blob = new Blob([data], { type: type });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download =
      this.data.incidentDetails.title + this.util.getDateformate() + ".pdf";
    link.click();

    // const property = [];
    // property.push({
    //     property: "last_report_downloaded",
    //     value: this.data.incidentDetails.title + " at " + new Date().toISOString()
    // });
    // this.hubspotService.updateContact(user.email, property)
    this.hubspotService.postDownloadDetails(
      this.data.incidentDetails.title + " at " + new Date().toISOString()
    );
  }
}
