import {Component, NgZone, OnInit} from '@angular/core';
import {circle, circleMarker, icon, latLng, Map, marker, point, polyline, tileLayer} from 'leaflet';
import {LeafletDrawDirective,LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {MatDialog, MatDialogConfig, MatTableDataSource} from "@angular/material";
import {MapIncidentService} from "../service/map/map-incident.service";
import {UtilService} from "../service/util/util.service";
import leafletImage from 'leaflet-image';
import {Router} from "@angular/router";
import html2canvas from 'html2canvas';
import {IncidentReportService} from "../service/dialog/incident-report.service";
import {NgxSpinnerService} from "ngx-spinner";
import { NotificationsService } from 'angular2-notifications';
import {TraPopupComponent} from "./tra-popup/tra-popup.component";

declare let L;


@Component({
    selector: 'app-tra',
    templateUrl: './tra.component.html',
    styleUrls: ['./tra.component.scss']
})
export class TraComponent implements OnInit {

    testJson = {'features':[]};

    traDone = false;

    threatScore = "Low";

    portList =[]
    showWayPointPopup = false;
    waypointData = []
    displayedColumns = ['lat', 'lng', 'arrival', 'departure' , 'speed' , 'freeboard', 'view'];

    speedTra = 20;

    cargo = "";
    freeboardVessel = "";
    plannedSpeed = 0;
    waypoint = 0;
    freeboard = "";
    latTra = 0;
    lngTra = 0;
    arrival = "-";
    departure = "";

    vertexNumber = 0;
    vertexArray = [];

    showTransitPopup = false;
    showVesselPopup = true;

    vesselName = "";
    imo = "";
    mmsi= "";
    vesselType   = "";
    vesselFlag   = "";

    utcDate = new Date().toUTCString();

    latestNotification : any;

    notOptions = {
        'position':'middle'
    }

    streetMaps = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
        detectRetina: true,
        attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    });

    seaMarks = tileLayer('https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png', {
        attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    });

    darkMap = tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
        detectRetina: true,
        attribution: '&amp;copy; &lt;a href="https://www.openstreetmap.org/copyright"&gt;OpenStreetMap&lt;/a&gt; contributors'
    });

    layersControl = {
        baseLayers: {
            'Street Maps': this.streetMaps,
            'Sea Marks': this.seaMarks
        }
    };

    mapCanvas:any
    circle = circleMarker([ 46.78465227596462, -121.74141269177198 ], { radius: 50, color: '#ff0000',
        fillOpacity: 0.6,
        weight: 1})
    circle2 = circle([ 46.8523, -121.7603 ], { radius: 1000, color: '#ff0000',
        fillOpacity: 0.6,
        weight: 1});
    summit = marker([ 46.8523, -121.7603 ], {
        icon: icon({
            iconSize: [ 25, 41 ],
            iconAnchor: [ 13, 41 ],
            iconUrl: 'leaflet/marker-icon.png',
            shadowUrl: 'leaflet/marker-shadow.png'
        })
    });

    options = {
        layers: [ this.streetMaps,this.seaMarks],
        minZoom: 3,
        maxZoom: 10,
        zoomControl: false,
        drawControl: false,
        zoom: 3,
        center: latLng([20.632784250388028, -12.304687500000002])
    };

    drawOptions = {
        position: 'bottomleft',
        draw: {
            polygon: false,
            polyline: true,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false
        }
    };

    mLat:any = 0
    mLng:any = 0


    map: any

    drawControl = new L.Control.Draw({
        position : 'bottomleft',
        draw : {
            polygon: false,
            polyline: true,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false
        },
        edit : false
    });

    startNew = false;

    mapLoad(){
        this.map.addControl(this.drawControl);

        this.map.on(L.Draw.Event.DRAWVERTEX, (e) => {
            // let type = e.layerType,
            //     layer = e.layer;
            // if (type === 'polyline') {
            this.latTra = e.layers._layers[Object.keys(e.layers._layers)[this.vertexNumber]]._latlng.lat;
            this.lngTra = e.layers._layers[Object.keys(e.layers._layers)[this.vertexNumber]]._latlng.lng;
            if(this.vertexNumber != 0){
                this.showTransitPopup = true;
                let n = {lat:e.layers._layers[Object.keys(e.layers._layers)[this.vertexNumber]]._latlng.lat,lng:e.layers._layers[Object.keys(e.layers._layers)[this.vertexNumber]]._latlng.lng};
                this.vertexArray.push(n);
            } else{
                this.showTransitPopup = true;
                let n = {lat:e.layers._layers[Object.keys(e.layers._layers)[0]]._latlng.lat,lng:e.layers._layers[Object.keys(e.layers._layers)[0]]._latlng.lng};
                this.vertexArray.push(n);
            }
            this.vertexNumber++;
            this.waypoint++;

            // alert("heck");
            // }

        })


        this.map.on(L.Draw.Event.CREATED, (e) => {
            let type = e.layerType,
                layer = e.layer;

            if (type === 'polyline') {
                this.map.removeControl(this.drawControl);
                this.startNew = true;
                html2canvas(document.getElementById('map1'), {
                    allowTaint: true,
                    useCORS: true,
                }).then((canvas) => {
                    this.util.log('html2canvas: ',canvas.toDataURL())
                    this.mapCanvas = canvas.toDataURL();
                    this.showWayPointPopup = true;
                    this.traDone = true;

                    let canvasContents = canvas.toDataURL();

                    let data = {
                        vesselName: this.vesselName,
                        imo: this.imo,
                        mmsi:this.mmsi,
                        plannedSpeed:this.plannedSpeed,
                        waypoints: this.waypointData,
                        threatScore: this.threatScore,
                        mapCanvas: canvasContents,
                        incidentTableHTML:this.getIncidentTableHTML()

                    }
                    this.savedTras.push(data);
                    localStorage.setItem('savedTra', JSON.stringify(this.savedTras));
                    this.incidentReportService.setMapCanvas(canvas)
                }).catch(err=>{this.util.error('html2canvas err:',err)});
                // let points = '';
                // for(let l in this.vertexArray){
                //     let p = 'point=' + this.vertexArray[l].lat + ',' + this.vertexArray[l].lng + "&";
                //     points += p;
                // }
                // points = points.substring(0, points.length - 1);
                // this.ngZone.run(()=>{
                //     this.spinner.show();
                // });
                // this.mapIncident.getTra(points,0).subscribe(value => {
                //     this.ngZone.run(()=>{
                //         this.spinner.hide();
                //     });
                //     this.testJson = JSON.parse(value);
                //     this.renderTra();
                // });
                e.layer.remove()
            }
            // Do whatever else you need to. (save to db; add to map etc)
            this.map.removeLayer(layer);
        });

        this.map.on('mousemove', event => {
            // this.util.log('hover',event.latlng)

            this.ngZone.run(() => {
                this.mLat = event.latlng.lat;
                this.mLng = event.latlng.lng;
            })
        })
    }

    onMapReady(map: Map) {
        this.map = map
        L.control.zoom({
            position:'bottomleft'
        }).addTo(map);

        this.mapLoad();
    }


    savedTras: any;

    constructor(public mapIncident:MapIncidentService,
                public matDialog:MatDialog,
                public util:UtilService,
                public router: Router,
                public spinner: NgxSpinnerService,
                public incidentReportService:IncidentReportService,
                public ngZone:NgZone,
                public _service: NotificationsService) {
        const date = new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)

        if(localStorage.getItem('savedTra')){
            this.savedTras = JSON.parse(localStorage.getItem('savedTra'));
        } else{
            this.savedTras = [];
        }

    }

    ngOnInit() {
        if(!localStorage.getItem('currentUser')){
            this.router.navigate(['/login/']);
            return;
        }
        this.departure = new Date().toISOString().slice(0, 16);
        // this.spinner.show();
        //
        // setTimeout(() => {
        //     /** spinner ends after 5 seconds */
        //     this.spinner.hide();
        // }, 5000);
    }

    table = '<tr>\n' +
        '<th>Time</th>\n' +
        '<th>Title</th>\n' +
        '<th>Significant Type</th>\n' +
        '<th>Tags</th>\n' +
        '<th>Type</th>\n' +
        '</tr>'

    renderTra() {
        var percentColors = [
            { pct: 0.0, color: { r: 0x00, g: 0xff, b: 0 } },
            { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
            { pct: 1.0, color: { r: 0xff, g: 0x00, b: 0 } } ];

        var getColorForPercentage = function(pct) {
            for (var i = 1; i < percentColors.length - 1; i++) {
                if (pct < percentColors[i].pct) {
                    break;
                }
            }
            var lower = percentColors[i - 1];
            var upper = percentColors[i];
            var range = upper.pct - lower.pct;
            var rangePct = (pct - lower.pct) / range;
            var pctLower = 1 - rangePct;
            var pctUpper = rangePct;
            var color = {
                r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
                g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
                b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
            };
            return 'rgb(' + [color.r, color.g, color.b].join(',') + ')';
            // or output as hex if preferred
        };

        const colors = ['green', 'red', 'blue', 'yellow'];
        let i = 0;
        for (const geom of this.testJson.features) {
            if (geom.hasOwnProperty('geometry')) {
                // const col = colors[i % 4];
                if (geom.geometry.type === 'LineString') {
                    let pct = 0;
                    let total = 0;
                    if (geom.properties.hasOwnProperty('firedUpon')) {
                        total += geom.properties.firedUpon;
                    }
                    if (geom.properties.hasOwnProperty('hijack')) {
                        total += geom.properties.hijack;
                    }
                    if (geom.properties.hasOwnProperty('attack')) {
                        total += geom.properties.attack;
                    }
                    if (geom.properties.hasOwnProperty('threat')) {
                        total += geom.properties.threat;
                    }
                    // if (geom.properties.hasOwnProperty('attempted')) {
                    //     total += geom.properties.threat;
                    // }
                    if (geom.properties.hasOwnProperty('robbery')) {
                        total += geom.properties.robbery;
                    }
                    if (geom.properties.hasOwnProperty('approach')) {
                        total += geom.properties.approach;
                    }
                    if (geom.properties.hasOwnProperty('boarding')) {
                        total += geom.properties.boarding;
                    }
                    if (geom.properties.hasOwnProperty('kidnapping')) {
                        total += geom.properties.kidnapping;
                    }
                    pct = (total/60)*100;

                    const col = getColorForPercentage(pct);
                    const latlngs = geom.geometry.coordinates;
                    const polyLine = L.polyline(latlngs, {color: col}).addTo(this.map);
// zoom the map to the polyline
//           this.map.fitBounds(polyLine.getBounds());
                }
                if (geom.geometry.type === 'Polygon') {
                    const latlngs = geom.geometry.coordinates;
                    const polygon = L.polygon(latlngs, {color: 'blue'}).addTo(this.map);
// zoom the map to the polygon
//           this.map.fitBounds(polygon.getBounds());
                }
                if (geom.geometry.type === 'Point') {

                    console.log('geom: ',JSON.stringify(geom))

                    this.table += '<tr>\n' +
                        '<th>'+this.util.getTime(geom.properties.dtg)+'</th>\n' +
                        '<th>'+geom.properties.title+'</th>\n' +
                        '<th>'+geom.properties.significant_tag+'</th>\n' +
                        '<th>'+geom.properties.tags.join(', ')+'</th>\n' +
                        '<th>'+geom.properties.type+'</th>\n' +
                        '</tr>'


                    if(geom.properties.score.value > 3 && geom.properties.score.value < 8 && this.threatScore == "Low"){
                        this.threatScore = "Medium";
                    } else if(geom.properties.score.value >= 8 && (this.threatScore == "Low" || this.threatScore == "Medium")){
                        this.threatScore = "High";
                    }
                    L.circle(geom.geometry.coordinates, {radius: 2000, color: 'green'}).addTo(this.map);
                }
            }
            i++;
        }
    }


    saveTra(){
        let waypoint = {};
        waypoint['waypoint'] = this.waypoint;
        waypoint['lat'] = this.latTra;
        waypoint['lng'] = this.lngTra;
        waypoint['arrival'] = this.arrival;
        waypoint['departure'] = this.departure;
        waypoint['speed'] = this.speedTra;
        waypoint['freeboard'] = this.freeboard;
        if(this.vertexNumber > 1){
            let points = '';
            points += 'point=' + this.vertexArray[this.vertexNumber-1].lat + ',' + this.vertexArray[this.vertexNumber-1].lng + "&";
            points += 'point=' + this.vertexArray[this.vertexNumber-2].lat + ',' + this.vertexArray[this.vertexNumber-2].lng;

            this.mapIncident.getTra(points,this.speedTra,this.arrival).subscribe(value => {
                this.ngZone.run(()=>{
                    this.spinner.hide();
                });
                this.testJson = JSON.parse(value);
                waypoint['segments'] = this.testJson;
                this.waypointData.push(waypoint);
                this.renderTra();
            });
        } else{
            this.waypointData.push(waypoint);
        }
        this.arrival = this.departure;
        this.showTransitPopup = false;
    }


    saveVessel(){
        this.showVesselPopup = false;
    }

    createdNotification(event){

    }

    destroyedNotification(event){

    }

    convertToArray(obj){
        var result = Object.keys(obj).map(function(key) {
            return key
        });
        return result
    }

    roundIt(n){
        return Math.round(n);
    }


    getIncidentTableHTML(){
        return '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
            this.table+
            '</table></div></div>'

    }

    onSeeData(){

        const matDialogConfig = new MatDialogConfig()
        matDialogConfig.height = "70%";
        matDialogConfig.width = "65%";
        matDialogConfig.data = {
            vesselName: this.vesselName,
            imo: this.imo,
            mmsi:this.mmsi,
            plannedSpeed:this.plannedSpeed,
            waypoints: this.waypointData,
            threatScore: this.threatScore,
            mapCanvas: this.mapCanvas,
            incidentTableHTML:this.getIncidentTableHTML()
        }

        const dialogRef = this.matDialog.open(TraPopupComponent, matDialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            this.util.log('Dialog result: ',result)
        });

    }

    startNewTra(){
        this.testJson = {'features':[]};

        this.traDone = false;

        this.threatScore = "Low";

        this.portList =[]
        this.showWayPointPopup = false;
        this.waypointData = []
        this.displayedColumns = ['lat', 'lng', 'arrival', 'departure' , 'speed' , 'freeboard', 'view'];

        this.speedTra = 20;

        this.cargo = "";
        this.freeboardVessel = "";
        this.plannedSpeed = 0;
        this.waypoint = 0;
        this.freeboard = "";
        this.latTra = 0;
        this.lngTra = 0;
        this.arrival = "-";
        this.departure = "";

        this.vertexNumber = 0;
        this.vertexArray = [];

        this.showTransitPopup = false;
        this.showVesselPopup = true;

        this.vesselName = "";
        this.imo = "";
        this.mmsi= "";
        this.vesselType   = "";
        this.vesselFlag   = "";

        this.startNew = false;

        this.map.eachLayer((layer)=> {
            this.map.removeLayer(layer);
        });

        this.departure = new Date().toISOString().slice(0, 16);
        this.map.addLayer(this.streetMaps);
        this.mapLoad();
    }

    viewTRA(tra){
        this.startNewTra();

        this.traDone = true;

        this.threatScore = tra.threatScore;

        this.cargo = tra.cargo;
        this.freeboardVessel = tra.freeboardVessel;
        this.plannedSpeed = tra.plannedSpeed;

        this.showTransitPopup = false;
        this.showVesselPopup = false;

        this.vesselName = tra.vesselName;
        this.imo = tra.imo;
        this.vesselType   = tra.vesselType;
        this.vesselFlag   = tra.vesselFlag;

        this.waypointData = tra.waypoints;

        this.mapCanvas = tra.mapCanvas;

        this.startNew = true;

        for(let i in tra.waypoints){
            if(parseInt(i) > 0){
                this.testJson = tra.waypoints[i]['segments'];
                this.renderTra()
            }
        }
    }

}

class MatchPhrase{
    type:string=''
}

