import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {url} from "../utils/const";
import {Router} from "@angular/router";
import {UtilService} from "../service/util/util.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private utilService: UtilService,
                private router: Router) {
    }

    private excludedURLs = [];

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url == url.index) {
            return next.handle(request).pipe(
                tap((t: any) => {
                    const body = t.body ? JSON.parse(t.body) : null;
                    // console.log("TokenInterceptor tap:: ", body);
                    if (body && body.code && (body.code == "credentials_required" || body.code == "invalid_token")) {
                        localStorage.clear();
                        this.utilService.logout();
                        this.router.navigate(['/']);
                    }
                }));
        }
        return next.handle(request)

    }
}
