import {Compiler, Component} from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dryad-leaflet';
  /*
  path : src/assets/dryad-areas.json
  { "type": "Feature", "properties": { "id": "gog-soatome-nigerian", "title": "GoG Sao Tome & Nigerian", "type": "GoG", "poly_type": 4, "Title_1": "Nigeria & Sao Tome Joint Development Zone" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 5.0, 2.0 ], [ 8.333814492923198, 1.98731231095788 ], [ 8.330517890111276, -0.754241480527751 ], [ 4.998703558827411, -0.753344439217914 ], [ 5.0, 2.0 ] ] ] ] } },

  *
  * */
  constructor(private compiler: Compiler) {
    this.compiler.clearCache();
  } // Compiler
}
