import { HubspotService } from './../../service/hubspot.service';
import {AfterViewInit, Component, Inject, NgZone, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {Color, Label, SingleDataSet} from "ng2-charts";
import {
    MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort,
    MatTableDataSource
} from "@angular/material";

import {canvas, circle, circleMarker, icon, latLng, Map, marker, tileLayer} from "leaflet";

declare let L;
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf';
import {UtilService} from "../../service/util/util.service";
import {NgxSpinnerService} from "ngx-spinner";
import {MapIncidentService} from "../../service/map/map-incident.service";
import {PortGraphDialogModalComponent} from "../port-graph-dialog-modal/port-graph-dialog-modal.component";
import {code, url} from "../../utils/const";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {__values} from "tslib";

declare var $: any;

const Tag = 'CountryPopupComponent '
const Tagerr = 'LoginComponent Error '

@Component({
    selector: 'app-country-popup',
    templateUrl: './country-popup.component.html',
    styleUrls: ['./country-popup.component.scss']
})
export class CountryPopupComponent implements OnInit, AfterViewInit {

    isPdfOption = false;
    isDateRangeVisible = false;

    checkbox = {
        isOverview: false,
        isRiskSummary: false,
        isRiskCategories: false,
        isMaritimeCrimeData: false,
        isIncidentSummaryMap: false,
        isIncidentSummaryTable: true
    }

    streetMaps = tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}', {
        detectRetina: true,
        attribution: 'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors'
    });


    options = {
        layers: [this.streetMaps],
        minZoom: 3,
        maxZoom: 10,
        zoomControl: true,
        drawControl: false,
        zoom: 5,
        attributionControl: false,
        scrollWheelZoom: false,
        center: latLng([0, 0])
    };

    drawOptions = {
        position: 'bottomleft',
        draw: {
            polygon: true,
            polyline: false,
            rectangle: false,
            marker: false,
            circle: false,
            circlemarker: false
        }
    };

    /**********************************************/

    lineChartData: ChartDataSets[] = [
        {data: [85, 72, 78, 75, 77, 75], label: 'Incident Occurred'},
    ];

    lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June'];

    lineChartOptions = {
        responsive: true,
    };

    lineChartColors: Color[] = [
        {
            borderColor: 'black',
            backgroundColor: 'rgba(255,255,0,0.28)',
        },
    ];

    lineChartLegend = true;
    lineChartPlugins = [];
    lineChartType = 'line';

    //Pie Chart
    public pieChartOptions: ChartOptions = {
        responsive: true,
    };
    // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
    public pieChartLabels: Label[] = ['Robbery', 'Boarding', 'FiredUpon', 'Approach', 'Kidnap', 'Attak',
        'Attempted', 'Hijack'];

    public pieChartData: SingleDataSet = [300, 50, 100, 200, 0, 0, 0, 90];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [];


    //horizontal bar graph

    public barChartOptions: ChartOptions = {
        responsive: true
    };
    public barChartType: ChartType = 'horizontalBar';
    public barChartLegend = true;

    public barChartData: ChartDataSets[] = [
        {data: [1, 2, 3], label: 'Kidnap', stack: 'a'},
        {data: [1, 2, 3], label: 'Hijack', stack: 'a'},
        {data: [1, 2, 3], label: 'Robbery', stack: 'a'},
        {data: [1, 2, 3], label: 'Attack', stack: 'a'},
    ];
    public barChartLabels: string[] = ['P', 'R', 'B'];


    ///vertical bar

    public barChartOptions1: ChartOptions = {
        responsive: true,
    };
    public barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartType1: ChartType = 'bar';
    public barChartLegend1 = true;
    public barChartPlugins1 = [];

    public barChartData1: ChartDataSets[] = [
        {data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a'},
        {data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', stack: 'a'}
    ];


    /***************************************/

    displayedColumns = ['type', 'time', 'title', 'significant_tag', 'classification_permissioning', 'star'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


    @ViewChild(MatSort, {static: true}) sort: MatSort;

    // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
    dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

    // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    isDateTypeChange: boolean = true

    startDate: any
    endDate: any
    timeType: any = 'years'
    timeCount: number = 2
    topdetailsHTML: string = ''
    summary: any = []
    countryCode = 'NG';
    map: any
    markers: any = []
    isFullScreen: boolean = false
    public news: any = []
    public newsVenues: any = []

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public utils: UtilService,
                private ngZone: NgZone,
                private mapIncident: MapIncidentService,
                private dialogRef: MatDialogRef<PortGraphDialogModalComponent>,
                private matDialog: MatDialog,
                public util: UtilService,
                private hubspotService: HubspotService,
                private http: HttpClient,
                private spinner: NgxSpinnerService) {
        // this.summary = this.utils.convertToArrayObj(this.data.portResponse.risk)
        this.fetchCountryNews(this.data.portResponse.title);
        this.fetchCountryNewsVenues(this.data.portResponse.title);
        this.hubspotService.postLastViewItem("Country: " + this.data.portResponse.title);


        if (this.data.portResponse.risk) {
            this.summary = this.utils.convertToArrayObj(this.data.portResponse.risk)
            for (let x of this.summary) {

                if (x.key === 'uscglist'
                    || x.key === 'ispscompliant'
                    || x.key === 'ispslevel'
                    || x.key === 'jwrcarea') {

                    this.topdetailsHTML += '<p><b>' +
                        this.utils.capitalizeFirstLetterXC(x.key) + ': ' + this.utils.getIconX(x.value.indicator, x.key)
                        + '</b></p>'
                }
            }
        }
        const date = new Date().setUTCFullYear(new Date().getUTCFullYear() - 2)
        // const start = new Date(date)
        const start = new Date(this.data.startTime)
        let sm = start.getMonth() + 1
        let sMonth = sm >= 10 ? sm : '0' + sm

        let sd = start.getDate()
        let sDate = sd >= 10 ? sd : '0' + sd

        this.startDate = start.getFullYear() + '-' + sMonth + '-' + sDate

        const end = new Date(this.data.endTime)

        let em = end.getMonth() + 1
        let eMonth = em >= 10 ? em : '0' + em

        let ed = end.getDate()
        let eDate = ed >= 10 ? ed : '0' + ed

        this.endDate = end.getFullYear() + '-' + eMonth + '-' + eDate;


    }

    ngOnInit() {
        $('[data-toggle="tooltip"]').tooltip();
        this.data.dataSource.paginator = this.paginator;
        this.data.dataSource.sort = this.sort;
        this.countryCode = this.data.portResponse.country['code'];
        // this.dataSource.paginator = this.paginator;
    }

    onMapReady(map: Map) {
        this.ngZone.run(() => {
            map.panTo(new L.LatLng(this.data.mLat, this.data.mLng));
            this.map = map
            this.putMarker()
        })

    }

    getOverview(summary) {
        return summary.filter((text: any) => {
            if (text.key === 'recommendations') {
                return text.value.narrative
            } else {
                return (text.key.toLowerCase() == 'overview');
            }

        });
    }

    getCapFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getSummary(summary) {
        return summary.filter((text: any) => {
            return (text.key === 'maritime' || text.key === 'ashore');
        });
    }

    dateRangeVisible(bool) {
        this.isDateRangeVisible = bool;
    }

    createFileOption() {
        this.isPdfOption = !this.isPdfOption;
    }


    createFile() {
        this.spinner.show();
        let div = '';
        for (let i = 0; i < this.summary.length; i++) {
            if (this.summary[i].key === 'maritime' || this.summary[i].key === 'ashore' || this.summary[i].key === 'recommendations') {
                let desc = '';

                console.log(this.summary[i]);
                for (let para of this.summary[i].value.narrative ? this.summary[i].value.narrative : []) {
                    desc += '<p>' + para + '</p>';
                }
                div += '<p class="bText textRighttt fontSize18" style="font-family: Arial, Helvetica, sans-serif;"> '
                    + this.util.capitalizeFirstLetter(this.summary[i].key)
                    + this.utils.getRiskIconX(this.summary[i].value.indicator, this.summary[i].key) + '</p>'
                    + desc + '<br>';
            }
        }

        let summaryHTML2 = div;


        let table = '<tr>\n' +
            '<th class="bText">Time</th>\n' +
            '<th class="bText">Title</th>\n' +
            '<th class="bText">Type</th>\n' +
            '<th class="bText">Area</th>\n' +
            '</tr>';

        let tb = this.data.table
        console.log('tb:\n', JSON.stringify(tb))
        for (let x = 0; x < tb.length; x++) {
            table += '<tr>\n' +
                '<th>' + this.utils.getTime(tb[x].time) + '</th>\n' +
                '<th>' + tb[x].title + '</th>\n' +
                '<th>' + tb[x].significant_tag + '</th>\n' +
                '<th>' + tb[x].classification_permissioning + '</th>\n' +
                '</tr>'
        }

        let tableHTML = ''

        tableHTML = '<div class="page_break"></div><div class="pageDiv">\n' +
            '<div class="pageContent">\n' +
            '<p class="bText fontSize18">Incident Summary Table:</p><br>' +
            '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
            table +
            '</table></div></div>'


        let x1 = document.getElementById('cGraph');
        let x2 = document.getElementById('chart2');
        let x3 = document.getElementById('chart3');
        let x4 = document.getElementById('cMap');

        let g1 = document.getElementById('cGraph1');
        let g2 = document.getElementById('cGraph2');
        let g3 = document.getElementById('cGraph3');

        html2canvas(g1, {
            allowTaint: true,
            useCORS: true,
        }).then(charts1 => {
            html2canvas(g2, {
                allowTaint: true,
                useCORS: true,
            }).then(charts2 => {
                html2canvas(g3, {
                    allowTaint: true,
                    useCORS: true,
                }).then(charts3 => {
                    html2canvas(x4, {
                        allowTaint: true,
                        useCORS: true,
                    }).then(map1 => {
                        this.getPDF(summaryHTML2, tableHTML, charts1.toDataURL(), charts2.toDataURL(), charts3.toDataURL(), map1.toDataURL())
                    }).catch(err => console.log(err))
                }).catch(err => console.log(err))
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))
    }

    getPDF(summaryHTML, tableHTML, charts1, charts2, charts3, map1) {

        let topTableHTML = ''
        let topdetailsHTML = ''
        for (let x of this.summary) {
            if (this.utils.capitalizeFirstLetter(x.key) != 'Port Overview' &&
                this.utils.capitalizeFirstLetter(x.key) != 'Recommendations') {
                if (x.key === 'maritime'
                    || x.key === 'ashore'
                ) {
                    console.log(x.value.indicator, x.key)
                    topTableHTML += '<tr>' +
                        '<td>' + this.utils.capitalizeFirstLetterXC(x.key) + '</td>' +
                        // this.utils.getHTML(x.value.indicator) +
                        '<td style="text-align: center">' + this.utils.getRiskIcon(x.value.indicator, x.key) + '</td>'
                    '</tr>'

                }
                if (x.key === 'uscglist'
                    || x.key === 'ispscompliant'
                    || x.key === 'ispslevel'
                    || x.key === 'jwrcarea') {

                    // summaryHTML2 += '<p class="bText textRighttt"><strong style="margin-right: 10px">Associated Countries: </strong> '+ this.data.portResponse.country.description.join(', ')+'</p></br>'
                    // summaryHTML2 += '<p class="bText textRighttt"><strong style="margin-right: 10px">Associated Regions: </strong> '+ this.data.portResponse.geo_desc.join(', ')+'</p></br>'

                    topdetailsHTML += '<p class="bText">' +
                        this.utils.capitalizeFirstLetterXC(x.key) +
                        ': ' +
                        this.utils.getRiskIcon(x.value.indicator, x.key)
                        + '</p></br>'
                }


            }
        }

        let d = new Date()
        this.http.post(url.country, {
            title: 'Country Threat Assessment',
            name: this.data.portResponse.title,
            topdetailsHTML: topdetailsHTML,
            summaryHTML: summaryHTML,
            chartImg1: charts1,
            chartImg2: charts2,
            chartImg3: charts3,
            mapImg: map1,
            tableHTML: tableHTML,
            topTableHTML: topTableHTML,
            company: this.utils.company,
            graphText: 'Maritime Crime Data: ' + this.utils.getDate(this.data.startTime) + ' to ' + this.utils.getDate(this.data.endTime),
            date: d.getDate() + '/' + Math.abs(d.getMonth() + 1) + '/' + d.getFullYear()
        }, {
            responseType: 'arraybuffer',
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/pdf')
        }).subscribe(response => {

            this.downLoadFile(response, "application/pdf")

        }, error2 => {
            console.log('error: ', error2)
        })
    }

    putMarker() {
        this.ngZone.run(() => {


            for (let marker of this.markers) {
                this.map.removeLayer(marker);
            }
            this.markers = []

            for (let marker of this.data.markers) {
                let circlecolor = 'white';
                let symbol = '';
                switch (marker.significant_tag) {
                    case "Hijack":
                        circlecolor = 'f8433f';
                        symbol = 'H';
                        break;
                    case "Kidnap":
                        circlecolor = 'fd9b3e';
                        symbol = 'K';
                        break;
                    case "Kidnapping":
                        circlecolor = 'fd9b3e';
                        symbol = 'K';
                        break;
                    case "Attack":
                        circlecolor = 'c27ba0';
                        symbol = 'A';
                        break;
                    case "Fired Upon":
                        circlecolor = '8e7cc3';
                        symbol = 'F';
                        break;
                    case "Boarding":
                        circlecolor = '6d9eeb';
                        symbol = 'B';
                        break;
                    case "Robbery":
                        circlecolor = 'b7b7b7';
                        symbol = 'R';
                        break;
                    case "Attempted":
                        circlecolor = '93c47d';
                        symbol = 'AT';
                        break;
                    case "Approach":
                        circlecolor = '2f6318';
                        symbol = 'AP';
                        break;
                    default:
                        circlecolor = 'white';
                }
                let x = new L.marker(marker.location, {
                    icon: icon({
                        iconSize: [20, 25],
                        iconAnchor: [0, 0],
                        iconUrl: 'https://assets.mapquestapi.com/icon/v2/marker-sm-' + circlecolor + '-' + circlecolor + '-' + symbol + '@2x.png'
                    })
                }).bindPopup("<h4> " + marker.title + "" + "</h4>" +
                    "<p><b>Latitude: </b>" + this.util.getDMS(marker.location[0], 'lat') + "</p>" +
                    "<p><b>Longitude: </b>" + this.util.getDMS(marker.location[1], 'long') + "</p>" +
                    "<p><b>Date : </b>" + marker.dtg_parts + "</p>").on('mouseover', (d) => {
                    x.openPopup()
                }).addTo(this.map)

                this.markers.push(x)
            }


        })
    }


    onResize() {
        this.isFullScreen = !this.isFullScreen
        if (this.isFullScreen) {
            $('.cdk-overlay-pane').css('max-width', '100vw')
            $('.cdk-overlay-pane').css('height', '100%')
            $('.cdk-overlay-pane').css('width', '100%')
        } else {
            $('.cdk-overlay-pane').css('height', '70%')
            $('.cdk-overlay-pane').css('width', '65%')
        }
    }

    onChange() {
        this.isDateTypeChange = !this.isDateTypeChange
        // console.log(this.startDate)
    }

    onApply() {

        if (this.isDateTypeChange) {
            this.data.endTime = new Date(this.endDate)
            this.data.startTime = new Date(this.startDate)
            console.log(this.data.endTime, this.data.startTime)
            this.onCountry()

        } else {
            const d = new Date()
            var x
            switch (this.timeType) {
                case 'minutes':
                    x = d.setMinutes(d.getMinutes() - this.timeCount)
                    break;
                case 'hours':
                    x = d.setHours(d.getHours() - this.timeCount)
                    break;
                case 'days':
                    x = d.setDate(d.getDate() - this.timeCount)
                    break;
                case 'months':
                    x = d.setMonth(d.getMonth() - this.timeCount)
                    break;
                case 'years':
                    x = d.setUTCFullYear(d.getFullYear() - this.timeCount)
                    // this.startTime=new Date(x)
                    break;
            }
            this.data.endTime = new Date()
            this.data.startTime = new Date(x)

            console.log(this.data.endTime, this.data.startTime)
            this.onCountry()
        }
    }

    onCountry() {
        let markers = []
        this.spinner.show()
        this.mapIncident.getPopDetails(code.COUNTRY, false, 1, this.countryCode, this.data.startTime, this.data.endTime).subscribe(value => {
            var port = {}
            port = JSON.parse(value)

            this.mapIncident.getPopDetails(code.COUNTRY, true, 1000, this.countryCode, this.data.startTime, this.data.endTime).subscribe(val => {


                let types = {}
                let dataSourcePop = []

                let significantTag: any = {}
                let dateSignificantTag: any = {}

                let locationChart: any = {}

                let portList = []
                let incidentList = []
                let migrationList = []

                let portObject = {}

                if (val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            // if(data['_source']['title'])
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                        modal.dtg_parts = data['_source']['dtg_parts'] ? data['_source']['dtg_parts']['month_desc'] + ', ' + data['_source']['dtg_parts']['day'] + ' ' + data['_source']['dtg_parts']['year'] : ''
                        modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false
                        modal.country = data['_source']['country']['description'] ? data['_source']['country']['description'] : ''
                        modal.code = data['_source']['country']['code'] ? data['_source']['country']['code'] : ''
                        markers.push({
                            location: [modal.lat, modal.lon],
                            title: modal.title,
                            significant_tag: modal.significant_tag,
                            id: modal.id,
                            dtg_parts: modal.dtg_parts
                        });

                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.utils.convertToArray(dateSignificantTag);
                    var label2 = this.utils.convertToArray(significantTag);
                    var label3 = this.utils.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[] = [];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[] = [];

                    for (let c of label2) {
                    }


                    for (let x of label2) {
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1 = []
                        for (let y of label1) {
                            data1.push(dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0);
                        }
                        barChartData1.push({
                            data: data1,
                            label: x,
                            stack: 'a'
                        })

                        let data3 = []
                        for (let y of label3) {
                            data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                        }
                        barChartData3.push({
                            data: data3,
                            label: x,
                            stack: 'a'
                        })
                    }


                    let chart1 = {
                        label: label1,
                        data: barChartData1
                    };
                    let chart2 = {
                        label: label2,
                        data: barChartData2
                    };
                    let chart3 = {
                        label: label3,
                        data: barChartData3
                    };


                    /*********************************************************/
                    // const matDialogConfig = new MatDialogConfig()
                    // matDialogConfig.height = "80%";
                    // matDialogConfig.width = "75%";
                    const dataSource = dataSourcePop.filter(val => {
                        return val.published
                    })

                    this.data = {
                        markers: markers,
                        startTime: this.data.startTime,
                        endTime: this.data.endTime,
                        dataSource: new MatTableDataSource<PeriodicElement>(dataSource),
                        table: dataSource,
                        chart1: chart1,
                        chart2: chart2,
                        chart3: chart3,
                        portResponse: port['hits']["hits"][0]['_source']
                    }
                    this.data.dataSource.paginator = this.paginator;
                    this.spinner.hide()
                    /*


                                            const dialogRef = this.matDialog.open(PortPopupComponent,matDialogConfig);
                                            dialogRef.afterClosed().subscribe(result => {
                                                this.util.log('Dialog result: ',result)
                                            });*/

                    /************************************************************/
                }

            }, err2 => this.util.error('err', err2))

        }, err => this.util.error('err: ', err))
    }

    onViewMore(element) {
        this.spinner.show()

        let significantTag = {}
        let dateSignificantTag = {}
        let locationChart = {}

        let types = {}

        let dataSourcePop = []
        let portList = []
        let incidentList = []
        let migrationList = []


        let portObject = {}
        this.mapIncident.getIncident(element.id).subscribe(value => {
            var incidentDataJump = JSON.parse(value)
            var x = incidentDataJump.hits.hits[0]._source
            var lat = 0
            var lon = 0

            if (x.lat) {
                lat = x.lat
            }
            if (x.lon) {
                lat = x.lon
            }
            var square = this.util.getBoundingCoords(lat, lon, 2000)

            let n1 = {lat: square.left.latitude, lon: square.left.longitude};
            let n2 = {lat: square.top.latitude, lon: square.top.longitude};
            let n3 = {lat: square.right.latitude, lon: square.right.longitude};
            let n4 = {lat: square.bottom.latitude, lon: square.bottom.longitude};

            let poly = []
            poly.push(n1);
            poly.push(n2);
            poly.push(n3);
            poly.push(n4);
            const filter = [{"match_phrase": {"type": "incident"}}, {"match_phrase": {"type": "migration"}}, {"match_phrase": {"type": "country"}}, {"match_phrase": {"type": "dryad"}}]
            const sub = this.mapIncident.getIncidentsDetailsData(this.map.getBounds(), this.data.startTime, this.data.endTime, '', filter, poly, 4).subscribe(val => {
                if (val) {
                    var hit = {}
                    hit = JSON.parse(val)
                    for (let data of hit['hits']['hits']) {


                        const x = data['_source']['type']
                        if (x.toLowerCase() == 'port') {
                            const obj = {}
                            obj['value'] = data['_source']['title']
                            obj['status'] = true
                            portList.push(obj)
                        }


                        if (x.toLowerCase() == 'migration') {
                            migrationList.push(data['_source']['title'])
                        }

                        types[x] = true

                        let modal = new IncidentTableModal()
                        modal.title = data['_source']['title'] ? data['_source']['title'] : ''
                        modal.time = data['_source']['dtg'] ? data['_source']['dtg'] : ''
                        modal.classification_permissioning = data['_source']['classification']['permissioning'] ? Array.isArray(data['_source']['classification']['permissioning']) ? data['_source']['classification']['permissioning'].join() : data['_source']['classification']['permissioning'] : '                 '
                        modal.tags = data['_source']['tags'] ? data['_source']['tags'].join() : '';
                        modal.significant_tag = data['_source']['significant_tag'] ? data['_source']['significant_tag'] : 'unknown'
                        modal.contributor = data['_source']['contributor'] ? data['_source']['contributor'] : '              '
                        modal.id = data['_source']['id'] ? data['_source']['id'] : ''
                        modal.lat = data['_source']['lat'] ? data['_source']['lat'] : 0
                        modal.lon = data['_source']['lon'] ? data['_source']['lon'] : 0
                        modal.type = data['_source']['type'] ? data['_source']['type'] : ''
                        modal.published = data['_source']['published'] ? data['_source']['published'] : false


                        // markers.push([modal.lat,modal.lon])


                        let date = 'unknown'

                        if (data['_source']['dtg_parts']) {
                            let month = data['_source']['dtg_parts']['month_desc'] ? data['_source']['dtg_parts']['month_desc'] : ''
                            let year = data['_source']['dtg_parts']['year'] ? data['_source']['dtg_parts']['year'] : 'unknown'
                            date = month + ' ' + year
                        }

                        let l = data['_source']['classification']['permissioning']
                        if (l) {
                            if (Array.isArray(l)) {

                                for (let a of l) {
                                    locationChart[a] = locationChart[a] ? locationChart[a] : {};
                                    locationChart[a][modal.significant_tag] = locationChart[a][modal.significant_tag] ? locationChart[a][modal.significant_tag] + 1 : 1;

                                }

                            } else {
                                locationChart[l] = locationChart[l] ? locationChart[l] : {};
                                locationChart[l][modal.significant_tag] = locationChart[l][modal.significant_tag] ? locationChart[l][modal.significant_tag] + 1 : 1;
                            }
                        } else {
                            locationChart['unknown'] = locationChart['unknown'] ? locationChart['unknown'] : {};
                            locationChart['unknown'][modal.significant_tag] = locationChart['unknown'][modal.significant_tag] ? locationChart['unknown'][modal.significant_tag] + 1 : 1;
                        }


                        dateSignificantTag[date] = dateSignificantTag[date] ? dateSignificantTag[date] : {}
                        dateSignificantTag[date][modal.significant_tag] = dateSignificantTag[date][modal.significant_tag] ? dateSignificantTag[date][modal.significant_tag] + 1 : 1;

                        significantTag[modal.significant_tag] = significantTag[modal.significant_tag] ? significantTag[modal.significant_tag] + 1 : 1;

                        dataSourcePop.push(modal)
                    }

                    /***********************************************************/

                    var label1 = this.utils.convertToArray(dateSignificantTag);
                    var label2 = this.utils.convertToArray(significantTag);
                    var label3 = this.utils.convertToArray(locationChart);

                    var barChartData1: ChartDataSets[] = [];
                    var barChartData2: SingleDataSet = [];
                    var barChartData3: ChartDataSets[] = [];


                    for (let x of label2) {
                        let data2 = [];
                        barChartData2.push(significantTag[x])

                        let data1 = []
                        for (let y of label1) {
                            data1.push(dateSignificantTag[y][x] ? dateSignificantTag[y][x] : 0);
                        }
                        barChartData1.push({
                            data: data1,
                            label: x,
                            stack: 'a'
                        })

                        let data3 = []
                        for (let y of label3) {
                            data3.push(locationChart[y][x] ? locationChart[y][x] : 0);
                        }
                        barChartData3.push({
                            data: data3,
                            label: x,
                            stack: 'a'
                        })
                    }


                    let chart1 = {
                        label: label1.reverse(),
                        data: barChartData1.reverse()
                    };
                    let chart2 = {
                        label: label2,
                        data: barChartData2
                    };
                    let chart3 = {
                        label: label3,
                        data: barChartData3
                    };


                    this.mapIncident.getIncidentsPop(this.map.getBounds(), this.data.startTime, this.data.endTime, '', [], poly, 4).subscribe(value1 => {

                        var popmarker = value1

                        this.ngZone.run(() => {
                            var tag = []

                            const dataSource4 = dataSourcePop.filter(val => {
                                return val.published
                            });

                            const matDialogConfig = new MatDialogConfig();
                            matDialogConfig.height = "70%";
                            matDialogConfig.width = "65%";
                            matDialogConfig.data = {
                                startTime: this.data.startTime,
                                endTime: this.data.endTime,
                                chart1: chart1,
                                chart2: chart3,
                                incidentDetails: incidentDataJump.hits.hits[0]._source,
                                marker: popmarker,
                                dataSource: new MatTableDataSource<PeriodicElement>(dataSource4),
                                table: dataSource4
                            }
                            const dialogRef = this.matDialog.open(PortGraphDialogModalComponent, matDialogConfig);

                            dialogRef.afterClosed().subscribe(result => {
                                this.util.log('Dialog result: ', result)
                            });
                            this.spinner.hide()

                        })

                    }, error => this.util.error('Get Incidents in Map err: ', error))


                }
                sub.unsubscribe()
            }, err => this.util.error('mirgation err: ', err))


        }, err => this.util.log('err', err))
    }

    onClose() {
        this.dialogRef.close()
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    GetStats(value) {
        console.log('GetStats', JSON.stringify(this.checkbox))
    }

    createPDF() {
        let m1 = document.getElementById('cMap');
        let g1 = document.getElementById('cbgGraph1');
        let g2 = document.getElementById('cbgGraph2');
        let g3 = document.getElementById('cbgGraph3');

        if (this.checkbox.isMaritimeCrimeData || this.checkbox.isIncidentSummaryMap) {
            if (this.checkbox.isMaritimeCrimeData && this.checkbox.isIncidentSummaryMap) {
                this.spinner.show();
                html2canvas(g1, {
                    allowTaint: true,
                    useCORS: true,
                }).then(charts1 => {
                    html2canvas(g2, {
                        allowTaint: true,
                        useCORS: true,
                    }).then(charts2 => {
                        html2canvas(g3, {
                            allowTaint: true,
                            useCORS: true,
                        }).then(charts3 => {
                            html2canvas(m1, {
                                allowTaint: true,
                                useCORS: true,
                            }).then(map1 => {
                                this.spinner.hide();
                                this.genratePDF(charts1.toDataURL(), charts2.toDataURL(), charts3.toDataURL(), map1.toDataURL())
                            }).catch(err => {
                                this.spinner.hide();
                                console.error(err)
                            })
                        }).catch(err => {
                            this.spinner.hide();
                            console.error(err)
                        })
                    }).catch(err => {
                        this.spinner.hide();
                        console.error(err)
                    })
                }).catch(err => {
                    this.spinner.hide();
                    console.error(err)
                });

            } else if (this.checkbox.isMaritimeCrimeData) {
                this.spinner.show();

                html2canvas(g1, {
                    allowTaint: true,
                    useCORS: true,
                }).then(charts1 => {
                    html2canvas(g2, {
                        allowTaint: true,
                        useCORS: true,
                    }).then(charts2 => {
                        html2canvas(g3, {
                            allowTaint: true,
                            useCORS: true,
                        }).then(charts3 => {
                            this.spinner.hide();
                            this.genratePDF(charts1.toDataURL(), charts2.toDataURL(), charts3.toDataURL(), '')
                        }).catch(err => {
                            this.spinner.hide();
                            console.log(err)
                        })
                    }).catch(err => {
                        this.spinner.hide();
                        console.log(err)
                    })
                }).catch(err => {
                    this.spinner.hide();
                    console.log(err)
                });
            } else if (this.checkbox.isIncidentSummaryMap) {
                this.spinner.show();
                html2canvas(m1, {
                    allowTaint: true,
                    useCORS: true,
                }).then(map1 => {
                    this.spinner.hide();
                    this.genratePDF('', '', '', map1.toDataURL())
                }).catch(err => {
                    this.spinner.hide();
                    console.log(err)
                })
            }
        } else {
            this.genratePDF('', '', '', '')
        }
    }


    // TODO Country News...

    genratePDF(chartImg1, chartImg2, chartImg3, mapImage) {

        const title = 'Country Threat Assessment';
        const name = this.data.portResponse.title;

        let summaryHTML = '';
        let graphImageHTML = '';
        let mapImageHTML = '';
        let tableHTML = '';

        let topTableHTML = '';
        let topdetailsHTML = '';
        let summary = '';
        let topTable = '<tr><th class="bText">Threat Type</th><th class="bText textCenter">Level </th></tr>';

        if (this.checkbox.isOverview || this.checkbox.isRiskCategories) {


            for (let x of this.summary) {

                if (x.key === 'overview' || x.key === 'maritime' || x.key === 'ashore' || x.key === 'recommendations') {
                    let desc = '';
                    for (let para of x.value.narrative ? x.value.narrative : []) {
                        desc += '<p>' + para + '</p>';
                    }

                    if (this.checkbox.isOverview) {
                        if (x.key === 'overview' || x.key === 'recommendations') {
                            summary += '<p class="bText textRighttt fontSize18"> '
                                + this.util.capitalizeFirstLetterXC(x.key)
                                + this.utils.getRiskIconX(x.value.indicator, x.key) + '</p>'
                                + desc + '<br>';
                        }
                    }

                    if (this.checkbox.isRiskCategories) {
                        if (x.key === 'maritime' || x.key === 'ashore') {
                            summary += '<p class="bText textRighttt fontSize18"> '
                                + this.util.capitalizeFirstLetterXC(x.key)
                                + this.utils.getRiskIconX(x.value.indicator, x.key) + '</p>'
                                + desc + '<br>';
                        }
                    }

                }

                if (x.key === 'maritime' || x.key === 'ashore') {
                    topTable += '<tr>' +
                        '<td>' + this.utils.capitalizeFirstLetterXC(x.key) + '</td>' +
                        '<td style="text-align: center">' + this.utils.getRiskIcon(x.value.indicator, x.key) + '</td>'
                    '</tr>'
                }

                if (this.checkbox.isOverview) {
                    if (x.key === 'uscglist' || x.key === 'ispscompliant'
                        || x.key === 'ispslevel' || x.key === 'jwrcarea') {
                        topdetailsHTML += '<p class="bText">' + this.utils.capitalizeFirstLetterXC(x.key) + ': '
                            + this.utils.getRiskIcon(x.value.indicator, x.key) + '</p></br>'
                    }
                }


            }

            if (this.checkbox.isRiskCategories) {
                topTableHTML = '<table border="1" cellpadding="5" cellspacing="0" width="100%" class="cFontFamily">\n' +
                    topTable + '</table><br>';
            }
        }
        summaryHTML = '<div class="pageDiv">\n' +
            '            <div class="pageContent">\n' +
            '                 <p class="bText fontSize18"> ' + title + '</p>' +
            '                 <p class="bText cFont">' + name + '</p>' +
            '                 </br>' + topdetailsHTML + '</br></br>' +
            topTableHTML +
            '                  <div class="cJustify">' + summary + '</div>' +
            '                </div>' +
            '            </div>'

        if (this.checkbox.isMaritimeCrimeData) {
            const graphText = 'Maritime Crime Data: ' + this.utils.getDate(this.data.startTime) + ' to ' + this.utils.getDate(this.data.endTime);

            graphImageHTML = '<div class="page_break"></div>\n' +
                '            <div class="pageDiv">\n' +
                '                <div class="pageContent">\n' +
                '                    <p class="bText fontSize18">' + graphText + '</p><br><br>\n' +
                '                    <img class="cGraph" src = "' + chartImg1 + '"/><br><br>\n' +
                '                    <img class="cGraph" src = "' + chartImg2 + '"/><br><br>\n' +
                '                    <img class="cGraph" src = "' + chartImg3 + '"/><br><br>\n' +
                '             </div>\n' +
                '            </div>'
        }

        if (this.checkbox.isIncidentSummaryMap) {


            mapImageHTML = '<div class="page_break"></div>\n' +
                '            <div class="pageDiv">\n' +
                '                <div class="pageContent">\n' +
                '                    <p class="bText fontSize18">Incident Plot </p><br><br>\n' +
                '                    <img class="cMap" src = "' + mapImage + '"/>' +
                '                </div>\n' +
                '            </div>'
        }

        if (this.checkbox.isIncidentSummaryTable) {
            const tb = this.data.table
            let table = '<tr>\n' +
                '<th class="bText">Time</th>\n' +
                '<th class="bText">Title</th>\n' +
                '<th class="bText">Type</th>\n' +
                '<th class="bText">Area</th>\n' +
                '</tr>';
            for (let x = 0; x < tb.length; x++) {
                table += '<tr>\n' +
                    '<td>' + this.utils.getTime(tb[x].time) + '</td>\n' +
                    '<td>' + tb[x].title + '</td>\n' +
                    '<td>' + tb[x].significant_tag + '</td>\n' +
                    '<td>' + tb[x].classification_permissioning + '</td>\n' +
                    '</tr>'
            }

            tableHTML = '<div class="page_break"></div>' +
                '<div class="pageDiv">' +
                '<div class="pageContent">' +
                '<p class="bText fontSize18">Incident Summary Table:</p><br>' +
                '<table border="1" cellpadding="5" cellspacing="0" width="100%">' +
                table +
                '</table>' +
                '</div>' +
                '</div>';
        }


        let d = new Date()
        const body = {
            reportTitle: 'Country Threat Assessment ',
            reportFor: this.data.portResponse.title,
            company: this.utils.company,
            date: d.getDate() + '/' + Math.abs(d.getMonth() + 1) + '/' + d.getFullYear(),
            summaryHTML: summaryHTML,
            graphImageHTML: graphImageHTML,
            mapImageHTML: mapImageHTML,
            tableHTML: tableHTML
        }

        /*
                const bodyFileName = {
                    coverFileName: 'dryad_pdf_body.pdf',
                    bodyFileName: 'dryad1.pdf'
                };
                this.http.post(url.download,bodyFileName,{
                    responseType: 'arraybuffer',
                    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept', 'application/pdf')
                }).subscribe(response=>{
                    this.downLoadFile(response, "application/pdf")
                },error2 => {
                    // this.spinner.hide();
                    console.error(url.country+' error: ',error2)
                });*/

        setTimeout(() => this.spinner.show(), 100)
        this.http.post(url.country, body).subscribe(bodyFileName => {
            console.log('sucess', bodyFileName);
            let fileNames = {
                coverFileName: 'cover-country-risk',//coverFileName,
                bodyFileName: bodyFileName
            };
            setTimeout(() => {
                this.http.post(url.download, fileNames, {
                    responseType: 'arraybuffer',
                    headers: new HttpHeaders()
                        //.set('Content-Type', 'application/x-www-form-urlencoded')
                        .set('Accept', 'application/pdf')
                }).subscribe(response => {
                    this.spinner.hide();
                    this.downLoadFile(response, "application/pdf")
                }, error2 => {
                    this.spinner.hide();
                    console.error('download error: ', error2)
                });
            }, 1000)


        }, error2 => {
            this.spinner.hide();
            console.error(url.country + ' error: ', error2)
        });

        /* this.http.post(url.cover,body).subscribe(coverFileName=>{
             console.log('sucess',coverFileName);
         }, err=>{
             this.spinner.hide();
             console.error('cover', err)
         });*/

    }

    downLoadFile(data: any, type: string) {

        let blob = new Blob([data], {type: type});

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.data.portResponse.title + this.utils.getDateformate() + ".pdf";
        link.click();
        
        this.hubspotService.postDownloadDetails(this.data.portResponse.title + " at " + new Date().toISOString())
    }

    convertToPDF() {



        // this.createFile()
        let x1 = document.getElementById('convertopdfUser');
        let x1_5 = document.getElementById('convertopdfUser1');
        let x2 = document.getElementById('convertopdfUser2');
        let x3 = document.getElementById('convertopdfUser3');
        let x4 = document.getElementById('convertopdfUser4');
        this.spinner.show()
        html2canvas(x1, {
            allowTaint: true,
            useCORS: true,
        }).then(canvas1 => {
            html2canvas(x2, {
                allowTaint: true,
                useCORS: true,
            }).then(canvas2 => {
                html2canvas(x3, {
                    allowTaint: true,
                    useCORS: true,
                }).then(canvas3 => {
                    html2canvas(x4, {
                        allowTaint: true,
                        useCORS: true,
                    }).then(canvas4 => {

                        html2canvas(x1_5, {
                            allowTaint: true,
                            useCORS: true,
                        }).then(canvas1_5 => {
                            this.spinner.hide()


                            console.log('img: sss', canvas2.toDataURL())
                            // this.toPDF(canvas1,canvas1_5,canvas2,canvas3,canvas4,x1,x2,x3,x4,x1_5)
                        })

                    })
                })
            })
        })
    }

    toPDF(canvas1, canvas1_5, canvas2, canvas3, canvas4, x1, x2, x3, x4, x1_5) {

        var pdf = new jspdf('p', 'pt', 'a4');
        var xfact = 1150;

        let j = 0
        for (var i = 0; i <= x1.clientHeight / xfact; i++) {
            //! This is all just html2canvas stuff
            var srcImg = canvas1;
            var sX = 0;
            var sY = xfact * i; // start 980 pixels down for every new page
            //! var sWidth  = 778;
            var sWidth = 850;
            var sHeight = 1150;
            var dX = 0;
            var dY = 0;
            //! var dWidth  = 778;
            var dWidth = 850;
            var dHeight = 1150;

            var onePageCanvas = document.createElement("canvas");
            onePageCanvas.setAttribute('width', '850');
            onePageCanvas.setAttribute('height', '1150');
            var ctx = onePageCanvas.getContext('2d');

            sHeight = xfact
            ctx.drawImage(srcImg, sX, sY, x1.clientWidth, sHeight, dX, dY, dWidth, dHeight);

            var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

            var width = onePageCanvas.width;
            var height = onePageCanvas.clientHeight;

            if (i > 0) {
                pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
            }
            pdf.setPage(i + 1);
            j = i + 1
            //! now we add content to that page!
            pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width * .72), (height * .72));
        }


        let onePageCanvasmid = document.createElement("canvas");
        onePageCanvasmid.setAttribute('width', '850');
        onePageCanvasmid.setAttribute('height', '1150');
        let ctxmid = onePageCanvasmid.getContext('2d');

        ctxmid.drawImage(canvas1_5, 0, 0, x1_5.clientWidth, 1150, 0, 0, 850, 1150);

        let canvasDataURLmid = onePageCanvasmid.toDataURL("image/png", 1.0);
        let widthmid = onePageCanvasmid.width;
        let heightmid = onePageCanvasmid.clientHeight;
        if (j > 0) {
            pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
        }
        pdf.setPage(j + 1);

        pdf.addImage(canvasDataURLmid, 'PNG', 0, 0, (widthmid * .72), (heightmid * .72));


        let onePageCanvas2 = document.createElement("canvas");
        onePageCanvas2.setAttribute('width', '850');
        onePageCanvas2.setAttribute('height', '1150');
        let ctx2 = onePageCanvas2.getContext('2d');

        ctx2.drawImage(canvas2, 0, 0, x2.clientWidth, 1150, 0, 0, 850, 1150);

        let canvasDataURL2 = onePageCanvas2.toDataURL("image/png", 1.0);
        let width2 = onePageCanvas2.width;
        let height2 = onePageCanvas2.clientHeight;
        if (j > 0) {
            pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
        }
        pdf.setPage(j + 2);

        pdf.addImage(canvasDataURL2, 'PNG', 0, 0, (width2 * .72), (height2 * .72));


        let onePageCanvas3 = document.createElement("canvas");
        onePageCanvas3.setAttribute('width', '850');
        onePageCanvas3.setAttribute('height', '1150');
        let ctx3 = onePageCanvas3.getContext('2d');

        ctx3.drawImage(canvas3, 0, 0, x3.clientWidth, 1150, 0, 0, 850, 1150);

        let canvasDataURL3 = onePageCanvas3.toDataURL("image/png", 1.0);
        let width3 = onePageCanvas3.width;
        let height3 = onePageCanvas3.clientHeight;
        if (j > 0) {
            pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
        }

        pdf.setPage(j + 3);

        pdf.addImage(canvasDataURL3, 'PNG', 0, 0, (width3 * .72), (height3 * .72));


        let onePageCanvas4 = document.createElement("canvas");
        onePageCanvas4.setAttribute('width', '850');
        onePageCanvas4.setAttribute('height', '1150');
        let ctx4 = onePageCanvas4.getContext('2d');

        ctx4.drawImage(canvas4, 0, 0, x4.clientWidth, 1150, 0, 0, 850, 1150);

        let canvasDataURL4 = onePageCanvas4.toDataURL("image/png", 1.0);
        let width4 = onePageCanvas4.width;
        let height4 = onePageCanvas4.clientHeight;
        if (j > 0) {
            pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
        }
        pdf.setPage(j + 4);

        pdf.addImage(canvasDataURL4, 'PNG', 0, 0, (width4 * .72), (height4 * .72));


        pdf.save('Test3.pdf');
    }

    convertToPDFx() {
        var data = document.getElementById('convertopdfUser');
        console.log(data.clientHeight / data.clientWidth * 850);

        //! var xfact = Math.round(data.clientHeight/data.clientWidth * 850);
        var xfact = 1150;


        html2canvas(data, {
            allowTaint: true,
            useCORS: true,
        }).then(canvas => {
            var pdf = new jspdf('p', 'pt', 'a4');

            for (var i = 0; i <= data.clientHeight / xfact; i++) {
                //! This is all just html2canvas stuff
                var srcImg = canvas;
                var sX = 0;
                var sY = xfact * i; // start 980 pixels down for every new page
                //! var sWidth  = 778;
                var sWidth = 850;
                var sHeight = 1150;
                var dX = 0;
                var dY = 0;
                //! var dWidth  = 778;
                var dWidth = 850;
                var dHeight = 1150;

                var onePageCanvas = document.createElement("canvas");
                onePageCanvas.setAttribute('width', '850');
                onePageCanvas.setAttribute('height', '1150');
                var ctx = onePageCanvas.getContext('2d');
                // details on this usage of this function:
                // https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Using_images#Slicing
                // ctx.drawImage("<h1>ss</h1>" +
                //     "<img src='{{canvas}}'>",sX,sY,data.clientWidth,sHeight,dX,dY,dWidth,dHeight)

                sHeight = xfact
                ctx.drawImage(srcImg, sX, sY, data.clientWidth, sHeight, dX, dY, dWidth, dHeight);

                // ctx.drawImage(srcImg,sX,sY,data.clientWidth,data.clientHeight,dX,dY,data.clientWidth,data.clientHeight);

                // document.body.appendChild(canvas);
                var canvasDataURL = onePageCanvas.toDataURL("image/png", 1.0);

                var width = onePageCanvas.width;
                var height = onePageCanvas.clientHeight;

                //! If we're on anything other than the first page,
                // add another page
                if (i > 0) {
                    pdf.addPage(600, 800); //8.5" x 11" in pts (in*72)
                }
                //! now we declare that we're working on that page
                pdf.setPage(i + 1);
                //! now we add content to that page!
                pdf.addImage(canvasDataURL, 'PNG', 0, 0, (width * .72), (height * .72));

            }
            //! after the for loop is finished running, we save the pdf.
            pdf.save('Test3.pdf');
        });
    }

    private fetchCountryNews(country) {
        this.mapIncident.getCountryNews(country).subscribe(val => {
            console.log('News:: ', JSON.stringify(val));
            if (val) {
                this.news = val
            }
        }, error => console.error(error))
    }

    private fetchCountryNewsVenues(country) {
        this.mapIncident.getCountryNewsVenues(country).subscribe(val => {
            console.log('News:: ', JSON.stringify(val));
            if (val) {
                this.newsVenues = val
            }
        }, error => console.error(error))
    }
}

class Filter {
    match_phrase: MatchPhrase = new MatchPhrase()
}

class MatchPhrase {
    type: string = ''
}

class IncidentTableModal {
    id?: any = ''
    time?: any
    title?: any
    significant_tag?: any
    tags?: any
    classification_permissioning?: any
    contributor?: any
    lat?: any
    lon?: any
    dtg_parts?: any
    type?: any
    published?: boolean
    country?: any = ''
    code?: any = ''
}

export interface PeriodicElement {
    time: number;
    title: string;  // @ts-ignore
    significant_tag: string;
    tags: string;
    classification_permissioning: string;
    contributor: string;
}

const ELEMENT_DATA: any[] = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
    {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
    {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
    {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
    {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
    {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
    {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
    {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
    {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
    {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
    {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
    {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
    {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
    {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
    {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
    {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];