import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IncidentReportService {

    private mapCanvas:any
    private startTime:any
    private endTime:any
    private dataSource:any
  constructor() { }

  setMapCanvas(mapCanvas){
      this.mapCanvas = mapCanvas
  }

  setStartTime(startTime){
    this.startTime = startTime
  }

  setEndTime(endTime){
    this.endTime = endTime
  }

  setDataSource(dataSource){
    this.dataSource = dataSource
  }

  getMapCanvas(){
    return this.mapCanvas
  }
  getStartTime(){
    return this.startTime
  }

  getEndTime(){
    return this.endTime
  }

  getDataSource(){
    return this.dataSource
  }

}
