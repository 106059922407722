import { merge } from "rxjs/observable/merge";
import { map, mergeMap, tap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";
import { url } from "./../utils/const";
import { UtilService } from "./util/util.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class IframeService {
  constructor(
    private httpClient: HttpClient,
    private utilService: UtilService
  ) {}

  getCountryDetails(countryCode, startDate, endDate) {
    const countryBody = {
      body: {
        version: true,
        size: 1,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                match_phrase: {
                  type: {
                    query: "country",
                  },
                },
              },
              {
                match_phrase: {
                  status: {
                    query: true,
                  },
                },
              },
              {
                match_phrase: {
                  published: {
                    query: true,
                  },
                },
              },
              {
                match_phrase: {
                  "country.code": {
                    query: countryCode,
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };

    const incidentBody = {
      body: {
        version: true,
        size: 1000,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                range: {
                  dtg: {
                    format: "strict_date_optional_time",
                    gte: startDate,
                    lte: endDate,
                  },
                },
              },
              {
                match_phrase: {
                  "country.code": {
                    query: countryCode,
                  },
                },
              },
              {
                match_phrase: {
                  _index: {
                    query: "portal-incidents",
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };
    return merge(this.login()).pipe(
      map((m: any) => ({
        countryBody: Object.assign(countryBody, { currentUser: m.token }),
        incidentBody: Object.assign(incidentBody, { currentUser: m.token }),
      })),
      mergeMap((d) =>
        forkJoin(
          this.httpClient.post(url.index, d.countryBody, {
            responseType: "text",
          }),
          this.httpClient.post(url.index, d.incidentBody, {
            responseType: "text",
          })
        ).pipe(
          tap((d) => console.log(d)),
          map((d) => ({
            countryDetails: JSON.parse(d[0]),
            incidents: JSON.parse(d[1]),
          })),
          map((d) => ({
            countryDetails: d.countryDetails.hits.hits.map((m) => m._source)[0],
            incidents: d.incidents.hits.hits.map((m) => m._source),
          }))
        )
      )
    );
  }

  getMaritimeDetails(martimeName, startDate, endDate) {
    const maritimeBody = {
      body: {
        version: true,
        size: 1,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                match_phrase: {
                  type: {
                    query: "dryad",
                  },
                },
              },
              {
                match_phrase: {
                  status: {
                    query: true,
                  },
                },
              },
              {
                match_phrase: {
                  published: {
                    query: true,
                  },
                },
              },
              {
                match_phrase: {
                  name: {
                    query: martimeName,
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };

    const incidentBody = {
      body: {
        version: true,
        size: 1000,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                range: {
                  dtg: {
                    format: "strict_date_optional_time",
                    gte: startDate,
                    lte: endDate,
                  },
                },
              },
              {
                match_phrase: {
                  "classification.dryad_area": {
                    query: martimeName,
                  },
                },
              },
              {
                match_phrase: {
                  _index: {
                    query: "portal-incidents",
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };

    return merge(this.login()).pipe(
      map((m: any) => ({
        maritimeBody: Object.assign(maritimeBody, { currentUser: m.token }),
        incidentBody: Object.assign(incidentBody, { currentUser: m.token }),
      })),
      mergeMap((d) =>
        forkJoin(
          this.httpClient.post(url.index, d.maritimeBody, {
            responseType: "text",
          }),
          this.httpClient.post(url.index, d.incidentBody, {
            responseType: "text",
          })
        ).pipe(
          tap((d) => console.log(d)),
          map((d) => ({
            maritimeDetails: JSON.parse(d[0]),
            incidents: JSON.parse(d[1]),
          })),
          map((d) => ({
            maritimeDetails: d.maritimeDetails.hits.hits.map(
              (m) => m._source
            )[0],
            incidents: d.incidents.hits.hits.map((m) => m._source),
          }))
        )
      )
    );
  }

  getPortDetails(portName, startDate, endDate) {
    let token = "";
    const portBody = {
      body: {
        version: true,
        size: 1,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                match_phrase: {
                  _index: {
                    query: "portal-risk",
                  },
                },
              },
              {
                match_phrase: {
                  title: {
                    query: portName,
                  },
                },
              },
              {
                match_phrase: {
                  type: {
                    query: "Port",
                  },
                },
              },
              {
                match_phrase: {
                  status: {
                    query: true,
                  },
                },
              },
              {
                match_phrase: {
                  published: {
                    query: true,
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };

    const incidentBody = {
      body: {
        version: true,
        size: 1000,
        sort: [
          {
            dtg: {
              order: "desc",
              unmapped_type: "boolean",
            },
          },
        ],
        _source: {
          excludes: [],
        },
        stored_fields: ["*"],
        script_fields: {},
        docvalue_fields: [
          {
            field: "dtg",
            format: "date_time",
          },
          {
            field: "incident.create_date",
            format: "date_time",
          },
          {
            field: "incident.dtg",
            format: "date_time",
          },
          {
            field: "incident.last_update_date",
            format: "date_time",
          },
          {
            field: "incident.updated.timestamp",
            format: "date_time",
          },
          {
            field: "index_date",
            format: "date_time",
          },
          {
            field: "last_update_date",
            format: "date_time",
          },
          {
            field: "migration.reported_date",
            format: "date_time",
          },
          {
            field: "updated.timestamp",
            format: "date_time",
          },
        ],
        query: {
          bool: {
            must: [
              {
                range: {
                  dtg: {
                    format: "strict_date_optional_time",
                    gte: startDate,
                    lte: endDate,
                  },
                },
              },
              {
                match_phrase: {
                  "classification.port": {
                    query: portName,
                  },
                },
              },
              {
                match_phrase: {
                  _index: {
                    query: "portal-incidents",
                  },
                },
              },
            ],
            filter: [],
            should: [],
            must_not: [],
          },
        },
        highlight: {
          pre_tags: ["@kibana-highlighted-field@"],
          post_tags: ["@/kibana-highlighted-field@"],
          fields: {
            "*": {},
          },
          fragment_size: 2147483647,
        },
      },
      currentUser: this.utilService.currentUser,
    };

    return merge(this.login()).pipe(
      tap((t: any) => (token = t.token)),
      map((m: any) => ({
        portBody: Object.assign(portBody, { currentUser: m.token }),
        incidentBody: Object.assign(incidentBody, { currentUser: m.token }),
      })),
      mergeMap((d) =>
        this.httpClient.post(url.index, d.portBody, { responseType: "text" })
      ),
      map((m: any) => ({
        port: m,
        incidentBody: {
          body: {
            version: true,
            size: 1000,
            sort: [
              {
                dtg: {
                  order: "desc",
                  unmapped_type: "boolean",
                },
              },
            ],
            _source: {
              excludes: [],
            },
            stored_fields: ["*"],
            script_fields: {},
            docvalue_fields: [
              {
                field: "dtg",
                format: "date_time",
              },
              {
                field: "incident.create_date",
                format: "date_time",
              },
              {
                field: "incident.dtg",
                format: "date_time",
              },
              {
                field: "incident.last_update_date",
                format: "date_time",
              },
              {
                field: "incident.updated.timestamp",
                format: "date_time",
              },
              {
                field: "index_date",
                format: "date_time",
              },
              {
                field: "last_update_date",
                format: "date_time",
              },
              {
                field: "migration.reported_date",
                format: "date_time",
              },
              {
                field: "updated.timestamp",
                format: "date_time",
              },
            ],
            query: {
              bool: {
                must: [
                  {
                    range: {
                      dtg: {
                        format: "strict_date_optional_time",
                        gte: startDate,
                        lte: endDate,
                      },
                    },
                  },
                  {
                    match_phrase: {
                      "classification.port": {
                        query:
                          JSON.parse(m).hits.hits[0]._source.name || portName,
                      },
                    },
                  },
                  {
                    match_phrase: {
                      _index: {
                        query: "portal-incidents",
                      },
                    },
                  },
                ],
                filter: [],
                should: [],
                must_not: [],
              },
            },
            highlight: {
              pre_tags: ["@kibana-highlighted-fieportNameld@"],
              post_tags: ["@/kibana-highlighted-field@"],
              fields: {
                "*": {},
              },
              fragment_size: 2147483647,
            },
          },
          currentUser: token,
        },
      })),
      mergeMap((d: any) =>
        forkJoin(
          of(d.port),
          // this.httpClient.post(url.index, d.portBody, { responseType: "text" }),

          this.httpClient.post(url.index, d.incidentBody, {
            responseType: "text",
          })
        ).pipe(
          tap((d) => console.log(d)),
          map((d) => ({
            portDetails: JSON.parse(d[0]),
            incidents: JSON.parse(d[1]),
          })),
          map((d) => ({
            portDetails: d.portDetails.hits.hits.map((m) => m._source)[0],
            incidents: d.incidents.hits.hits.map((m) => m._source),
          }))
        )
      )
    );
  }

  login() {
    const obj = {
      username: "jay@connect91.com",
      password: "T%^v5f3^Sq",
    };
    return this.httpClient.post(url.login, obj);
  }
}
