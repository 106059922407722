import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { ChartDataSets, ChartOptions, ChartType } from "chart.js";
import { Color, Label, SingleDataSet } from "ng2-charts";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatPaginator,
  PageEvent,
  MatSort,
  Sort,
  MatTableDataSource,
} from "@angular/material";

import {
  circle,
  circleMarker,
  icon,
  latLng,
  Map,
  marker,
  tileLayer,
} from "leaflet";
declare let L;

import { Observable } from "rxjs/Observable";
import { of } from "rxjs/observable/of";
import { map } from "rxjs/operators";
import { fromMatSort, sortRows } from "../datasource-utils";
import { fromMatPaginator, paginateRows } from "../datasource-utils";

import html2canvas from "html2canvas";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NgxSpinnerService } from "ngx-spinner";
import { UtilService } from "../../service/util/util.service";
import { url } from "../../utils/const";
@Component({
  selector: "app-tra-popup",
  templateUrl: "./tra-popup.component.html",
  styleUrls: ["./tra-popup.component.scss"],
})
export class TraPopupComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  vesselName = "";

  imo = "";

  waypoints = [];

  totalDistance = 0;

  threatScore = "Low";

  mapCanvas;

  displayedRows$: Observable<any[]>;
  totalRows$: Observable<number>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    public utils: UtilService,
    private spinner: NgxSpinnerService
  ) {
    console.log("tra:  ", JSON.stringify(this.data));

    this.vesselName = this.data.vesselName;
    this.imo = this.data.imo;
    this.waypoints = this.data.waypoints;
    this.threatScore = this.data.threatScore;
    this.mapCanvas = this.data.mapCanvas;
    let threatDataLineChart = [];
    let threatTimeLineChart = [];
    let barChartData = [
      { data: [], label: "Fired Upon", stack: "Fired Upon" },
      { data: [], label: "Hijack", stack: "Hijack" },
      { data: [], label: "Attack", stack: "Attack" },
      { data: [], label: "Threat", stack: "Threat" },
      { data: [], label: "Robbery", stack: "Robbery" },
      { data: [], label: "Approach", stack: "Approach" },
      { data: [], label: "Boarding", stack: "Boarding" },
      { data: [], label: "Kidnapping", stack: "Kidnapping" },
    ];
    for (let i in this.waypoints) {
      if (parseInt(i) > 0) {
        this.totalDistance +=
          this.waypoints[i].segments.features[
            this.waypoints[i].segments.features.length - 1
          ].properties.cumulativeDistance;
        let total = 0;
        let firedUpon = 0;
        let hijack = 0;
        let attack = 0;
        let threat = 0;
        let robbery = 0;
        let approach = 0;
        let boarding = 0;
        let kidnapping = 0;
        for (let geom of this.waypoints[i].segments.features) {
          if (geom.geometry.type === "LineString") {
            if (geom.properties.hasOwnProperty("firedUpon")) {
              total += geom.properties.firedUpon;
              firedUpon += geom.properties.firedUpon;
            }
            if (geom.properties.hasOwnProperty("hijack")) {
              total += geom.properties.hijack;
              hijack += geom.properties.hijack;
            }
            if (geom.properties.hasOwnProperty("attack")) {
              total += geom.properties.attack;
              attack += geom.properties.attack;
            }
            if (geom.properties.hasOwnProperty("threat")) {
              total += geom.properties.threat;
              threat += geom.properties.threat;
            }
            // if (geom.properties.hasOwnProperty('attempted')) {
            //     total += geom.properties.threat;
            // }
            if (geom.properties.hasOwnProperty("robbery")) {
              total += geom.properties.robbery;
              robbery += geom.properties.robbery;
            }
            if (geom.properties.hasOwnProperty("approach")) {
              total += geom.properties.approach;
              approach += geom.properties.approach;
            }
            if (geom.properties.hasOwnProperty("boarding")) {
              total += geom.properties.boarding;
              boarding += geom.properties.boarding;
            }
            if (geom.properties.hasOwnProperty("kidnapping")) {
              total += geom.properties.kidnapping;
              kidnapping += geom.properties.kidnapping;
            }
          }
        }
        barChartData[0].data.push(firedUpon);
        barChartData[1].data.push(hijack);
        barChartData[2].data.push(attack);
        barChartData[3].data.push(threat);
        barChartData[4].data.push(robbery);
        barChartData[5].data.push(approach);
        barChartData[6].data.push(boarding);
        barChartData[7].data.push(kidnapping);
        threatTimeLineChart.push(this.waypoints[i].waypoint);
        threatDataLineChart.push(total);
        this.lineChartData[0].data = threatDataLineChart;
        this.lineChartLabels.push(this.waypoints[i].waypoint);
        this.barChartLabels1 = threatTimeLineChart;
      }
    }
    this.barChartData1 = barChartData;
  }

  streetMaps = tileLayer(
    "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}",
    {
      detectRetina: true,
      attribution:
        'Map data: &copy; <a href="http://www.openseamap.org">OpenSeaMap</a> contributors',
    }
  );

  options = {
    layers: [this.streetMaps],
    minZoom: 3,
    maxZoom: 10,
    zoomControl: true,
    drawControl: false,
    zoom: 5,
    attributionControl: false,
    scrollWheelZoom: false,
    center: latLng([16.966666666666665, 82.3]),
  };

  drawOptions = {
    position: "bottomleft",
    draw: {
      polygon: true,
      polyline: false,
      rectangle: false,
      marker: false,
      circle: false,
      circlemarker: false,
    },
  };

  /**********************************************/

  lineChartData: ChartDataSets[] = [
    { data: [85, 72, 78, 75, 77, 75], label: "Incident Occurred" },
  ];

  lineChartLabels: Label[] = [];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: "black",
      backgroundColor: "rgba(255,255,0,0.28)",
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = "line";

  //Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };
  // public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartLabels: Label[] = [
    "Robbery",
    "Boarding",
    "FiredUpon",
    "Approach",
    "Kidnap",
    "Attak",
    "Attempted",
    "Hijack",
  ];

  public pieChartData: SingleDataSet = [300, 50, 100, 200, 0, 0, 0, 90];
  public pieChartType: ChartType = "pie";
  public pieChartLegend = true;
  public pieChartPlugins = [];

  //horizontal bar graph

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartType: ChartType = "horizontalBar";
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [1, 2, 3], label: "Kidnap", stack: "a" },
    { data: [1, 2, 3], label: "Hijack", stack: "a" },
    { data: [1, 2, 3], label: "Robbery", stack: "a" },
    { data: [1, 2, 3], label: "Attack", stack: "a" },
  ];
  public barChartLabels: string[] = ["P", "R", "B"];

  ///vertical bar

  public barChartOptions1: ChartOptions = {
    responsive: true,
  };
  public barChartLabels1: Label[] = [];
  public barChartType1: ChartType = "bar";
  public barChartLegend1 = true;
  public barChartPlugins1 = [];

  public barChartData1: ChartDataSets[] = [];

  // public barChartLabels1: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  // public barChartType1: ChartType = 'bar';
  // public barChartLegend1 = true;
  // public barChartPlugins1 = [];
  //
  // public barChartData1: ChartDataSets[] = [
  //     { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A', stack: 'a' },
  //     { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B', stack: 'a' }
  // ];
  /***************************************/
  displayedColumns: string[] = [
    "waypoint",
    "lat",
    "lng",
    "arrival",
    "departure",
    "speed",
    "freeboard",
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);

  // @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  ngOnInit() {
    const sortEvents$: Observable<Sort> = fromMatSort(this.sort);
    const pageEvents$: Observable<PageEvent> = fromMatPaginator(this.paginator);

    const rows$ = of(this.waypoints);

    this.totalRows$ = rows$.pipe(map((rows) => rows.length));
    this.displayedRows$ = rows$.pipe(
      sortRows(sortEvents$),
      paginateRows(pageEvents$)
    );

    this.dataSource.paginator = this.paginator;
  }

  onMapReady(map: Map) {}

  viewSegmentsPopup = false;
  viewSegments(segments) {
    this.viewSegmentsPopup = true;
  }

  createFile() {
    this.spinner.show();

    let table =
      "<tr>\n" +
      "<th>Way Point</th>\n" +
      "<th>LatLng</th>\n" +
      "<th>Arrival</th>\n" +
      "<th>Departure</th>\n" +
      "<th>Speed</th>\n" +
      "<th>Freeboard</th>\n" +
      "</tr>";

    for (let x of this.waypoints) {
      table +=
        "<tr>\n" +
        "<th>" +
        x.waypoint +
        "</th>\n" +
        "<th>" +
        this.utils.getDMS(x.lat, "lat") +
        ", " +
        this.utils.getDMS(x.lng, "long") +
        "</th>\n" +
        "<th>" +
        x.arrival +
        "</th>\n" +
        "<th>" +
        x.departure +
        "</th>\n" +
        "<th>" +
        x.speed +
        "</th>\n" +
        "<th>" +
        x.freeboard +
        "</th>\n" +
        "</tr>";
    }

    let tableHTML =
      '<table border="1" cellpadding="5" cellspacing="0" width="100%">\n' +
      table +
      "</table></div></div>";

    let x1 = document.getElementById("cGraph");
    html2canvas(x1, {
      allowTaint: true,
      useCORS: true,
    })
      .then((charts) => {
        let _POST = {
          vesselName: this.vesselName,
          IMO: this.imo,
          MMSI: this.data.mmsi,
          departurePoint: this.data.waypoints[0].departure,
          arrivalPoint:
            this.data.waypoints[this.data.waypoints.length - 1].departure,
          distance: this.totalDistance.toFixed(2) + " KM",
          speed: this.data.plannedSpeed + " Kpm",
          time: this.toHHMMSS(),
          risk: this.getimg(this.threatScore),
          weatherRisk: "",
          forecast: "",
          mapImg: this.mapCanvas,
          chartImg: charts.toDataURL(),
          tableHTML: tableHTML,
          company: this.utils.company,
          incidentTableHTML: this.data.incidentTableHTML,
        };

        this.getPDF(_POST);
      })
      .catch((err) => console.log(err));
  }

  isFullScreen: boolean = false;
  onResize() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      $(".cdk-overlay-pane").css("max-width", "100vw");
      $(".cdk-overlay-pane").css("height", "100%");
      $(".cdk-overlay-pane").css("width", "100%");
    } else {
      $(".cdk-overlay-pane").css("height", "70%");
      $(".cdk-overlay-pane").css("width", "65%");
    }
  }

  getimg(x) {
    if (x == "Low") {
      return "pdf-html/images/circle-low.jpg";
    } else if (x == "Medium") {
      return "pdf-html/images/circle-moderate.jpg";
    } else if (x == "High") {
      return "pdf-html/images/circle-high.jpg";
    } else {
      return "pdf-html/images/circle-low.jpg";
    }
  }

  getSec() {
    let d = new Date(this.data.waypoints[0].departure);
    let a = new Date(
      this.data.waypoints[this.data.waypoints.length - 1].departure
    );
    return Math.round((a.getTime() - d.getTime()) / 1000);
  }

  toHHMMSS() {
    // var sec_num = parseInt(this.getSec(), 10)

    var sec_num = this.getSec();

    var days = Math.floor(sec_num / 86400);
    var hours = Math.floor(sec_num / 3600) % 24;
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return (
      [days].map((d) => (d == 0 ? "" : d == 1 ? d + " day " : d + " days ")) +
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? "0" + v : v))
        .filter((v, i) => v !== "00" || i > 0)
        .join(":")
    );
  }

  getPDF(data) {
    // const url = 'http://159.65.152.29/dryad-dompdf/createPdfTra.php'
    this.http
      .post(url.tra, data, {
        responseType: "arraybuffer",
        headers: new HttpHeaders()
          .set("Content-Type", "application/x-www-form-urlencoded")
          .set("Accept", "application/pdf"),
      })
      .subscribe(
        (response) => {
          this.downLoadFile(response, "application/pdf");

          console.log(response, "sssss");
        },
        (error2) => {
          console.log("errrrrrrrr", error2);
        }
      );
  }

  downLoadFile(data: any, type: string) {
    this.spinner.hide();
    let blob = new Blob([data], { type: type });

    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "TRA-" + this.vesselName + ".pdf";
    link.click();
  }
}

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
  { position: 11, name: "Sodium", weight: 22.9897, symbol: "Na" },
  { position: 12, name: "Magnesium", weight: 24.305, symbol: "Mg" },
  { position: 13, name: "Aluminum", weight: 26.9815, symbol: "Al" },
  { position: 14, name: "Silicon", weight: 28.0855, symbol: "Si" },
  { position: 15, name: "Phosphorus", weight: 30.9738, symbol: "P" },
  { position: 16, name: "Sulfur", weight: 32.065, symbol: "S" },
  { position: 17, name: "Chlorine", weight: 35.453, symbol: "Cl" },
  { position: 18, name: "Argon", weight: 39.948, symbol: "Ar" },
  { position: 19, name: "Potassium", weight: 39.0983, symbol: "K" },
  { position: 20, name: "Calcium", weight: 40.078, symbol: "Ca" },
];
